import React from 'react';
import cx from 'classnames';

import './Spinner.scss';

interface ISpinnerProps {
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  screen?: boolean;
  style?: React.CSSProperties;
  center?: boolean;
}

const Spinner: React.FC<ISpinnerProps> = ({
  className,
  screen,
  style = {},
  size = 'md',
  center = false,
}) => (
  <div
    className={cx(
      'spinner',
      {
        [`spinner--${size}`]: size,
        'spinner--center': center,
        'spinner--screen': screen,
      },
      className
    )}
    style={style}
  />
);

export default Spinner;

import React from 'react';
import './ProgressBar.scss';

interface IProgressBarProps {
  value: number;
}

export default function ProgressBar(
  { value }: IProgressBarProps,
): JSX.Element {
  const fillerRelativePercentage = (100 / value) * 100;

  return (
    <div
      className="progressbar"
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={value}
    >
      <div className="progressbar__container">
        <div className="progressbar__filler" style={{ width: `${value}%` }}>
          <div
            className="progressbar__filler-bg"
            style={{ width: `${fillerRelativePercentage}%` }}
          />
        </div>
        <span className="progressbar__text">{`${value}%`}</span>
      </div>
    </div>
  );
}

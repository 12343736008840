import { AxiosResponse } from 'axios';
import { projectInstance } from '../helpers/axios';

const getBalance = (): Promise<any> =>
  projectInstance
    .get('billing/user/balance')
    .then((response: AxiosResponse) => response.data);

const getBalanceStatistic = (): Promise<any> =>
  projectInstance
    .get('billing/user/transaction/statistic')
    .then((response: AxiosResponse) => response.data);

const getBalanceTransactions = ({ listType, lastId }: any): Promise<any> =>
  projectInstance
    // .get(`billing/user/transaction/list/${listType}`)
    .get(`billing/user/transaction/list/${listType}/${lastId}`)
    .then((response: AxiosResponse) => response.data);

const refillBalance = (data: any): Promise<any> =>
  projectInstance
    .post('billing/user/balance/action', data)
    .then((response: AxiosResponse) => response.data);

const balanceToProject = (data: any): Promise<any> =>
  projectInstance.post(`billing/project/${data.slug}/balance/action`, {
    sum: data.sum,
  });

const balanceRequest = (data: any): Promise<any> =>
  projectInstance.post('billing/user/balance/request', data);

const getProjectBalance = (id: string): Promise<any> =>
  projectInstance
    .get(`billing/project/${id}/balance`)
    .then((response: AxiosResponse) => response.data);

const getProectBalanceStatistic = (slug: string): Promise<any> =>
  projectInstance
    .get(`billing/project/${slug}/transaction/statistic`)
    .then((response: AxiosResponse) => response.data);

const getProjectBalanceTransactions = (
  slug: string,
  lastId: number | null
): Promise<any> =>
  projectInstance
    // .get(`billing/project/${slug}/transaction/list/all/`)
    .get(`billing/project/${slug}/transaction/list/all/${lastId}`)
    .then((response: AxiosResponse) => response.data);

const getBonuses = (): Promise<any> =>
  projectInstance
    .get('billing/user/bonus')
    .then((response: AxiosResponse) => response.data);

const getBonusesStatistic = (): Promise<any> =>
  projectInstance
    .get('billing/bonus/transaction/statistic')
    .then((response: AxiosResponse) => response.data);

const getBonusesTransactions = (): Promise<any> =>
  projectInstance
    .get('billing/bonus/transaction/list/all')
    .then((response: AxiosResponse) => response.data);

const moveBonusesToBalance = (data: any): Promise<any> =>
  projectInstance
    .post('billing/bonus/send', data)
    .then((response: AxiosResponse) => response.data);

const billingService = {
  getBonuses,
  getBalance,
  getProectBalanceStatistic,
  getProjectBalance,
  getProjectBalanceTransactions,
  getBalanceTransactions,
  getBonusesStatistic,
  getBonusesTransactions,
  getBalanceStatistic,
  refillBalance,
  balanceToProject,
  moveBonusesToBalance,
  balanceRequest,
};

export default billingService;

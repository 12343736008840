import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import './User.scss';

type IUserProps = {
  user: any;
  size: string;
  isOnline?: boolean;
  withBorder?: boolean;
  backgroundColor?: string;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

function User({
  user,
  size,
  className,
  backgroundColor,
  style,
  withBorder = false,
  isOnline = false,
}: IUserProps): JSX.Element {
  const { onlineUsers } = useSelector(
    (state: { userData: any }) => state.userData
  );

  const previewLetter = useMemo(
    () =>
      user?.firstName
        ? user?.firstName?.charAt(0).toUpperCase()
        : user?.email?.charAt(0).toUpperCase(),
    [user?.email]
  );

  const onlineUser = useMemo(
    () => onlineUsers?.find((u: any) => u.id === user?.id),
    [onlineUsers, user?.id]
  );

  const isImgPng = useMemo(
    () => user?.avatar?.url?.includes('png'),
    [user?.avatar?.url]
  );

  return (
    <div
      className={cx(
        'user',
        {
          [`user--${size}`]: size,
          'user--with-border': withBorder && user?.avatar?.url,
        },
        className
      )}
      style={{
        border: !withBorder && isImgPng && '1px solid #EFEFEF',
        ...style,
        backgroundColor: !withBorder && isImgPng ? '#fff' : backgroundColor,
      }}
    >
      {isOnline && onlineUser && <div className="user__online" />}
      {user?.avatar?.url ? (
        <img
          className="user__photo"
          src={user?.avatar.url}
          alt={user?.lastName}
          height={30}
          style={
            isImgPng
              ? {
                  backgroundColor: '#fff',
                }
              : {}
          }
        />
      ) : (
        <span>{previewLetter}</span>
      )}
      {user?.available && <span className="user__label" />}
    </div>
  );
}

export default memo(User);

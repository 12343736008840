// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.users-list__item {
  position: relative;
  margin-left: 2px;
  cursor: pointer;
}
.users-list__item:first-child {
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/UsersList/UsersList.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;AACJ;AACI;EACE,cAAA;AACN","sourcesContent":[".users-list {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  &__item {\n    position: relative;\n    margin-left: 2px;\n    cursor: pointer;\n\n    &:first-child {\n      margin-left: 0;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

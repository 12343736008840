import React, { useState, useMemo, memo, useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import './Range.scss';
import { useThemeToggler } from '../../../context/ThemeContext';
import { mockPriorities } from '../../../helpers/constant';

type Props = {
  onChange?: (value: number) => void;
  onMouseUp?: any;
  className?: string;
  disabled?: boolean;
  initialValue?: number;
  value?: number;
  data?: {
    priority: number;
    title: string;
  }[];
  textClassName?: string;
  inputClassName?: string;
  activeTextClassName?: string;
  contentClassName?: string;
  dots?: boolean;
};

function RangeDots({
  length,
  rangeValue,
}: {
  length: any;
  rangeValue: number;
}): JSX.Element {
  return (
    <div className="range__dots">
      {Array.from({ length }).map((_, index) => {
        if (index + 1 === rangeValue) return null;
        return (
          <div
            key={Date.now() + index}
            className="range__dot"
            style={{
              left: `${index * (100 / (length - 1))}%`,
            }}
          />
        );
      })}
    </div>
  );
}

const Range = ({
  className,
  onChange,
  disabled,
  onMouseUp,
  textClassName,
  contentClassName,
  inputClassName,
  activeTextClassName,
  initialValue = 1,
  dots = true,
  data = mockPriorities,
  ...rest
}: Props): JSX.Element => {
  const { theme } = useThemeToggler();
  const [value, setValue] = useState<number>(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const val = Number(event.target.value);
    setValue(val);
  };

  const hasTouscSupport =
    'ontouchstart' in window || navigator.maxTouchPoints > 0;

  return (
    <div
      className={cx(
        'range',
        {
          [`range--${theme}`]: theme,
        },
        className
      )}
      style={{
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? 0.5 : 1,
      }}
    >
      {dots && <RangeDots length={data.length} rangeValue={value} />}
      <input
        style={
          {
            '--bg-position': `${(value - 1) * (100 / (data.length - 1))}%`,
          } as {
            [key: string]: string;
          }
        }
        className={cx('range__input', inputClassName)}
        min={1}
        max={data.length}
        value={value}
        type="range"
        onMouseUp={() => {
          if (value === initialValue) return;
          onMouseUp(value);
        }}
        onTouchEnd={() => {
          if (hasTouscSupport) {
            onMouseUp(value);
          }
        }}
        onChange={handleChange}
        {...rest}
      />
      <div className={cx('range__content', contentClassName)}>
        {data.map((item) => (
          <span
            onClick={() => {
              if (item.priority === initialValue) return;
              onMouseUp(item.priority);
            }}
            key={item.priority}
            className={cx(
              'range__text',
              {
                'range__text--active': item.priority === value,
                [`${activeTextClassName}`]: item.priority === value,
              },
              textClassName
            )}
          >
            {item.title}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Range;

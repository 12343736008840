import React, { forwardRef, useEffect, useState } from 'react';
import Tooltip from 'rc-tooltip';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import cx from 'classnames';

import { createTask } from '../../store/tasks/thunk';
import { AppDispatch } from '../../store';
import { TCreateTaskInputs } from './types';
import { fetchProjectList, getProjectData } from '../../store/projects/thunk';
import { getLocalStorage } from '../../helpers/common';
import { getUserData } from '../../store/user/thunk';

interface ICreateTaskProps {
  projectId: number;
  hideCreateTaskForm: (value: boolean) => void;
  slug: any;
}

const CreateTask = forwardRef(
  ({ hideCreateTaskForm, slug }: ICreateTaskProps, ref) => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const {
      register,
      handleSubmit,
      setError,
      setValue,
      formState: { errors },
    } = useForm<TCreateTaskInputs>();
    const [isSending, setIsSending] = useState<boolean>(false);

    useEffect(() => {
      dispatch(getUserData());
    }, []);

    const user = getLocalStorage('user') as any;
    const canSetTaskNegayno = user?.negaynoCheckbox;

    const onSubmit: SubmitHandler<TCreateTaskInputs> = async (
      data: TCreateTaskInputs
    ) => {
      const postData = {
        ...data,
        [canSetTaskNegayno && 'type']: Number(data.type),
        slug,
      };
      setIsSending(true);
      dispatch(createTask(postData))
        .unwrap()
        .then((task) => {
          navigate(`/task/${task.slug}/`, { replace: true });
          dispatch(fetchProjectList());
          hideCreateTaskForm(false);
        })
        .catch((e: AxiosError) => {
          const error: any = e?.response?.data;
          setError('name', {
            type: 'server',
            message: error.message,
          });
        })
        .finally(() => {
          setIsSending(false);
        });
    };

    return (
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="form__fieldset">
          <label
            ref={ref as any}
            htmlFor="name"
            className={cx('form__label form__label--custom', {
              'form__label--button': canSetTaskNegayno,
            })}
          >
            Додати нову задачу
            {!!canSetTaskNegayno && (
              <Tooltip
                placement="top"
                overlayInnerStyle={{ marginBottom: '8px' }}
                overlayStyle={{ marginBottom: '8px' }}
                overlay={<span>Негайно!</span>}
              >
                <div className="create-negayno">
                  <input
                    id="type"
                    type="checkbox"
                    className="create-negayno__checkbox"
                    {...register('type' as any)}
                    onChange={(e) => {
                      setValue('type', e.target.checked ? 1 : 0);
                      const input: any = document.getElementById('name');
                      if (
                        input &&
                        input !== document.activeElement &&
                        !input?.value
                      ) {
                        input.focus();
                      }
                    }}
                  />
                  <label htmlFor="type" className="create-negayno__label" />
                </div>
              </Tooltip>
            )}
          </label>

          <input
            id="name"
            className="form__input"
            placeholder="Введіть назву задачі"
            style={{ borderColor: errors.name && '#FF0000' }}
            {...register('name', { required: true })}
          />
        </fieldset>
        <div className="form__create-task-wrapper">
          {/* {canSetTaskNegayno && (
            <div className="create-negayno">
              <input
                id="type"
                type="checkbox"
                {...register('type' as any)}
                className="create-negayno__checkbox"
              />
              <label htmlFor="type" className="create-negayno__label">
                Negayno!
              </label>
            </div>
          )} */}
          <input
            disabled={isSending}
            type="submit"
            className="form__btn"
            value="Продовжити"
            style={{ opacity: isSending ? 0.8 : 1 }}
          />
        </div>
      </form>
    );
  }
);

export default CreateTask;

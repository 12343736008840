import React, { forwardRef, memo } from 'react';
import Select from 'react-select';
import cx from 'classnames';

import { useThemeToggler } from '../../../context/ThemeContext';

import './CustomSelect.scss';

const CustomSelect = forwardRef(
  (
    {
      options,
      placeholder,
      className,
      classNamePrefix,
      onchange,
      menuIsOpen,
      isSearchable = false,
      ...rest
    }: any,
    ref: any
  ): JSX.Element => {
    const { theme } = useThemeToggler();

    return (
      <Select
        menuIsOpen={menuIsOpen}
        onChange={onchange}
        controlRef={ref}
        isSearchable={isSearchable}
        options={options.map((item: any) => ({
          label: item.label,
          value: item.value,
          id: item.value,
          email: item.email,
          text: item.text,
          ...item,
        }))}
        placeholder={placeholder}
        className={cx(
          'custom-select',
          {
            [`custom-select--${theme}`]: theme,
          },
          className
        )}
        classNamePrefix={cx('custom-select', classNamePrefix)}
        {...rest}
      />
    );
  }
);

export default memo(CustomSelect);

import { useLayoutEffect, useState } from 'react';

export const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
): number[] => {
  const [textAreaHeight, setTextAreaHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = '0px';
      const { scrollHeight } = textAreaRef;
      setTextAreaHeight(scrollHeight);
      textAreaRef.style.height = `${scrollHeight}px`;
    }
  }, [value]);

  return [textAreaHeight];
};

import React, { InputHTMLAttributes, forwardRef, useEffect } from 'react';
import cx from 'classnames';

import './Input.scss';
import { inputNumberHandler } from '../../../helpers/common';

// interface IInputProps {
//   type?: string;
//   value?: string;
//   defaultValue?: string;
//   className?: string;
//   placeholder?: string;
//   style?: React.CSSProperties;
//   onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
// } & React.InputHTMLAttributes<HTMLInputElement>;

type IInputProps = InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      className,
      value,
      defaultValue,
      onChange,
      children,
      style = {},
      placeholder = '',
      type = 'text',
      ...rest
    },
    ref
  ) => {
    useEffect(() => {
      if (type === 'number') {
        document.querySelectorAll('input[type="number"]').forEach((el: any) => {
          el.addEventListener('keydown', inputNumberHandler);
        });
      }
    }, [type]);

    return (
      <div className={cx('input', className)}>
        <input
          ref={ref}
          value={value}
          defaultValue={defaultValue}
          type={type}
          placeholder={placeholder}
          className="input__field"
          style={style}
          onChange={onChange}
          {...rest}
        />
        {children}
      </div>
    );
  }
);

export default Input;

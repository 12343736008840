import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import Button from '../../../components/Button/Button';
import { AppDispatch } from '../../../store';
import { login } from '../../../store/auth/thunk';
import { LoginInputs } from '../types';
import { translateData } from '../constant';
import { getUserInfo } from '../../../store/user/store';

function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginInputs>();

  const onSubmit: SubmitHandler<LoginInputs> = (data: LoginInputs) => {
    dispatch(login(data))
      .unwrap()
      .then(() => {
        navigate('/', { replace: true });
        dispatch(getUserInfo());
      })
      .catch((e: AxiosError) => {
        const { error }: any = e?.response?.data;
        setError('email', {
          type: 'server',
          message: error,
        });
        setError('password', {
          type: 'server',
          message: error,
        });
      });
  };

  return (
    <>
      <div className="section__auth-form section__auth-form--login">
        <h1 className="form__title">{translateData.UA.signInText}</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="form__fieldset">
            <label
              className="form__label form__label--login form__label--custom"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="form__input form__input--login"
              id="email"
              style={{ borderColor: errors?.email?.message && '#FF0000' }}
              placeholder={translateData.UA.placeholderEmail}
              {...register('email', {
                required: translateData.UA.emailErrorMsg,
              })}
            />
            {errors?.email && (
              <span className="form__error">{errors.email?.message}</span>
            )}
          </fieldset>

          <fieldset className="form__fieldset">
            <label
              className="form__label form__label--login form__label--custom"
              htmlFor="password"
            >
              {translateData.UA.labelPassword}
            </label>
            <input
              className="form__input form__input--login"
              id="password"
              type="password"
              placeholder={translateData.UA.placeholderPassword}
              style={{ borderColor: errors?.password?.message && '#FF0000' }}
              {...register('password', {
                required: translateData.UA.passwordErrorMsg,
              })}
            />
            <NavLink className="form__extra-text" to="/restore-password/">
              {translateData.UA.forgotPasswordText}
            </NavLink>

            {errors.password && (
              <span className="form__error">{errors.password?.message}</span>
            )}
          </fieldset>

          <Button view="default" type="submit">
            {translateData.UA.signInBtnText}
          </Button>
        </form>
      </div>

      <div className="section__extra-info form__label--login">
        {translateData.UA.noLoginText}
        <NavLink to="/register/" style={{ marginLeft: '8px' }}>
          Зареєструватися
        </NavLink>
      </div>
    </>
  );
}

export default LoginPage;

import { createSlice } from '@reduxjs/toolkit';

import {
  fetchBillingBonuses,
  fetchBalance,
  fetchProjectBalance,
  fetchProjectBalanceTransactions,
  fetchBalanceTransactions,
  fetchBonusesStatistic,
  fetchBalanceStatistic,
  fetchProjectBalanceStatistic,
  fetchBonusesTransactions,
} from './thunk';

export interface BillingState {
  bonuses: any;
  balance: any;
  projectBalance: any;
  balanceTransactions: any;
  actualTransactions: any;
  bonusesStatistic: any;
  isTheLastTransaction: boolean;
  bonusesTransactions: any;
  balanceStatistic: any;
  isTheLastBalanceTransaction: boolean;
  projectStatistic: any;
}

const initialState = {
  bonuses: null,
  balance: null,
  projectBalance: null,
  actualTransactions: null,
  isTheLastTransaction: false,
  balanceTransactions: null,
  isTheLastBalanceTransaction: false,
  bonusesStatistic: null,
  bonusesTransactions: null,
  balanceStatistic: null,
  projectStatistic: null,
} as BillingState;

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    clearBillingData: () => initialState,
    clearBalanceTransactions: (state: BillingState) => {
      state.balanceTransactions = null;
      state.isTheLastBalanceTransaction = false;
    },
    clearActualTransactions: (state: BillingState) => {
      state.actualTransactions = null;
      state.isTheLastTransaction = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchBonusesTransactions.fulfilled,
      (state: BillingState, action) => {
        state.bonusesTransactions = action.payload;
      }
    );
    builder.addCase(
      fetchBillingBonuses.fulfilled,
      (state: BillingState, action) => {
        state.bonuses = action.payload;
      }
    );
    builder.addCase(fetchBillingBonuses.rejected, (state: BillingState) => {
      state.bonuses = null;
    });
    builder.addCase(fetchBalance.fulfilled, (state: BillingState, action) => {
      state.balance = action.payload;
    });
    builder.addCase(fetchBalance.rejected, (state: BillingState) => {
      state.balance = null;
    });
    builder.addCase(
      fetchProjectBalance.fulfilled,
      (state: BillingState, action) => {
        state.projectBalance = action.payload.balance;
      }
    );
    builder.addCase(fetchProjectBalance.rejected, (state: BillingState) => {
      state.projectBalance = null;
    });
    builder.addCase(
      fetchProjectBalanceTransactions.fulfilled,
      (state: BillingState, action) => {
        if (action.payload?.transactionList?.length === 0) {
          state.isTheLastTransaction = true;
        }
        if (!state.actualTransactions) {
          state.actualTransactions = {
            ...state.actualTransactions,
            ...action.payload,
          };
        } else {
          state.actualTransactions.transactionList = [
            ...action.payload?.transactionList,
          ];
        }
      }
    );
    builder.addCase(
      fetchProjectBalanceTransactions.rejected,
      (state: BillingState) => {
        state.actualTransactions = null;
      }
    );
    builder.addCase(
      fetchBalanceTransactions.fulfilled,
      (state: BillingState, action) => {
        if (action.payload?.transactionList?.length === 0) {
          state.isTheLastBalanceTransaction = true;
        }
        if (!state.balanceTransactions) {
          state.balanceTransactions = {
            ...state.balanceTransactions,
            ...action.payload,
          };
        } else {
          state.balanceTransactions.transactionList = [
            ...state.balanceTransactions.transactionList,
            ...action.payload?.transactionList,
          ];
        }
        // if (action.payload?.transactionList?.length === 0) {
        //   state.isTheLastBalanceTransaction = true;
        // }
        // if (!state.balanceTransactions) {
        //   state.balanceTransactions = {
        //     ...state.balanceTransactions,
        //     ...action.payload,
        //   };
        // } else {
        //   state.balanceTransactions.transactionList = [
        //     ...action.payload?.transactionList,
        //   ];
        // }
      }
    );
    builder.addCase(
      fetchBalanceTransactions.rejected,
      (state: BillingState) => {
        state.balanceTransactions = null;
      }
    );
    builder.addCase(
      fetchBonusesStatistic.fulfilled,
      (state: BillingState, action) => {
        state.bonusesStatistic = action.payload;
      }
    );
    builder.addCase(fetchBonusesStatistic.rejected, (state: BillingState) => {
      state.bonusesStatistic = null;
    });
    builder.addCase(
      fetchBalanceStatistic.fulfilled,
      (state: BillingState, action) => {
        state.balanceStatistic = action.payload;
      }
    );
    builder.addCase(
      fetchProjectBalanceStatistic.fulfilled,
      (state: BillingState, action) => {
        state.projectStatistic = action.payload;
      }
    );
  },
});

export const {
  clearBillingData,
  clearBalanceTransactions,
  clearActualTransactions,
} = billingSlice.actions;
const { reducer } = billingSlice;

export default reducer;

import React, { ReactNode, CSSProperties } from 'react';
import cx from 'classnames';

import Heading from '../Heading/Heading';
import Text from '../Text/Text';

import { ComponentSizes } from '../../../common/types';

import './Info.scss';
import User from '../../UsersList/User/User';

interface IInfo {
  user?: any;
  avatar?: {
    url?: string;
    text: string;
  };
  title?: string | ReactNode;
  text?: string | ReactNode;
  children?: ReactNode;
  className?: string;
  classNames?: {
    texts?: string;
    title?: string;
    text?: string;
    avatar?: string;
  };
  sizes?: {
    avatar?: ComponentSizes | 'sm' | 'md' | 'lg';
    title?: ComponentSizes;
    text?: ComponentSizes;
  };
  gap?: number | string;
  style?: CSSProperties;
  textVerticalCenter?: boolean;
  vertical?: boolean;
  dangerouslyHtml?: boolean;
  backgroundColor?: string;
  showOnline?: boolean;
}

export default function Info({
  avatar,
  title,
  text,
  children,
  className,
  classNames,
  sizes,
  style,
  gap,
  user,
  backgroundColor,
  showOnline = false,
  textVerticalCenter = false,
  dangerouslyHtml = false,
  vertical = false,
}: IInfo): JSX.Element {
  console.log('user', user, avatar);

  return (
    <div
      className={cx(
        'info',
        {
          'info--vertical': vertical,
        },
        className
      )}
      style={{
        ...style,
        gap: `${gap}px`,
      }}
    >
      {children}
      {avatar && (
        <User
          isOnline={showOnline}
          user={{ ...user, avatar }}
          size={sizes?.avatar || 'sm'}
          className={cx('info__picture', classNames?.avatar)}
          backgroundColor={backgroundColor}
        />
      )}
      <div
        className={cx('info__texts', classNames?.texts)}
        style={{ alignItems: textVerticalCenter ? 'center' : 'flex-start' }}
      >
        {title && (
          <Heading
            as="h4"
            size={sizes?.title || 'sm'}
            className={cx('info__title', classNames?.title)}
          >
            {title}
          </Heading>
        )}
        {text && (
          <Text
            size={sizes?.text || 'sm'}
            className={cx('info__text', classNames?.text)}
          >
            {dangerouslyHtml ? (
              <div dangerouslySetInnerHTML={{ __html: text as string }} />
            ) : (
              text
            )}
          </Text>
        )}
      </div>
    </div>
  );
}

import React, {
  ChangeEvent,
  useEffect,
  forwardRef,
  useState,
  useLayoutEffect,
} from 'react';
import cx from 'classnames';

import { useAutosizeTextArea } from '../../hooks/useAutosizeTextArea';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

type IAutoResizeTextareaProps = {
  name?: string;
  handleChangeName: (value: string) => void;
  maxLength?: number;
  className?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  defaultHeight?: number;
  onBlur?: () => void;
  isChatField?: boolean;
  disabled?: boolean;
  isFieldActive?: boolean;
  autofocus?: boolean;
  sendByEnter?: boolean;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const AutoResizeTextarea = forwardRef(
  (
    {
      handleChangeName,
      className,
      style,
      autofocus = false,
      name = '',
      placeholder = '',
      maxLength = Infinity,
      defaultHeight = 30,
      sendByEnter = false,
      isFieldActive = true,
      onBlur = () => {},
      disabled = false,
      isChatField = false,
      ...rest
    }: IAutoResizeTextareaProps,
    ref: any
  ) => {
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [value, setValue] = useState<string>(name);
    const [rowsCount, setRowsCount] = useState<number>(1);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [textAreaHeight] = useAutosizeTextArea(ref?.current, value);

    useOnClickOutside(ref, () => {
      setIsActive(false);
    });

    useLayoutEffect(() => {
      setValue('');
      setIsMounted(true);

      const sendByEnterHandler = (e: KeyboardEvent): void => {
        if (sendByEnter && e.ctrlKey && e.key === 'Enter') {
          e.preventDefault();
          handleBlur();
        }
      };

      ref.current.addEventListener('keyup', sendByEnterHandler);

      return () => {
        ref.current?.removeEventListener('keyup', sendByEnterHandler);
      };
    }, []);

    useEffect(() => {
      setValue(name);
      setIsActive(isFieldActive);
    }, [isMounted, name, isFieldActive]);

    useEffect(() => {
      setRowsCount((ref?.current?.offsetHeight as number) / defaultHeight);

      if (ref?.current?.closest('.main') && textAreaHeight) {
        document.documentElement.style.setProperty(
          '--section-header-height',
          `${textAreaHeight}px`
        );
      }
    }, [textAreaHeight, isMounted]);

    useEffect(() => {
      if (!value) {
        setRowsCount(1);
      }
    }, [value, isMounted]);

    useEffect(() => {
      setRowsCount((ref?.current?.offsetHeight as number) / defaultHeight);
    }, [textAreaHeight]);

    const handleBlur = (): void => {
      handleChangeName(value);
      ref?.current?.blur();
    };

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
      setValue(e.target.value);
      setIsActive(true);
      if (isChatField) {
        handleChangeName(e.target.value);
      }
    };

    return (
      <textarea
        ref={(r) => {
          ref.current = r;
          if (autofocus) {
            if (ref.current && isActive) {
              r?.focus();
            }
          }
        }}
        className={cx('form-change-name', className)}
        onChange={handleChange}
        onBlur={() => {
          handleBlur();
          onBlur();
        }}
        value={value}
        rows={rowsCount}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        style={{
          ...style,
          height: textAreaHeight ? `${textAreaHeight}px` : 'auto',
          cursor: disabled ? 'default' : 'auto',
        }}
        {...rest}
      />
    );
  }
);

export default AutoResizeTextarea;

import React, { memo } from 'react';
import cx from 'classnames';

import './Skeleton.scss';

interface ISkeletonProps {
  className?: string;
  count?: number;
  width?: string;
  height?: string;
}

export const Skeleton: React.FC<ISkeletonProps> = memo(
  ({ className, count = 2, width = '100%', height = '20px' }) => (
    <div className="skeleton">
      {Array(count)
        .fill('')
        .map((_, index) => (
          <span
            style={{ width, height }}
            key={index}
            className={cx('skeleton__item skeleton__item--animated', className)}
          >
            &zwnj;
          </span>
        ))}
    </div>
  )
);

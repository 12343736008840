import { useState } from 'react';

export const useModal = (initialMode = false): any => {
  const [modalOpen, setModalOpen] = useState(initialMode);
  const toggle = (): void => setModalOpen(!modalOpen);
  return [modalOpen, setModalOpen, toggle];
};

export const useModalWithData = (
  initialMode = false,
  initialSelected = null
): any => {
  const [modalOpen, setModalOpen] = useModal(initialMode);
  const [selected, setSelected] = useState(initialSelected);
  const setModalState = (state: any): void => {
    setModalOpen(state);
    if (state === false) {
      setSelected(null);
    }
  };
  return {
    modalOpen,
    setModalOpen,
    selected,
    setSelected,
    setModalState,
  };
};

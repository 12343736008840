import React, { useMemo } from 'react';

import './FileExtensions.scss';

import { ReactComponent as IconAi } from '../../../media/icons/extensions/001-adobe illustrator.svg';
import { ReactComponent as IconApk } from '../../../media/icons/extensions/002-apk.svg';
import { ReactComponent as IconCss } from '../../../media/icons/extensions/003-css.svg';
import { ReactComponent as IconDisc } from '../../../media/icons/extensions/004-disc.svg';
import { ReactComponent as IconDoc } from '../../../media/icons/extensions/005-doc.svg';
import { ReactComponent as IconExcel } from '../../../media/icons/extensions/006-excel.svg';
import { ReactComponent as IconFont } from '../../../media/icons/extensions/007-font file.svg';
import { ReactComponent as IconIso } from '../../../media/icons/extensions/008-iso.svg';
import { ReactComponent as IconJs } from '../../../media/icons/extensions/009-javascript.svg';
import { ReactComponent as IconImage } from '../../../media/icons/extensions/010-image.svg';
import { ReactComponent as IconJSFile } from '../../../media/icons/extensions/011-js file.svg';
import { ReactComponent as IconMail } from '../../../media/icons/extensions/012-mail.svg';
import { ReactComponent as IconMp3 } from '../../../media/icons/extensions/013-mp3.svg';
import { ReactComponent as IconVideo } from '../../../media/icons/extensions/014-video.svg';
import { ReactComponent as IconMusic } from '../../../media/icons/extensions/015-music.svg';
import { ReactComponent as IconPdf } from '../../../media/icons/extensions/016-pdf.svg';
import { ReactComponent as IconPhp } from '../../../media/icons/extensions/017-php.svg';
import { ReactComponent as IconPowerpoint } from '../../../media/icons/extensions/018-powerpoint.svg';
import { ReactComponent as IconPpt } from '../../../media/icons/extensions/019-ppt.svg';
import { ReactComponent as IconPsd } from '../../../media/icons/extensions/020-psd.svg';
import { ReactComponent as IconRec } from '../../../media/icons/extensions/021-record.svg';
import { ReactComponent as IconSql } from '../../../media/icons/extensions/022-sql.svg';
import { ReactComponent as IconSvg } from '../../../media/icons/extensions/023-svg.svg';
import { ReactComponent as IconText } from '../../../media/icons/extensions/024-text.svg';
import { ReactComponent as IconTtf } from '../../../media/icons/extensions/025-ttf.svg';
import { ReactComponent as IconTxt } from '../../../media/icons/extensions/026-txt.svg';
import { ReactComponent as IconMail2 } from '../../../media/icons/extensions/027-mail.svg';
import { ReactComponent as IconVector } from '../../../media/icons/extensions/028-vector.svg';
import { ReactComponent as IconWord } from '../../../media/icons/extensions/030-word.svg';
import { ReactComponent as IconXls } from '../../../media/icons/extensions/031-xls.svg';
import { ReactComponent as IconZip } from '../../../media/icons/extensions/032-zip.svg';

interface IFileExtensionsProps {
  type: string;
  className?: string;
}

const FileExtensions: React.FC<IFileExtensionsProps> = ({
  type: string,
  className,
}) => {
  const Icon = useMemo((): JSX.Element => {
    switch (string) {
      // video
      case 'wmv':
        return <IconVideo />;
      case 'mov':
        return <IconVideo />;
      case 'mp4':
        return <IconVideo />;
      case 'avi':
        return <IconVideo />;
      case 'h264':
        return <IconVideo />;
      case 'm4v':
        return <IconVideo />;
      case 'mpeg':
        return <IconVideo />;
      case 'mpg':
        return <IconVideo />;

      // picts
      case 'webp':
        return <IconImage />;
      case 'png':
        return <IconImage />;
      case 'jpe':
        return <IconImage />;
      case 'jpeg':
        return <IconImage />;
      case 'jpg':
        return <IconImage />;
      case 'gif':
        return <IconImage />;
      case 'bmp':
        return <IconImage />;
      case 'ico':
        return <IconImage />;
      case 'tiff':
        return <IconImage />;
      case 'tif':
        return <IconImage />;
      case 'svg':
        return <IconSvg />;

      // graphicks
      case 'psd':
        return <IconPsd />;
      case 'ai':
        return <IconAi />;
      case 'eps':
        return <IconVector />;
      case 'cdr':
        return <IconVector />;
      case 'fig':
        return <IconVector />;

      // archives
      case 'zip':
        return <IconZip />;
      case 'svgz':
        return <IconZip />;
      case 'rar':
        return <IconZip />;
      case 'tar':
        return <IconZip />;
      case 'targz':
        return <IconZip />;
      case '7z':
        return <IconZip />;
      case 'gz':
        return <IconZip />;
      case 'gzip':
        return <IconZip />;
      case 'tgz':
        return <IconZip />;
      case 'zipx':
        return <IconZip />;

      // text
      case 'txt':
        return <IconText />;
      case 'sql':
        return <IconSql />;
      case 'log':
        return <IconText />;
      case 'cfg':
        return <IconText />;
      case 'conf':
        return <IconText />;
      case 'ini':
        return <IconText />;

      // docs
      case 'doc':
        return <IconDoc />;
      case 'docx':
        return <IconDoc />;
      case 'pdf':
        return <IconPdf />;
      case 'rtf':
        return <IconDoc />;

      // tables
      case 'xls':
        return <IconXls />;
      case 'xlsx':
        return <IconXls />;
      case 'csv':
        return <IconXls />;

      // presentations
      case 'pptx':
        return <IconPpt />;
      case 'ppt':
        return <IconPpt />;

      // audio
      case 'm4a':
        return <IconMp3 />;
      case 'mp3':
        return <IconMp3 />;
      case 'wav':
        return <IconMp3 />;
      case 'wma':
        return <IconMp3 />;
      case 'ogg':
        return <IconMp3 />;
      case 'aac':
        return <IconMp3 />;
      case 'flac':
        return <IconMp3 />;
      case 'alac':
        return <IconMp3 />;
      case 'm3u':
        return <IconMp3 />;

      default:
        return <IconJSFile />;
    }
  }, [string]);

  return <div className={className}>{Icon}</div>;
};

export default FileExtensions;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading--xxl {
  font-size: 2.5rem;
}
.heading--xl {
  font-size: 2rem;
}
.heading--lg {
  font-size: 1.5rem;
}
.heading--md {
  font-size: 1.25rem;
}
.heading--sm {
  font-size: 1rem;
}
.heading--xs {
  font-size: 0.75rem;
}
.heading--inline {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Heading/Heading.scss"],"names":[],"mappings":"AAEI;EACI,iBAAA;AADR;AAII;EACI,eAAA;AAFR;AAKI;EACI,iBAAA;AAHR;AAMI;EACI,kBAAA;AAJR;AAOI;EACI,eAAA;AALR;AAQI;EACI,kBAAA;AANR;AASI;EACI,qBAAA;AAPR","sourcesContent":[".heading {\n\n    &--xxl {\n        font-size: 2.5rem;\n    }\n\n    &--xl {\n        font-size: 2rem;\n    }\n\n    &--lg {\n        font-size: 1.5rem;\n    }\n\n    &--md {\n        font-size: 1.25rem;\n    }\n\n    &--sm {\n        font-size: 1rem;\n    }\n\n    &--xs {\n        font-size: 0.75rem;\n    }\n\n    &--inline {\n        display: inline-block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '../../services/auth.service';
import {
  RegisterInputs,
  LoginInputs,
  RestoreInputs,
} from '../../pages/auth/types';

export const createAccount = createAsyncThunk(
  'auth/createAccount',
  async (data: RegisterInputs, thunkAPI) => {
    try {
      return await AuthService.register(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createStaffAccount = createAsyncThunk(
  'auth/createStaffAccount',
  async (data: RegisterInputs, thunkAPI) => {
    try {
      return await AuthService.staffRegister(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (data: LoginInputs, thunkAPI) => {
    try {
      return await AuthService.login(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const restorePassword = createAsyncThunk(
  'auth/restorePassword',
  async (data: RestoreInputs, thunkAPI) => {
    try {
      return await AuthService.restorePassword(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    return await AuthService.logout();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const setNewPassword = createAsyncThunk(
  'auth/setNewPassword',
  async ({ data, token }: any, thunkAPI) => {
    try {
      return await AuthService.setNewPassword(data, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getNicknames = createAsyncThunk(
  'auth/getNicknames',
  async (_, thunkAPI) => {
    try {
      return await AuthService.getNicknames();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSpecialities = createAsyncThunk(
  'auth/getSpecialities',
  async (_, thunkAPI) => {
    try {
      return await AuthService.getSpecialities();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

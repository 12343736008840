export const translateData = {
  UA: {
    forgotPasswordText: 'Забули пароль?',
    sendPasswordText: 'Посилання для відновлення надіслано!',
    checkEmailText: 'Тепер перевірте свою електронну пошту',
    noLoginText: 'Немає облікового запису?',
    continueWithText: 'Продовжити через',
    hasAccountText: 'У вас вже є аккаунт?',
    signInText: 'Вхід',
    signInBtnText: 'Увійти',
    restoreBtnText: 'Надіслати мені посилання',
    registerText: 'Реєстрація',
    or: 'або',
    firstNameErrorMsg: "Необхідно ввести ім'я",
    lastNameErrorMsg: 'Необхідно ввести прізвище',
    phoneErrorMsg: 'Необхідно ввести номер телефону',
    emailErrorMsg: 'Необхідно ввести електронну адресу',
    labelFirstName: "Ім'я",
    labelLastName: 'Прізвище',
    labelPhone: 'Телефон',
    labelConfirmPassword: 'Підтвердіть пароль',
    passwordErrorMsg: 'Необхідно ввести пароль',
    placeholderEmail: 'Введіть email',
    placeholderPassword: 'Введіть пароль',
    labelPassword: 'Пароль',
  },
};

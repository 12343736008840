import React from 'react';
import cx from 'classnames';

import './Dot.scss';

type Props = {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  variant?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  onClick?: () => void;
};

export default function Dot({
  className,
  children,
  style,
  variant = 'default',
  size = 'sm',
  onClick,
}: Props): JSX.Element {
  return (
    <div
      onClick={onClick}
      className={cx(
        'dot',
        {
          [`dot--${variant}`]: variant,
          [`dot--${children ? 'xxl' : size}`]: size,
        },
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
}

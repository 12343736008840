import dayjs from 'dayjs';
import { SITE_TITLE, TIME_VARIANTS } from '../common/constant';

export const setLocalStorage = (key: string, value: any): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key: string, defaultValue?: any): string => {
  let currentValue;

  try {
    currentValue = JSON.parse(
      localStorage.getItem(key) || String(defaultValue)
    );
  } catch (error) {
    currentValue = defaultValue;
  }

  return currentValue;
};

export const removeLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const capitializeFirstLetter = (str = ''): string =>
  str.trim().charAt(0).toUpperCase() + str.slice(1);

export const declOfNum = (number: number, titles: string[]): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const getTimeLeft = (date: number, isHours?: boolean): string => {
  const diffDate = Date.now() / 1000 - date;

  const seconds = Math.floor(diffDate);
  const minutes = Math.floor(diffDate / 60);
  const hours = Math.floor(diffDate / 60 / 60);

  const finalTime = hours ? hours : minutes ? minutes : seconds;

  const timeVariant = declOfNum(
    finalTime,
    isHours && hours
      ? TIME_VARIANTS.hours
      : minutes
      ? TIME_VARIANTS.minutes
      : TIME_VARIANTS.seconds
  );

  return `${finalTime} ${timeVariant} тому`;
};

export const isFullDate = (date: number, hours = false): boolean => {
  const t = (Date.now() / 1000 - date) / 60 / 60 / (hours ? 24 : 1);

  return t > 1;
};

export const getActionDate = (date: number): string =>
  !isFullDate(date)
    ? getTimeLeft(date)
    : isFullDate(date, true)
    ? dayjs.unix(date).format('YYYY.MM.DD, HH:mm')
    : dayjs.unix(date).format('HH:mm');

export const scrollToNode = (
  className: string,
  index?: number,
  smooth = false
): void => {
  const nodeCollection = document.querySelectorAll(`.${className}`);
  const lastNodeCollectionElement = nodeCollection?.[index as number];
  lastNodeCollectionElement?.scrollIntoView(
    smooth &&
      (index === 0
        ? { behavior: 'smooth', block: 'start' }
        : { behavior: 'smooth' })
  );
};

export const isTheSameAuthor = (userId: number, messageId: number): boolean =>
  userId === messageId ? true : false;

export const convertBytesToKbOrMbOrGb = (bytes: number): string => {
  let result: string;

  switch (true) {
    case bytes < 1024:
      result = `${bytes} bytes`;
      break;
    case bytes < 1048576:
      result = `${(bytes / 1024).toFixed(0)} КБ`;
      break;
    case bytes < 1073741824:
      result = `${(bytes / 1048576).toFixed(2)} МБ`;
      break;
    default:
      result = `${(bytes / 1073741824).toFixed(2)} ГБ`;
      break;
  }

  return result;
};

export const reduceStringWithThreeDots = (str: string, symbols = 5): string =>
  str.length <= symbols
    ? str
    : `${str.slice(0, str.length - symbols)} ..${str.slice(
        str.lastIndexOf('.')
      )}`;

export const getChartdataArrays = (arr: any[]): any[] => {
  const globalArr = [];

  const values = Object.values(arr[0]);
  const keys = Object.keys(arr[0]);

  for (let i = 0; i < values.length; i++) {
    if (keys[i] !== 'month') {
      const insetArr = arr.map((item: any) => {
        const c = Object.values(item);
        return c[i];
      });
      globalArr.push(insetArr);
    }
  }
  return globalArr;
};

export const inputNumberHandler = (e: any): any => {
  // Разрешить клавиши: Backspace, Delete, Tab, Escape, Enter, стрелки, Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
  if (
    [8, 9, 27, 13, 46, 37, 38, 39, 40].includes(e.keyCode) || // Backspace, Tab, Escape, Enter, Delete, стрелки
    (e.ctrlKey &&
      (e.keyCode === 65 ||
        e.keyCode === 67 ||
        e.keyCode === 86 ||
        e.keyCode === 88 ||
        e.key === 'r')) || // Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
    (e.keyCode >= 35 && e.keyCode <= 39) // Home, End, стрелки
  ) {
    return; // Разрешаем нажатие этих клавиш
  }

  // Разрешить ввод только цифр
  if (
    (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
    (e.keyCode < 96 || e.keyCode > 105)
  ) {
    e.preventDefault(); // Запрещаем ввод любых символов, кроме цифр
  }
};
// export const inputNumberHandler = (evt: any): void => {
//   if (
//     /[a-zA-Zа-яА-Я]/.test(evt.key) &&
//     ![
//       'Backspace',
//       'Delete',
//       'ArrowLeft',
//       'ArrowRight',
//       'Control',
//       'Alt',
//       'Shift',
//       'Tab',
//       'Enter',
//     ].includes(evt.key) &&
//     !(evt.key === 'a' && evt.ctrlKey) &&
//     !(evt.key === 'ф' && evt.ctrlKey) &&
//     !(evt.key === 'c' && evt.ctrlKey) &&
//     !(evt.key === 'v' && evt.ctrlKey) &&
//     !(evt.key === 'x' && evt.ctrlKey) &&
//     !(evt.key === 'z' && evt.ctrlKey) &&
//     !(evt.key === 'r' && evt.ctrlKey)
//   ) {
//     evt.preventDefault();
//   }
// };

export const setDocumentTitle = (title = ''): void => {
  document.title = `${SITE_TITLE} ${title}`;
};

export const setCursorPosition = (
  target: any,
  text: string,
  injectingText: string
): string => {
  const cursorPosition = target?.selectionStart;
  const textBefore = text.substring(0, cursorPosition);
  const textAfter = text.substring(cursorPosition as number);
  return `${textBefore}${injectingText}${textAfter}`;
};

export const formatDate = (date: any): any => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

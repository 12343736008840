import React, { memo, useEffect, useRef, useState } from 'react';

import './Dropzone.scss';

interface IDropzoneProps {
  addFiles?: (files: File[]) => void;
  targetClassName?: string;
}

const Dropzone: React.FC<IDropzoneProps> = ({
  addFiles = () => {},
  targetClassName,
}) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const preventDefaultHandler = (e: React.DragEvent<HTMLElement>): void => {
      e.preventDefault();
      e.stopPropagation();
    };

    const dragOverHandler = (e: any): void => {
      preventDefaultHandler(e);
      const { clientY, clientX, target } = e;
      if (!target.closest(`.${targetClassName}`)) {
        if (!isDragActive) {
          setIsDragActive(false);
        } else {
          setIsDragActive(true);
        }
      }
    };

    const dragEnterHandler = (e: any): void => {
      preventDefaultHandler(e);
      if (e.target.closest(`.${targetClassName}`)) {
        setIsDragActive(true);
      }
    };

    const dragLeaveHandler = (e: any): void => {
      preventDefaultHandler(e);
      const { clientY, clientX } = e;
      const offset = 1;
      if (
        clientY < offset ||
        clientX < offset ||
        clientY > window.innerHeight - offset ||
        clientY < offset ||
        clientX > window.innerWidth - offset ||
        clientX < offset
      ) {
        if (!isDragActive) {
          setIsDragActive(false);
        } else {
          setIsDragActive(true);
        }
      }
    };

    const dropHandler = (e: any): void => {
      preventDefaultHandler(e);
      if (e.target.closest(`.${targetClassName}`)) {
        const filesData = Array.from(e.dataTransfer.files);
        addFiles(filesData as File[]);
        setIsDragActive(false);
      }
    };

    const handleMouseMove = (): void => {
      if (isDragActive) {
        setIsDragActive(false);
      }
    };

    if (ref.current) {
      document.addEventListener('dragover', dragOverHandler);
      document.addEventListener('dragenter', dragEnterHandler);
      document.addEventListener('dragleave', dragLeaveHandler);
      document.addEventListener('drop', dropHandler);
      ref.current.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('dragover', dragOverHandler);
      document.removeEventListener('dragenter', dragEnterHandler);
      document.removeEventListener('dragleave', dragLeaveHandler);
      document.removeEventListener('drop', dropHandler);
    };
  }, []);

  useEffect(() => () => addFiles([]), [isDragActive]);

  return (
    <div ref={ref}>
      <div
        className="drop-zone"
        style={{
          zIndex: isDragActive ? 9999 : 'auto',
        }}
      >
        <div
          className="drop-zone__inner"
          style={{ opacity: isDragActive ? 0.9 : 0 }}
        />
      </div>
    </div>
  );
};

export default memo(Dropzone);

import React from 'react';

interface AuthContextType {
  isLoggedIn: boolean;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth(): AuthContextType {
  return React.useContext(AuthContext);
}

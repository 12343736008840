// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-list {
  --item-max-width: 200px;
  --column-gap: 16px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(25% - var(--column-gap), var(--item-max-width)), 1fr));
  grid-auto-rows: 138px;
  gap: var(--column-gap) 20px;
  margin: -20px -20px 0;
  padding: 20px 20px 5px;
}`, "",{"version":3,"sources":["webpack://./src/layout/ProjectList/ProjectList.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,0GAAA;EACA,qBAAA;EAEA,2BAAA;EAEA,qBAAA;EACA,sBAAA;AADJ","sourcesContent":[".project-list {\n    --item-max-width: 200px;\n    --column-gap: 16px;\n    overflow-y: auto;\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(max(calc(25% - var(--column-gap)), var(--item-max-width)), 1fr));\n    grid-auto-rows: 138px;\n    // height: 100%;\n    gap: var(--column-gap) 20px;\n\n    margin: -20px -20px 0;\n    padding: 20px 20px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';

const portalRoot = document.querySelector('#root') as HTMLElement;

type PortalProps = {
  children: ReactNode;
  node?: HTMLElement | null;
};

const Portal = ({ children, node }: PortalProps): JSX.Element => {
  const elRef = useRef<HTMLDivElement | null>(null);
  if (!elRef.current) elRef.current = document.createElement('div');

  useEffect(() => {
    const el = elRef.current!;

    if (!node) {
      portalRoot.appendChild(el);

      return () => {
        portalRoot.removeChild(el);
      };
    } else {
      node.appendChild(el);

      return () => {
        node.removeChild(el);
      };
    }
  }, [node]);

  return createPortal(children, elRef.current);
};

export { Portal };

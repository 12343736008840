import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import Text from '../UI/Text/Text';
import Dot from '../UI/Dot/Dot';
import User from '../UsersList/User/User';

import { getActionDate, getTimeLeft, isFullDate } from '../../helpers/common';
import { useThemeToggler } from '../../context/ThemeContext';

interface INotificationItemProps {
  full?: boolean;
  onClick?: (e: any) => void;
  user?: any;
  notification?: any;
  onViewed?: (e: any) => void;
}

function NotificationItem({
  onClick,
  user = {},
  onViewed,
  notification = {},
  full = false,
}: INotificationItemProps): JSX.Element {
  const { theme } = useThemeToggler();
  const { text, header, status } = notification;
  let textLinkEdited = text;

  if (typeof text === 'string' && text.includes('<a href="')) {
    textLinkEdited = textLinkEdited.replace(
      /<a /g,
      '<a target="_blank" rel="noopener noreferrer" '
    );
  }

  return (
    <div
      onClick={onClick}
      className={cx('notification-item', {
        'notification-item--viewed': status,
        [`notification-item--${theme}`]: theme,
      })}
    >
      {full && (
        <div className="breadcrumbs notification-item__breadcrumbs">
          <NavLink className="breadcrumbs__link" to="sdsd">
            sdsd
          </NavLink>
          <NavLink className="breadcrumbs__link" to="sdsd">
            sdsd
          </NavLink>
          <NavLink className="breadcrumbs__link" to="sdsd">
            sdsd
          </NavLink>
        </div>
      )}
      {/* <IconArrowRight /> */}
      <User user={user} size="lg" className="notification-item__avatar" />
      <div className="notification-item__texts">
        <Text as="span" size="md" className="notification-item__title">
          <div
            dangerouslySetInnerHTML={{
              __html: textLinkEdited,
            }}
          />
        </Text>
        {!status && (
          <Dot
            variant="warning"
            size="md"
            className="notification-item__status-marker"
            onClick={onViewed as any}
          />
        )}
        <div className="notification-item__date">
          {!isFullDate(notification?.date, true)
            ? getTimeLeft(notification?.date, true)
            : getActionDate(notification?.date)}
        </div>
      </div>
    </div>
  );
}

export default NotificationItem;

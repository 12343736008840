import React from 'react';
import { Outlet } from 'react-router-dom';

import useDocumentTitle from '../../hooks/useDocumentTitle';

const AppLayout = (): JSX.Element => {
  useDocumentTitle();

  return <Outlet />;
};

export default AppLayout;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'quill-image-resize-module-react';
import ResizeModule from '@botom/quill-resize-module';
import cx from 'classnames';

import 'tinymce/tinymce';
import 'tinymce/themes/silver';
// import 'tinymce/plugins/paste';
import 'tinymce/models/dom/model';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/media';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/autoresize';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
// import 'tinymce/skins/content/default/content.min.css';
import 'react-quill/dist/quill.snow.css';

import './RichTextEditor.scss';
import { Editor } from '@tinymce/tinymce-react';

const tinymce = require('tinymce');

// import tinymce from 'tinymce/tinymce';

interface IRichTextEditorProps {
  content: string;
  onChangeDescription: (value: string) => void;
  style?: any;
  theme?: string;
  editorName: string;
  readOnly?: boolean;
}

const RichTextEditor = ({
  content,
  editorName,
  theme,
  onChangeDescription,
  style = {},
  readOnly = false,
}: IRichTextEditorProps): JSX.Element => {
  const [value, setValue] = useState<string>(content);
  const [prevValue, setPrevValue] = useState<string>('');
  const [contentUpdatesCount, setContentUpdatesCount] = useState<number>(0);
  const quillRef = useRef(null);
  const editorRef = useRef(null);

  const styles = `html { min-height: 224px!important; } 
                  body { font-size: 15px;min-height: 224px; cursor: auto;font-family: "Noto Sans", sans-serif;
                    ${theme === 'dark' && ' color: #fff !important;'}
                  }
                  img { max-width: 100%;} 
                  .tox-menu { 
                    ${
                      theme === 'dark' &&
                      'background-color: #1e1e1e !important;'
                    }
                    }`;

  useEffect(() => {
    if (content) {
      setValue(content);
      setPrevValue(content);
      setContentUpdatesCount((prev) => prev + 1);
    }
  }, [content]);

  useEffect(() => {
    const iframe = document.getElementById('tinymce_ifr') as HTMLIFrameElement;
    if (!iframe) {
      return;
    }
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow?.document;
    if (!iframeDocument) {
      return;
    }

    const editor = tinymce.get('tinymce'); // Replace 'tinymce' with your editor's ID
    if (!editor) {
      return;
    }

    const handleBlurPress = (e: any): void => {
      // if (value !== content && !e.target.closest('.editor')) {
      if (value !== content) {
        onChangeDescription(value);
      }
    };

    editor.on('blur', handleBlurPress);

    return () => {
      editor.off('blur', handleBlurPress);
    };
  }, [content, value]);

  const handleEditorChange = (ct: any, editor: any): any => {
    setValue(ct);
  };

  const isMobile =
    'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0;

  return (
    <div
      className={cx('editor', {
        [`editor--${theme}`]: theme,
      })}
      data-text-editor={editorName}
      style={style}
    >
      <Editor
        initialValue={content}
        onInit={(evt, editor) => {
          // editorRef.current = editor;
        }}
        init={{
          menubar: false,
          contextmenu: isMobile && 'image table',
          plugins: [
            'link',
            'lists',
            'image',
            'code',
            'autoresize',
            'advlist',
            'charmap',
            'table',
            'media',
          ],
          toolbar:
            'bold italic underline strikethrough | \
            forecolor backcolor | \
            alignleft aligncenter alignright alignjustify | \
            Blocks | bullist numlist | blockquote code | \
            outdent indent | \
            link image media | removeformat',
          skin: false,
          statusbar: false,
          content_css: false,
          branding: false,
          block_formats: 'Paragraph=p; Header 1=h1;Header 2=h2;Header 3=h3',
          autoresize_bottom_margin: 0,
          autoresize_overflow_padding: 0,
          autoresize_on_init: true,
          file_picker_types: 'image code',
          media_live_embeds: true,
          image_advtab: true,
          file_picker_callback: (cb, val, meta) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.addEventListener('change', (e: any) => {
              const file = e.target.files[0];

              const reader = new FileReader();
              reader.addEventListener('load', () => {
                const id = `blobid${new Date().getTime().toString()}`;
                const { blobCache } = (window as any).tinymce.activeEditor
                  .editorUpload;
                const base64 = (reader as any).result.split(',')[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                cb(blobInfo.blobUri(), { title: file.name });
              });
              reader.readAsDataURL(file);
            });

            input.click();
          },
          content_style: styles,
        }}
        onEditorChange={handleEditorChange}
        disabled={readOnly}
        id="tinymce"
      />
    </div>
  );
};

export default RichTextEditor;

import axios, { AxiosRequestConfig } from 'axios';
import { openDB } from 'idb';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 100000,
};

const templateOptions = { ...defaultOptions, baseURL };

export const instance = axios.create(templateOptions);
export const projectInstance = axios.create(templateOptions);
export const projectInstanceWithFiles = axios.create(templateOptions);

const tokenInstanceMiddleware =
  (files = false, token = '') =>
  async (req: AxiosRequestConfig): Promise<any> => {
    const tokenFromLocalStorage = localStorage.getItem('token');

    req.headers = {
      Authorization: `Bearer ${token || tokenFromLocalStorage}`,
      'Content-Type': files ? 'multipart/form-data' : 'application/json',
    };

    return req;
  };

export const interceptorsInit = (token?: string): void => {
  if (token) {
    localStorage.setItem('token', token);
  }
  (projectInstance.interceptors.request as any).handlers = [];
  projectInstance.interceptors.request.use(
    tokenInstanceMiddleware(false, token),
    (error) =>
      Promise.reject(
        new Error(`Error in request interceptor: ${error.message}`)
      )
  );
};

interceptorsInit();

projectInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.code === 'ERR_NETWORK') {
      console.error('Network Error: Could not connect to the server.');
    }

    if (error.response) {
      console.error('Error Response:', error.response);
      if (error.response.status >= 400 && error.response.status < 500) {
        console.log('Client Error:', error.response.status);
      } else if (error.response.status >= 500) {
        console.log('Server Error:', error.response.status);
      }
    } else if (error.request) {
      console.error('Error Request:', error.request);
      console.log('Request was made but no response received.');
    } else {
      console.error('Error Message:', error.message);
    }

    await saveRequestInIndexedDB(error.config);
    return Promise.reject(error);
  }
);

// projectInstance.interceptors.response.use(null as any, async (error) => {
//   // if (!navigator.onLine) {
//   if (error.code === 'ERR_NETWORK') {
//     // что то надо здесь сделать
//   }
//   if (error.response) {
//     console.log(error.response, 'error response');
//   }
//   if (error.request) {
//     console.log(error.request, 'error request');
//   }
//   await saveRequestInIndexedDB(error.config);
//   // }

//   return Promise.reject(error);
// });
projectInstanceWithFiles.interceptors.request.use(
  tokenInstanceMiddleware(true)
);

const saveRequestInIndexedDB = async (request: any): Promise<void> => {
  if (!request.url.includes('message')) {
    return;
  }
  const db = await openDB('offlineRequests', 3, {
    upgrade(d) {
      d.createObjectStore('postRequests', {
        keyPath: 'data',
      });
    },
  });

  const serializableRequest = {
    url: request.url,
    method: request.method,
    data: request.data,
    headers: request.headers,
  };

  const tx = db.transaction('postRequests', 'readwrite');
  await tx.store.add(serializableRequest);
  await tx.done;
  (window as any).deleteFromIndexedDB = async (data: any) => {
    const txdb = db.transaction('postRequests', 'readwrite');
    await txdb.store.delete(data);
    await txdb.done;
  };
};

const onOnlineHandler = async (): Promise<void> => {
  const db = await openDB('offlineRequests');
  const requests = await db.getAll('postRequests');
  for (const request of requests) {
    try {
      request.url = `${baseURL}${request.url}`;
      await axios(request);
      const tx = db.transaction('postRequests', 'readwrite');
      await tx.store.delete(request.data);
      await tx.done;
    } catch (error) {
      console.error('Failed to retry request', error);
    }
  }
};

window.addEventListener('online', () => {
  setTimeout(onOnlineHandler, 4500);
});

import { useEffect } from 'react';

export function useOnClickOutside(
  ref: React.RefObject<any>,
  handler: any
): any {
  useEffect(() => {
    const listener = (event: any): void => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchend', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [ref, handler]);
}

import React, { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'rc-tooltip';

import User from './User/User';

import { TUserData } from '../../layout/ProjectList/types';

import './UsersList.scss';
import { PROJECT_ROLES_COLORS } from '../../common/constant';

interface IUsersListProps {
  usersArray: TUserData[];
  size: string;
  withBorder?: boolean;
  tooltipContent?: (user: TUserData) => any;
  onClick?: () => void;
}

type TOnlineUser = {
  id: number;
  isOnline: boolean;
};

function UsersList({
  usersArray,
  size,
  onClick,
  withBorder = false,
  tooltipContent = (): any => {},
}: IUsersListProps): JSX.Element {
  const { onlineUsers } = useSelector(
    (state: { userData: any }) => state.userData
  );
  const usersList = useMemo(
    () =>
      usersArray.map((user) => ({
        ...user,
        photo: user.avatar?.url,
        isOnline: !!onlineUsers?.find(
          (item: TOnlineUser) => item.id === user.id
        )?.isOnline,
      })),
    [usersArray]
  );

  return (
    <ul onClick={onClick} className="list users-list">
      {usersList?.map(
        (user: TUserData): JSX.Element => (
          <Tooltip
            key={user.id}
            placement="top"
            trigger={['hover']}
            overlay={
              <span>
                {user.firstName
                  ? `${user.firstName} ${user.lastName}`
                  : 'Запрошений користувач'}
              </span>
            }
          >
            <li className="users-list__item">
              <User
                isOnline={user.isOnline}
                user={user}
                size={size}
                withBorder={withBorder}
                backgroundColor={
                  user.statusId === 1
                    ? PROJECT_ROLES_COLORS[user.roleId]
                    : '#C1C1C1'
                }
              />
            </li>
          </Tooltip>
        )
      )}
    </ul>
  );
}

export default memo(UsersList);

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ProjectsPage from './ProjectsPage/ProjectsPage';
import MainPage from './MainPage/MainPage';
import { checkPermission } from '../../hooks/checkPermission';

const Dashboard = (): JSX.Element => {
  const navigation = useNavigate();
  useEffect(() => {
    if (!checkPermission('CLIENT')) {
      navigation('/tasks/');
    }
  }, [navigation]);
  return checkPermission('CLIENT') ? <ProjectsPage /> : <MainPage />;
};

export default Dashboard;

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Tooltip from 'rc-tooltip';

import Dropzone from '../Dropzone/Dropzone';
import { FileAttachment } from '../FileAttachment/FileAttachment';
import Gallery from '../Gallery/Gallery';

import './FileUpload.scss';
import { allExtensions } from '../../helpers/constant';
import { Portal } from '../Portal/Portal';

import { ReactComponent as IconClose } from '../../media/icons/close_big.svg';
import { ReactComponent as IconCheck } from '../../media/icons/check.svg';
import { useThemeToggler } from '../../context/ThemeContext';

interface IFileUploadProps {
  maxFileSize?: number;
  supportedExtensions?: string[];
  handleAddFile: (data: any, config: any) => any;
  taskId: string;
  fileItems: any[];
  onDeleteFile: (id: string) => void;
  onRenameFile?: any;
  nodeRef?: HTMLElement | null;
  withFiles?: boolean;
  canDelete?: boolean;
  onOpenGallery: (file: any) => void;
  dndFiles?: any[];
}

type CurrentFile = {
  name: string;
  progress: number;
};

const FileUpload = forwardRef(
  (
    {
      maxFileSize = 1000000,
      supportedExtensions = ['jpg'],
      handleAddFile,
      taskId,
      fileItems,
      onOpenGallery,
      onDeleteFile,
      onRenameFile,
      nodeRef,
      withFiles = false,
      canDelete = false,
      dndFiles,
    }: IFileUploadProps,
    ref
  ): JSX.Element | null => {
    const { theme } = useThemeToggler();
    const isUploading = useRef<boolean>(false);
    const [notSupportedFiles, setNotSupportedFiles] = useState<any[]>([]);

    const [addedFiles, setAddedFiles] = useState<any[]>([]);
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const [index, setIndex] = useState<number>(0);

    const [currentFile, setCurrentFile] = useState<CurrentFile>({
      name: '',
      progress: 0,
    });

    const [filesUploadingProgress, setFilesUploadingProgress] = useState<
      {
        name: string;
        progress: number;
      }[]
    >([] as any);

    const controller = useRef<AbortController>();
    const allAmountFiles = useRef<number>(0);

    const handleFilesUpload = (f: any, i = 0): any => {
      isUploading.current = true;

      const data = new FormData();
      const blob = new Blob([f], { type: f.type });
      const blobFile = new File([blob], f.name, { type: f.type });

      data.append(`file[${i}]`, blobFile);
      data.append('task_id', taskId);

      controller.current = new AbortController();

      const promise = new Promise(
        (resolve: any): Promise<any> =>
          handleAddFile(data, {
            onUploadProgress: (progressEvent: any) => {
              if (progressEvent.lengthComputable) {
                const progress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                );

                setCurrentFile({
                  name: f.name,
                  progress,
                });

                if (progressEvent.loaded === progressEvent.total) {
                  controller.current = undefined;
                  isUploading.current = false;

                  resolve();
                }
              }
            },

            signal: controller.current?.signal,
          })
      );
      return promise;
    };

    useEffect(() => {
      const beforeLeave = (e: any): any => {
        if (filesToUpload[0]?.size) {
          e.preventDefault();
          return (e.returnValue = 'Наразі є не завершені завантаження файлів');
        }
      };

      window.addEventListener('beforeunload', beforeLeave);
      return () => {
        window.removeEventListener('beforeunload', beforeLeave);
      };
    }, [filesToUpload]);

    useEffect(() => {
      if (filesToUpload.length && !isUploading.current) {
        setFilesToUpload((prev) => {
          const newFiles = [...prev];
          if (newFiles.length) {
            newFiles.splice(0, 1);
          }
          return newFiles;
        });
        setCurrentFile({
          name: '',
          progress: 0,
        });
      }
    }, [fileItems]);

    useEffect(() => {
      for (let file of filesToUpload) {
        if (!isUploading.current) {
          handleFilesUpload(file);
        }
      }
    }, [filesToUpload, index]);

    useEffect(() => {
      if (dndFiles) {
        addFiles(dndFiles);
      }
    }, [dndFiles]);

    const addFiles = (source: any): void => {
      const uploadedFiles = []!;
      const files = source;

      for (let file of files as any) {
        if (file?.size !== 0) {
          // check file extension for support
          // const ext = file.name.slice(file.name.lastIndexOf('.') + 1);
          // how to get filex extension from file name on mac os
          // const ext = file.name.split('.').pop();
          const fileName = file.name;
          const parts = fileName.split('.');
          const extension = parts[parts.length - 1];
          console.log(fileName, extension, file?.size);
          if (
            !supportedExtensions.includes(extension) ||
            file?.size > maxFileSize
          ) {
            setNotSupportedFiles((prev) => [...prev, file]);
          } else {
            uploadedFiles.push(file as never);
          }
        }
      }
      allAmountFiles.current = uploadedFiles.length;
      setFilesToUpload((prev: any) => [...prev, ...uploadedFiles]);
    };

    const addFilesHandler = (e: any): void => {
      addFiles(e.target.files);
      e.target.value = '';
    };

    return nodeRef ? (
      <Portal node={nodeRef}>
        <Content
          withFiles={
            !!withFiles && (!!fileItems?.length || !!filesToUpload?.length)
          }
          theme={theme}
          maxFileSize={maxFileSize}
          canDelete={canDelete}
          fileItems={fileItems}
          onDeleteFile={onDeleteFile}
          onRenameFile={onRenameFile}
          filesUploadingProgress={currentFile}
          setFilesUploadingProgress={setFilesUploadingProgress}
          filesToUpload={filesToUpload}
          setFilesToUpload={setFilesToUpload}
          notSupportedFiles={notSupportedFiles}
          setAddedFiles={setAddedFiles}
          setIndex={setIndex}
          controller={controller}
          handlerOpenGallery={onOpenGallery}
          addFilesHandler={addFilesHandler}
          ref={ref}
          setCurrentFile={setCurrentFile}
        />
      </Portal>
    ) : (
      <Content
        maxFileSize={maxFileSize}
        withFiles={withFiles}
        canDelete={canDelete}
        fileItems={fileItems}
        onDeleteFile={onDeleteFile}
        onRenameFile={onRenameFile}
        filesUploadingProgress={currentFile}
        setFilesUploadingProgress={setFilesUploadingProgress}
        filesToUpload={filesToUpload}
        setFilesToUpload={setFilesToUpload}
        notSupportedFiles={notSupportedFiles}
        setAddedFiles={setAddedFiles}
        setIndex={setIndex}
        controller={controller}
        handlerOpenGallery={onOpenGallery}
        addFilesHandler={addFilesHandler}
        ref={ref}
        isUploading={isUploading}
        setCurrentFile={setCurrentFile}
      />
    );
  }
);

export default FileUpload;

const Content = forwardRef(
  (
    {
      maxFileSize,
      withFiles,
      canDelete,
      fileItems,
      onDeleteFile,
      onRenameFile,
      filesUploadingProgress,
      setFilesUploadingProgress,
      filesToUpload,
      setFilesToUpload,
      notSupportedFiles,
      setAddedFiles,
      setIndex,
      controller,
      handlerOpenGallery,
      addFilesHandler,
      isUploading,
      theme,
      setCurrentFile,
    }: any,
    ref: any
  ): JSX.Element => (
    <div className="flex flex-col items-center">
      {withFiles && (
        <div className="description-task__attachments-items">
          {!!fileItems &&
            [...fileItems.slice().reverse(), ...filesToUpload]?.map(
              (file: any, idx: number) => (
                <FileAttachment
                  key={idx}
                  file={file}
                  canDelete={canDelete}
                  onOpen={handlerOpenGallery}
                  onRename={(value: string) => onRenameFile(file.id, value)}
                  progress={
                    filesUploadingProgress?.progress &&
                    file.name === filesUploadingProgress?.name
                      ? filesUploadingProgress?.progress
                      : 0
                  }
                  className="description-task__attachments-item"
                  onCancel={() => {
                    controller.current?.abort();
                    setIndex(idx);

                    const upldateFilesListFilter = (prev: any): any =>
                      prev.filter(
                        ({ name }: any) =>
                          name !== prev[idx - fileItems.length].name
                      );

                    setFilesToUpload(upldateFilesListFilter);
                    setFilesUploadingProgress(upldateFilesListFilter);
                    setAddedFiles(upldateFilesListFilter);

                    controller.current = undefined;
                  }}
                  onDelete={() => {
                    onDeleteFile(file.id);
                  }}
                />
              )
            )}
          {notSupportedFiles?.map((file: any) => (
            <FileAttachment
              key={Date.now() * Math.random()}
              file={file}
              className="description-task__attachments-item--fake"
              notSupported
              largeFile={file.size > maxFileSize}
            />
          ))}
        </div>
      )}
      {!withFiles && !!filesToUpload.length && (
        <div className="file-uploading-short__wrap">
          {filesToUpload?.map((file: any, idx: number) => (
            <div
              key={Date.now() * Math.random()}
              className="file-uploading-short"
            >
              <div className="file-uploading-short__item">
                <div className="file-uploading-short__left">
                  <div
                    className="file-uploading-short__name"
                    style={{
                      backgroundColor:
                        filesUploadingProgress?.progress === 100 &&
                        filesUploadingProgress?.progress &&
                        file.name === filesUploadingProgress?.name
                          ? '#5987F8'
                          : '#C1C1C1',
                    }}
                  >
                    {file?.name}
                  </div>
                  {filesUploadingProgress?.progress !== 100 && (
                    <IconClose
                      className="file-uploading-short__close"
                      onClick={() => {
                        controller.current?.abort();
                        setIndex(idx);

                        const upldateFilesListFilter = (prev: any): any =>
                          prev.filter(
                            ({ name }: any) =>
                              name !== prev[idx - fileItems.length].name
                          );

                        setFilesToUpload(upldateFilesListFilter);
                        setFilesUploadingProgress(upldateFilesListFilter);
                        setAddedFiles(upldateFilesListFilter);

                        controller.current = undefined;
                      }}
                    />
                  )}
                </div>
                <div className="file-uploading-short__right">
                  <div className="file-uploading-short__progress">
                    {/* {filesUploadingProgress?.progress}%{' '} */}
                    {filesUploadingProgress?.progress &&
                    file.name === filesUploadingProgress?.name
                      ? `${filesUploadingProgress?.progress}%`
                      : '0%'}
                    <div
                      className="file-uploading-short__progress-bar"
                      style={
                        {
                          '--posX': `${
                            filesUploadingProgress?.progress &&
                            file.name === filesUploadingProgress?.name
                              ? filesUploadingProgress?.progress
                              : 0
                          }%`,
                        } as any
                      }
                    />
                  </div>
                  {file.name === filesUploadingProgress?.name &&
                    filesUploadingProgress?.progress &&
                    filesUploadingProgress?.progress === 100 && (
                      <IconCheck className="file-uploading-short__progress-check" />
                    )}
                </div>
              </div>
            </div>
          ))}
          {/* {notSupportedFiles?.map((file: any) => {
            console.log('file', file);
            return null;
          })} */}
        </div>
      )}

      <input
        ref={ref as any}
        type="file"
        multiple
        className="drop-block__input"
        onChange={addFilesHandler}
      />
    </div>
  )
);

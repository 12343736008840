import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, set } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import Button from '../../../components/Button/Button';
import { translateData } from '../constant';
import { createAccount } from '../../../store/auth/thunk';
import { AppDispatch } from '../../../store';
import { RegisterInputs } from '../types';
import addServerErrors from '../../../helpers/server';
import { emailValidationString } from '../../../helpers/constant';

function ClientRegister(): JSX.Element {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm<RegisterInputs>();

  const onSubmit: SubmitHandler<RegisterInputs> = (data: RegisterInputs) => {
    dispatch(createAccount(data))
      .unwrap()
      .then(() => {
        navigate('/');
      })
      .catch((e: any) => {
        const errs = e?.response?.data?.errors;
        if (!errs) return;
        addServerErrors(errs, setError);
      });
  };

  // useDocumentTitle('registration');

  return (
    <>
      <div className="section__auth-form">
        <h1 className="form__title">{translateData.UA.registerText}</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="form__fieldset">
            <label
              className="form__label form__label--login form__label--custom"
              htmlFor="firstName"
            >
              {translateData.UA.labelFirstName}
            </label>
            <input
              className="form__input form__input--login"
              id="firstname"
              style={{ borderColor: errors.firstName?.message && '#FF0000' }}
              {...register('firstName', {
                required: translateData.UA.firstNameErrorMsg,
              })}
              onChange={(e: any) => {
                setValue('firstName', e.target.value.trimStart());
              }}
            />
            {errors.firstName && (
              <span className="form__error">{errors.firstName.message}</span>
            )}
          </fieldset>

          <fieldset className="form__fieldset">
            <label
              className="form__label form__label--login form__label--custom"
              htmlFor="lastName"
            >
              {translateData.UA.labelLastName}
            </label>
            <input
              className="form__input form__input--login"
              id="lastName"
              style={{ borderColor: errors.lastName?.message && '#FF0000' }}
              {...register('lastName', {
                required: translateData.UA.lastNameErrorMsg,
              })}
              onChange={(e: any) => {
                setValue('lastName', e.target.value.trimStart());
              }}
            />
            {errors.lastName && (
              <span className="form__error">{errors.lastName.message}</span>
            )}
          </fieldset>

          <fieldset className="form__fieldset">
            <label
              className="form__label form__label--login form__label--custom"
              htmlFor="telephone"
            >
              {translateData.UA.labelPhone}
            </label>
            <InputMask
              id="telephone"
              className="input__field form__input--login"
              placeholder="+38 (XXX) XXX XX XX"
              {...register('telephone', {
                required: translateData.UA.phoneErrorMsg,
                maxLength: {
                  value: 13,
                  message: translateData.UA.phoneErrorMsg,
                },
                minLength: {
                  value: 13,
                  message: 'Необхідно ввести повний номер телефону',
                },
              })}
              mask="+380 (99) 999-9999"
              style={{ borderColor: errors.telephone?.message && 'red' }}
              inputRef={register('telephone').ref}
              onChange={(e: any) => {
                setValue('telephone', e.target.value);
              }}
              onBlur={() => {
                setValue(
                  'telephone',
                  getValues('telephone').replace(/[^+\d]/g, '')
                );
              }}
            />
            {errors.telephone && (
              <span className="form__error">{errors.telephone.message}</span>
            )}
          </fieldset>

          <fieldset className="form__fieldset">
            <label
              className="form__label form__label--login form__label--custom"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="form__input form__input--login"
              id="email"
              placeholder={translateData.UA.placeholderEmail}
              style={{ borderColor: errors.email?.message && '#FF0000' }}
              {...register('email', {
                required: translateData.UA.emailErrorMsg,
                pattern: emailValidationString,
                minLength: {
                  value: 4,
                  message: translateData.UA.emailErrorMsg,
                },
              })}
            />
            {errors.email?.type === 'pattern' && (
              <span className="form__error">Невірний формат e-mail</span>
            )}
            {errors.email && (
              <span className="form__error">{errors.email.message}</span>
            )}
          </fieldset>

          <fieldset className="form__fieldset">
            <label
              className="form__label form__label--login form__label--custom"
              htmlFor="password"
            >
              {translateData.UA.labelPassword}
            </label>
            <input
              className="form__input form__input--login"
              id="password"
              type="password"
              placeholder="Мінімум 8 символів"
              style={{ borderColor: errors.password?.message && '#FF0000' }}
              {...register('password', {
                required: translateData.UA.passwordErrorMsg,
              })}
            />
            {errors.password && (
              <span className="form__error">{errors.password.message}</span>
            )}
          </fieldset>

          <Button view="default" type="submit">
            Зареєструватися
          </Button>
        </form>
      </div>

      <div className="section__extra-info">
        {translateData.UA.hasAccountText}
        <NavLink to="/" style={{ marginLeft: '8px' }}>
          {translateData.UA.signInBtnText}
        </NavLink>
      </div>
    </>
  );
}

export default ClientRegister;

import React from 'react';
import cx from 'classnames';

import { ComponentSizes } from '../../../common/types';

import './Text.scss';

type As = 'p' | 'span' | 'code';

interface ITextProps {
  children: React.ReactNode;
  className?: string;
  as?: As;
  size?: ComponentSizes;
  inline?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export default function Text({
  className,
  children,
  style = {},
  inline = false,
  as = 'p',
  size = 'xxl',
  onClick = () => {},
}: ITextProps): JSX.Element {
  const TagName = as;
  const classes = cx(
    'text',
    {
      [`text--${size}`]: size,
      [`text--${inline}`]: inline,
    },
    className
  );

  return (
    <TagName onClick={onClick} style={style} className={classes}>
      {children}
    </TagName>
  );
}

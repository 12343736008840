import React, { HTMLAttributes } from 'react';
import cx from 'classnames';

import { ComponentSizes } from '../../../common/types';

import './Heading.scss';

interface IHeadingProps {
  children: React.ReactNode;
  className?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size: ComponentSizes;
  inline?: boolean;
  style?: React.CSSProperties;
  onClick?: (e?: any) => void;
  onMouseEnter?: (e?: any) => void;
}

export default function Heading({
  className,
  children,
  style = {},
  inline = false,
  as = 'h3',
  size = 'xxl',
  onClick = () => {},
  onMouseEnter = () => {},
}: IHeadingProps): JSX.Element {
  const TagName = as;
  const classes = cx(
    'heading',
    {
      [`heading--${size}`]: size,
      [`heading--${inline}`]: inline,
    },
    className
  );

  return (
    <TagName
      onClick={onClick}
      className={classes}
      style={style}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </TagName>
  );
}

import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import logo from '../../logo.svg';

const AuthLayout = (): JSX.Element => (
  <div className="section">
    <div className="section__wrap-auth">
      <NavLink className="section__logo" to="/">
        <img src={logo} alt="Home" height={30} />
      </NavLink>
      <Outlet />
    </div>
  </div>
);

export default AuthLayout;

import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import cx from 'classnames';

import {
  changePriorityCO,
  coActions,
  getTaskData,
  addAssessment,
  deleteAssessment,
  updateAssessment,
  finishAssessment,
  continueAssessment,
  taskGroupAction,
  checkAssessment,
  taskGroupUserAction,
  taskPauseAction,
  managerAction,
} from '../../store/tasks/thunk';

import Heading from '../UI/Heading/Heading';
import Button from '../Button/Button';
import Range from '../UI/Range/Range';

import { ReactComponent as IconMan } from '../../media/icons/man.svg';
import { ReactComponent as IconLogo } from '../../logo.svg';
import { ReactComponent as CheckLogo } from '../../media/icons/check.svg';

import { AppDispatch } from '../../store';
import { commercialOfferTextStatuses } from '../../helpers/constant';

import Modal from '../Modal/Modal';
import { PROJECT_ROLES } from '../../common/constant';
import { getProjectData } from '../../store/projects/thunk';
import Input from '../UI/Input/Input';
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import { checkPermission } from '../../hooks/checkPermission';
import { Toggler } from '../../layout/Header/Header';
import { formatDate, inputNumberHandler } from '../../helpers/common';

import './CommercialOffer.scss';

interface ICommercialOfferProps {
  group: any;
  taskId: string;
  slug: string;
  priority: number;
  rangeClickBlocked: boolean;
  setRangeClickBlocked: (value: boolean) => void;
}

interface ICommercialOfferCheckProps {
  group: any;
  slug: string;
  taskId: string;
}

const COfferActions = {
  rejectStart: 'Відхилити запит на старт робіт по КП',
  reoffer: 'Відправити запит на оновлення КП',
  rejectTest: 'Відхилити запит перевірки робіт по КП на тесті',
  successTest: 'Прйняти роботи по КП на тесті',
  rejectProd: 'Відхилити запит перевірки робіт по КП на проді',
  successProd: 'Прйняти роботи по КП на проді',
};

const parentCOTypesOptions = [
  {
    label: 'Через Х днів після завершення батькывського КП',
    value: 0,
  },
  {
    label: 'У вказану дату',
    value: 1,
  },
  {
    label: 'Через Х днів після затвердження КП',
    value: 2,
  },
];

const CommercialOffer: React.FC<ICommercialOfferProps> = ({
  group,
  taskId,
  slug,
  priority,
  rangeClickBlocked,
  setRangeClickBlocked,
}) => {
  const {
    calculations,
    name,
    total,
    totalOld,
    dateStart,
    deadline,
    deadlineOld,
  } = group;

  const dispatch: AppDispatch = useDispatch();
  const { taskInfo } = useSelector((state: any) => state.taskData);
  const [isAddStaffActive, setIsAddStaffActive] = useState(false);
  const [selectedDeveloperId, setSelectedDeveloperId] = useState<any>(null);
  const [selectedDataTermType, setSelectedDataTermType] = useState<any>(null);

  const [autoSendVisible, setAutoSendVisible] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isSendCORejected, setSendCORejected] = useState(false);
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: any) => state.userData);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<any>();
  const newCOPriority = useRef<number>(
    group?.priority ? group.priority : priority
  );

  useEffect(() => {
    newCOPriority.current = group?.priority ? group.priority : priority;
  }, [priority]);

  useEffect(() => {
    reset({
      data: 'comment',
    });
  }, [isRejected]);

  useEffect(() => {
    if (group?.auto) {
      setAutoSendVisible(true);
    }
  }, [group?.auto]);

  const handleCOfferDelete = (): void => {
    if (group.textStatus === commercialOfferTextStatuses.checkTest) {
      dispatch(
        coActions({
          slug,
          value: getValues('comment'),
          action: 'rejectTest',
          groupId: group.id,
          param: 'text',
        })
      ).then(() => {
        dispatch(getTaskData(taskInfo.slug)).then(() => {
          dispatch(getProjectData(taskInfo?.project.slug));
          reset({
            data: 'comment',
          });
        });
        setIsRejected(false);
      });
    } else if (group.textStatus === commercialOfferTextStatuses.checkProd) {
      dispatch(
        coActions({
          slug,
          value: getValues('comment'),
          action: 'rejectProd',
          groupId: group.id,
          param: 'text',
        })
      ).then(() => {
        dispatch(getTaskData(taskInfo.slug)).then(() => {
          reset({
            data: 'comment',
          });
        });
      });
    } else if (group.reoffer === 1) {
      dispatch(
        coActions({
          slug,
          value: getValues('comment'),
          action: 'delete',
          groupId: group.id,
          param: 'text',
        })
      ).then(() => {
        reset({
          data: 'comment',
        });
      });
    } else {
      dispatch(
        coActions({
          slug,
          value: getValues('comment'),
          action: 'rejectStart',
          groupId: group.id,
          param: 'text',
        })
      ).then(() => {
        reset({
          data: 'comment',
        });
      });
    }
  };

  const handleSendToWork = (): void => {
    dispatch(
      coActions({
        slug,
        action: 'confirmStart',
        groupId: group.id,
      })
    );
  };

  const handleReoffer = (): void => {
    dispatch(
      coActions({
        slug,
        action: 'reoffer',
        groupId: group.id,
      })
    ).then(() => {
      dispatch(getTaskData(taskInfo.slug));
      navigate(`/task/${taskInfo.slug}/`);
    });
  };

  const handleSendCOToPM = (): void => {
    dispatch(
      taskGroupAction({
        slug,
        action: 'rejectComplete',
        groupId: group.id,
        param: 'text',
        value: getValues('comment'),
      })
    );
  };

  const handleChangeCOPriority = useCallback(
    async (value: number): Promise<any> => {
      if (rangeClickBlocked) return;
      setRangeClickBlocked(true);
      newCOPriority.current = value;

      try {
        await dispatch(
          changePriorityCO({
            slug,
            groupId: group.id,
            priority: value,
          })
        );
      } catch (error) {
        newCOPriority.current = group.priority;
      } finally {
        setRangeClickBlocked(false);
      }
    },
    [rangeClickBlocked, group.id]
  );

  const isProjectManager = userInfo.roleId === PROJECT_ROLES.projectManager;
  const isSalesManager = userInfo.roleId === PROJECT_ROLES.salesManager;
  const isSalesOrProjectManager = isProjectManager || isSalesManager;
  const isClient = checkPermission('CLIENT');
  const isStaff = checkPermission('STAFF');

  const isNew = group.textStatus === 'new';
  const isTaskStatusDiscuss = taskInfo.textStatus === 'discuss';
  const isStatusNewAndProjectManager = isNew && isProjectManager;

  const parentCOOptions = useMemo(
    () =>
      taskInfo?.groups
        .filter((item: any) => item.id !== group.id)
        .map((item: any) => ({
          label: item.name,
          value: item.id,
        })) || [],
    [taskInfo?.groups?.length, group.id]
  );

  const conditionForShowingCODetails =
    (group.textStatus !== commercialOfferTextStatuses.reoffer &&
      group.textStatus !== commercialOfferTextStatuses.checkTest &&
      group.textStatus !== commercialOfferTextStatuses.checkProd &&
      isClient) ||
    ((isProjectManager || isSalesManager) && !group.reoffer);

  const conditionToShowingCORange =
    !isRejected &&
    userInfo.roleId === PROJECT_ROLES.productOwner &&
    !!group.total &&
    group.textStatus !== commercialOfferTextStatuses.checkProd &&
    group.textStatus !== commercialOfferTextStatuses.checkTest &&
    group.textStatus !== commercialOfferTextStatuses.reoffer &&
    !group.reoffer;

  const conditionToShowClientButtons =
    userInfo.roleId === PROJECT_ROLES.productOwner &&
    group.textStatus !== commercialOfferTextStatuses.checkTest &&
    group.reoffer !== 1 &&
    group.textStatus !== commercialOfferTextStatuses.checkProd;

  const staffOptions = useMemo(
    () =>
      taskInfo?.developers?.map((item: any) => ({
        label: item.speciality,
        options: item.users
          .filter(
            (user: any) =>
              !group?.developers
                .reduce((acc: any[], dev: any) => [...acc, ...dev.users], [])
                .some((dev: any) => dev.id === user.id)
          )
          .map((user: any) => ({
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          })),
      })) || [],
    [taskInfo?.developers?.length, group?.developers?.length]
  );

  return (
    <>
      {group.status >= 6 &&
        group.textStaus !== commercialOfferTextStatuses.reoffer &&
        !group?.reoffer &&
        !isSalesOrProjectManager && (
          <CommercialOfferCheck
            priority={priority}
            group={group}
            slug={slug}
            taskId={taskId}
          />
        )}
      {((group.textStatus !== commercialOfferTextStatuses.confirmStart &&
        group.textStatus !== commercialOfferTextStatuses.successTest &&
        group.textStatus !== commercialOfferTextStatuses.archive) ||
        isSalesOrProjectManager) && (
        <div className="commercial-offer">
          {name || isNew ? (
            <div
              className={cx('commercial-offer__header', {
                'commercial-offer__header--client': isClient,
                'commercial-offer__item': isStatusNewAndProjectManager,
              })}
            >
              {isStatusNewAndProjectManager ? (
                <CommercialOfferField
                  type="text"
                  label="Назва КП"
                  defaultValue={name}
                  onSubmit={(value: string) => {
                    dispatch(
                      taskGroupAction({
                        slug,
                        param: 'name',
                        action: 'update',
                        groupId: group.id,
                        value,
                      })
                    );
                  }}
                />
              ) : (
                name
              )}
              {isClient && <IconMan className="commercial-offer__man" />}
            </div>
          ) : null}
          <div className="commercial-offer__body">
            {group.text || isNew ? (
              <div className="commercial-offer__item">
                {isStatusNewAndProjectManager ? (
                  <CommercialOfferField
                    type="textarea"
                    label="Технічне завдання"
                    defaultValue={group.text}
                    onSubmit={(value: string) => {
                      dispatch(
                        taskGroupAction({
                          slug,
                          param: 'text',
                          action: 'update',
                          groupId: group.id,
                          value,
                        })
                      );
                    }}
                  />
                ) : (
                  group.text
                )}
              </div>
            ) : null}

            {calculations?.map((calculation: any) => (
              <div
                key={Date.now() * Math.random()}
                className="commercial-offer__item"
              >
                {calculation.text}
                {isStatusNewAndProjectManager ? (
                  <CommercialOfferField
                    type="textarea"
                    label="Технічне завдання"
                    defaultValue={calculation.text}
                  />
                ) : (
                  group.text
                )}
              </div>
            ))}
            {(!!group.term && !isSalesOrProjectManager) || isNew ? (
              <div className="commercial-offer__item">
                <CommercialOfferField
                  type="number"
                  canEdit={group.term && isNew}
                  label="Термін виконання, днів:"
                  defaultValue={group.term}
                  onSubmit={(value: string) => {
                    dispatch(
                      taskGroupAction({
                        slug,
                        param: 'term',
                        action: 'update',
                        groupId: group.id,
                        value,
                      })
                    );
                  }}
                />
              </div>
            ) : null}
            {!!parentCOOptions.length &&
              isProjectManager &&
              group.status === 1 && (
                <div className="commercial-offer__item">
                  <div className="commercial-offer__label">Батьківська КП</div>
                  <CustomSelect
                    defaultValue={{
                      label: taskInfo?.groups.find(
                        (item: any) => item.id === group.parentId
                      )?.name,
                      value: group.parentId,
                    }}
                    options={
                      taskInfo?.groups.find(
                        (item: any) => item.id === group.parentId
                      )
                        ? [
                            ...parentCOOptions,
                            { label: 'Без батьківської КП', value: null },
                          ]
                        : parentCOOptions
                    }
                    placeholder="Батьківська КП"
                    className="commercial-offer__select"
                    onChange={({ value }: any): any => {
                      dispatch(
                        taskGroupAction({
                          slug,
                          action: 'update',
                          groupId: group.id,
                          param: 'parentId',
                          value,
                        })
                      );
                    }}
                  />
                </div>
              )}
            {group?.parentId && group.status === 1 && (
              <div className="commercial-offer__item">
                <CustomSelect
                  options={parentCOTypesOptions}
                  placeholder="Тип старту КП"
                  className="commercial-offer__select"
                  onChange={({ value }: any): any => {
                    setSelectedDataTermType(value);
                  }}
                />
              </div>
            )}
            {selectedDataTermType === 0 && (
              <div className="commercial-offer__item">
                <CommercialOfferField
                  type="number"
                  canEdit={selectedDataTermType === 0}
                  label="Кількість днів до старту КП"
                  defaultValue={group.termStart}
                  onSubmit={(value: string) => {
                    dispatch(
                      taskGroupAction({
                        slug,
                        param: 'termStart',
                        action: 'update',
                        groupId: group.id,
                        value,
                      })
                    );
                  }}
                />
              </div>
            )}
            {selectedDataTermType === 1 && (
              <div className="commercial-offer__item">
                <CommercialOfferField
                  type="date"
                  canEdit={selectedDataTermType === 1}
                  label={`Дата старту КП (${dayjs
                    .unix(group.dateStart)
                    .format('DD.MM.YYYY')})`}
                  defaultValue={group.termStart}
                  onSubmit={(value: string) => {
                    dispatch(
                      taskGroupAction({
                        slug,
                        param: 'termStart',
                        action: 'update',
                        groupId: group.id,
                        value,
                      })
                    );
                  }}
                />
              </div>
            )}
            {selectedDataTermType === 2 && (
              <div className="commercial-offer__item">
                <CommercialOfferField
                  canEdit={selectedDataTermType === 2}
                  type="number"
                  label="Кількість днів до старту КП"
                  defaultValue={group.termStart}
                  onSubmit={(value: string) => {
                    dispatch(
                      taskGroupAction({
                        slug,
                        param: 'termStart',
                        action: 'update',
                        groupId: group.id,
                        value,
                      })
                    );
                  }}
                />
              </div>
            )}
            {isSalesManager && (
              <>
                <div className="commercial-offer__item">
                  <CommercialOfferField
                    canEdit={group.textStatus === 'checkComplete'}
                    type="number"
                    min={1}
                    label="Коефіцієнт бізнес цінності"
                    defaultValue={group.businessK}
                    onSubmit={(value: string) => {
                      dispatch(
                        taskGroupAction({
                          slug,
                          param: 'businessK',
                          action: 'update',
                          groupId: group.id,
                          value,
                        })
                      );
                    }}
                  />
                </div>
                <div className="commercial-offer__item commercial-offer__item--df">
                  Пріорітет: НЕГАЙНО!
                  <PriorityRating priority={group.priority} />
                </div>
              </>
            )}

            {!!group?.developers?.length && isSalesOrProjectManager && (
              <div className="commercial-offer__item">
                <CommercialOfferDevelopers
                  group={group}
                  isSalesManager={isSalesManager}
                  isProjectManager={isProjectManager}
                  onReject={(id: any) => {
                    dispatch(
                      managerAction({
                        slug,
                        action: 'rejectConfirm',
                        groupId: group.id,
                        calcId: id,
                      })
                    );
                  }}
                  onChatChange={(id: any, value: any) => {
                    dispatch(
                      taskGroupUserAction({
                        slug,
                        data: {
                          action: 'update',
                          param: 'chat',
                          groupId: group.id,
                          userId: id,
                          value,
                        },
                      })
                    );
                  }}
                  onChange={(id: any, value: any) => {
                    dispatch(
                      taskGroupUserAction({
                        slug,
                        data: {
                          action: 'update',
                          param: 'wait',
                          groupId: group.id,
                          userId: id,
                          value,
                        },
                      })
                    );
                  }}
                  onClick={(id: any) => {
                    dispatch(
                      taskGroupUserAction({
                        slug,
                        data: {
                          action: 'delete',
                          groupId: group.id,
                          userId: id,
                        },
                      })
                    );
                  }}
                />
              </div>
            )}
            {isProjectManager && group.textStatus === 'confirmStart' && (
              <div className="commercial-offer__item">
                <label
                  htmlFor="autoCheck"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: autoSendVisible ? '10px' : 'initial',
                  }}
                >
                  <input
                    type="checkbox"
                    name=""
                    defaultChecked={group?.auto}
                    id="autoCheck"
                    onChange={(e) => {
                      dispatch(
                        taskGroupAction({
                          slug,
                          groupId: group.id,
                          action: 'update',
                          param: 'auto',
                          value: e.target.checked ? 1 : 0,
                        })
                      )
                        .unwrap()
                        .then(() => {
                          setAutoSendVisible(
                            (prev: boolean) => !prev && e.target.checked
                          );
                        });
                    }}
                    style={{
                      marginRight: '5px',
                    }}
                  />
                  Автоматично відправити на перевірку
                </label>
                {autoSendVisible && (
                  <CommercialOfferField
                    // className="commercial-offer__input"
                    type="date"
                    defaultValue={dayjs
                      .unix(group.dateTest)
                      .format('YYYY-MM-DD')}
                    onSubmit={(value: string) => {
                      dispatch(
                        taskGroupAction({
                          slug,
                          param: 'dateTest',
                          action: 'update',
                          groupId: group.id,
                          value,
                        })
                      );
                    }}
                  />
                )}
              </div>
            )}

            {conditionForShowingCODetails && (
              <>
                {!isStaff && !isClient && (
                  <div className="commercial-offer__item commercial-offer__item--short">
                    <div className="commercial-offer__costs">
                      <div className="commercial-offer__costs-left">
                        Загалом по КП:
                      </div>
                      <div className="commercial-offer__costs-right">
                        <div className="commercial-offer__cost-sale commercial-offer__cost-sale--accent">
                          {group.totalHour
                            ? `${group.totalHour} год `
                            : 'За домовленістю '}
                          {group?.totalOld && (
                            <Strike
                              status={1}
                              tag="span"
                              className="commercial-offer__cost-old"
                            >
                              {group.totalOld}
                            </Strike>
                          )}
                          <span className="commercial-offer__cost-sale">
                            {group?.total &&
                              group.total !== 0 &&
                              ` ${group.total} грн`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!!group.term && (
                  <div className="commercial-offer__item commercial-offer__item--short">
                    <div className="commercial-offer__costs">
                      <div className="commercial-offer__costs-left">
                        Термін виконання, днів:
                      </div>
                      <div className="commercial-offer__costs-right">
                        {group.termOld && (
                          <Strike
                            status={1}
                            tag="span"
                            className="commercial-offer__cost-old"
                          >
                            {group.termOld}
                          </Strike>
                        )}
                        <div className="commercial-offer__cost-sale">
                          {group.term}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {dateStart && (
                  <div className="commercial-offer__item commercial-offer__item--short">
                    <div className="commercial-offer__costs">
                      {isStatusNewAndProjectManager && (
                        <div className="commercial-offer__costs-left">
                          +
                          <CommercialOfferField
                            className="commercial-offer__input commercial-offer__input--short"
                            type="number"
                            defaultValue={group.termBefore}
                            onSubmit={(value: string) => {
                              dispatch(
                                taskGroupAction({
                                  slug,
                                  param: 'termBefore',
                                  action: 'update',
                                  groupId: group.id,
                                  value,
                                })
                              );
                            }}
                          />
                        </div>
                      )}
                      <div className="commercial-offer__costs-left">
                        Старт робіт:
                      </div>
                      <div className="commercial-offer__costs-right">
                        <div className="commercial-offer__cost-sale">
                          {dayjs.unix(dateStart).format('DD.MM.YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {dateStart && (
                  <div className="commercial-offer__item commercial-offer__item--short">
                    <div className="commercial-offer__costs">
                      <div className="commercial-offer__costs-left">
                        Дедлайн:
                      </div>
                      <div className="commercial-offer__costs-right">
                        {(deadlineOld &&
                          userInfo.roleId === PROJECT_ROLES.productOwner) ||
                          (isProjectManager && deadlineOld && (
                            <div className="commercial-offer__cost-old">
                              {dayjs.unix(deadlineOld).format('DD.MM.YYYY')}
                            </div>
                          ))}
                        <div className="commercial-offer__cost-sale">
                          {dayjs.unix(deadline).format('DD.MM.YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!isSalesManager &&
                  !!total &&
                  userInfo.roleId === PROJECT_ROLES.productOwner && (
                    <div className="commercial-offer__item commercial-offer__item--short">
                      <div className="commercial-offer__costs">
                        <div className="commercial-offer__costs-left">
                          Вартість:
                        </div>
                        <div className="commercial-offer__costs-right">
                          {totalOld && (
                            <div className="commercial-offer__cost-old">
                              {totalOld}
                            </div>
                          )}
                          <div className="commercial-offer__cost-sale">
                            {total !== 0
                              ? `${group.total} грн`
                              : 'За домовленістю'}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </>
            )}
            {conditionToShowingCORange && (
              <>
                <Heading className="commercial-offer__title" size="sm">
                  Швидкість та дедлайн
                </Heading>
                <Range
                  key={`${newCOPriority.current}${userInfo.roleId}`}
                  disabled={rangeClickBlocked}
                  initialValue={newCOPriority.current}
                  className="commercial-offer__range"
                  contentClassName="commercial-offer__range-content"
                  textClassName="commercial-offer__range-text"
                  activeTextClassName="commercial-offer__range-text--active"
                  inputClassName="commercial-offer__range-input"
                  dots={false}
                  onMouseUp={handleChangeCOPriority}
                />
              </>
            )}

            {isRejected && (
              <>
                <div
                  className="commercial-offer__item commercial-offer__item--textarea-wrapper"
                  style={{
                    borderColor: errors.comment && '#FF0000',
                  }}
                >
                  <textarea
                    className="commercial-offer__input"
                    placeholder="Введіть повідомлення"
                    autoFocus
                    {...register('comment', { required: true })}
                  />
                </div>
                <div className="commercial-offer__item commercial-offer__item--transparent">
                  <Button
                    onClick={() => setIsRejected(false)}
                    className="commercial-offer__button"
                  >
                    Закрити
                  </Button>

                  <Button
                    onClick={handleSubmit(handleCOfferDelete)}
                    className="commercial-offer__button commercial-offer__button--danger"
                  >
                    Відправити
                  </Button>
                </div>
              </>
            )}
          </div>
          {isSendCORejected && (
            <>
              <div
                className="commercial-offer__item commercial-offer__item--textarea-wrapper"
                style={{
                  borderColor: errors.comment && '#FF0000',
                }}
              >
                <textarea
                  className="commercial-offer__input"
                  placeholder="Введіть повідомлення"
                  autoFocus
                  {...register('comment', { required: true })}
                />
              </div>
              <div className="commercial-offer__item commercial-offer__item--transparent">
                <Button
                  onClick={() => setSendCORejected(false)}
                  className="commercial-offer__button"
                >
                  Закрити
                </Button>

                <Button
                  onClick={handleSubmit(handleSendCOToPM)}
                  className="commercial-offer__button commercial-offer__button--danger"
                >
                  Відправити
                </Button>
              </div>
            </>
          )}
          {!isStaff && (
            <div className="commercial-offer__footer">
              <div className="commercial-offer__buttons">
                {isSalesManager && (
                  <>
                    {(group.textStatus === 'checkStart' ||
                      group.textStatus === 'reoffer') && (
                      <Button
                        view="third"
                        onClick={() => {
                          dispatch(
                            taskGroupAction({
                              slug,
                              groupId: group.id,
                              action: 'rejectStart',
                            })
                          );
                        }}
                      >
                        Відмінити КП у клієнта
                      </Button>
                    )}
                    {(group.textStatus === 'checkStart' ||
                      group.textStatus === 'reoffer') &&
                      group.overdue === 1 && (
                        <Button
                          view="success"
                          onClick={() => {
                            dispatch(
                              taskGroupAction({
                                slug,
                                groupId: group.id,
                                action: 'confirmReoffer',
                              })
                            );
                          }}
                        >
                          Оновити КП
                        </Button>
                      )}
                    {group.textStatus === 'confirmStart' && (
                      <Button
                        view="warning"
                        variant="fill"
                        onClick={() => {
                          dispatch(
                            taskGroupAction({
                              slug,
                              groupId: group.id,
                              action: 'setRefund',
                            })
                          );
                        }}
                      >
                        Клієнт відмовився від старту робіт
                      </Button>
                    )}
                    {/* <Button
                        view="secondary"
                        onClick={() => {
                          dispatch(
                            taskGroupAction({
                              slug,
                              groupId: group.id,
                              action: 'rejectStart',
                            })
                          );
                        }}
                      >
                        Відмінити кп у клієнта
                      </Button> */}
                    {group.textStatus === 'checkComplete' && (
                      <>
                        <Button
                          view="secondary"
                          onClick={() => setSendCORejected(true)}
                        >
                          Відправити на доопрацєвання PM-у
                        </Button>
                        <Button
                          view="success"
                          onClick={() => {
                            dispatch(
                              taskGroupAction({
                                slug,
                                groupId: group.id,
                                action: 'checkStart',
                              })
                            );
                          }}
                        >
                          Відправити КП клієнту
                        </Button>
                      </>
                    )}
                  </>
                )}
                {group.textStatus === commercialOfferTextStatuses.checkStart &&
                  group.reoffer !== 1 &&
                  userInfo.roleId === PROJECT_ROLES.productOwner &&
                  isClient && (
                    <Button
                      onClick={() => {
                        setIsRejected(true);
                      }}
                      className="commercial-offer__button "
                    >
                      Відхилити
                    </Button>
                  )}
                {conditionToShowClientButtons &&
                  (group.textStatus === commercialOfferTextStatuses.reoffer ? (
                    <div
                      style={{ cursor: 'default' }}
                      className="commercial-offer__item commercial-offer__item--short"
                    >
                      Запрошено оновлення КП
                    </div>
                  ) : group.textStatus ===
                    commercialOfferTextStatuses.reoffer ? (
                    <Button
                      onClick={handleReoffer}
                      className="commercial-offer__button commercial-offer__button--success"
                    >
                      Запросити оновлення КП
                    </Button>
                  ) : (
                    <Button
                      onClick={handleSendToWork}
                      className="commercial-offer__button commercial-offer__button--success"
                    >
                      Відправити в роботу
                    </Button>
                  ))}
                {group.reoffer === 1 && isClient && (
                  <>
                    <Button
                      onClick={() => setIsRejected(true)}
                      className="commercial-offer__button"
                    >
                      Відправити в архів
                    </Button>
                    <Button
                      onClick={handleReoffer}
                      className="commercial-offer__button commercial-offer__button--success"
                    >
                      Запросити оновлення КП
                    </Button>
                  </>
                )}
                {(isClient && !isRejected && !group.parentId) ||
                (group.parentId && group.canConfirm)
                  ? group.textStatus !== commercialOfferTextStatuses.reoffer &&
                    group.textStatus !==
                      commercialOfferTextStatuses.checkStart &&
                    !group.reoffer &&
                    (group.textStatus ===
                    commercialOfferTextStatuses.checkTest ? (
                      <Button
                        onClick={() => {
                          setIsRejected(true);
                        }}
                        className="commercial-offer__button "
                      >
                        Відхилити
                      </Button>
                    ) : group.textStatus ===
                        commercialOfferTextStatuses.checkProd && isClient ? (
                      <Button
                        onClick={() => {
                          setIsRejected(true);
                        }}
                        className="commercial-offer__button "
                      >
                        Знайдено помилки
                      </Button>
                    ) : isClient ? (
                      <Button
                        onClick={() => {
                          setIsRejected(true);
                        }}
                        className="commercial-offer__button "
                      >
                        Відхилити з коментарем
                      </Button>
                    ) : null)
                  : null}
                {((isProjectManager &&
                  (taskInfo.textStatus === 'discuss' ||
                    taskInfo.textStatus === 'new' ||
                    taskInfo.textStatus === 'request')) ||
                  group.textStatus === 'new') &&
                  // group?.term &&
                  group.status !== 2 &&
                  isNew && (
                    <Button
                      view="default"
                      onClick={() => {
                        setIsAddStaffActive((prev) => !prev);
                        if (selectedDeveloperId) {
                          setSelectedDeveloperId(null);
                        }
                      }}
                    >
                      Додати співробітника
                    </Button>
                  )}
                {isClient &&
                  (group.textStatus === commercialOfferTextStatuses.checkTest &&
                  (!group.parentId || (group.parentId && group.canConfirm)) ? (
                    <Button
                      onClick={() => {
                        dispatch(
                          coActions({
                            slug,
                            groupId: group.id,
                            action: 'successTest',
                          })
                        );
                      }}
                      className="commercial-offer__button commercial-offer__button--success"
                    >
                      Прийняти роботи на тесті
                    </Button>
                  ) : userInfo.roleId === PROJECT_ROLES.productOwner &&
                    group.textStatus ===
                      commercialOfferTextStatuses.checkProd &&
                    (!group.parentId ||
                      (group.parentId && group.canConfirm)) ? (
                    <Button
                      onClick={() => {
                        dispatch(
                          coActions({
                            slug,
                            groupId: group.id,
                            action: 'successProd',
                          })
                        );
                      }}
                      className="commercial-offer__button commercial-offer__button--success"
                    >
                      Все добре, дякую.Роботу прийнято
                    </Button>
                  ) : null)}
                {group.status === 1 &&
                  group?.term &&
                  isProjectManager &&
                  group.textStatus === 'new' && (
                    <Button
                      view="success"
                      className="commercial-offer__button--success"
                      onClick={() => {
                        dispatch(
                          taskGroupAction({
                            slug,
                            action: 'checkComplete',
                            groupId: group.id,
                          })
                        );
                      }}
                    >
                      Завершити оцінку
                    </Button>
                  )}
                {isProjectManager &&
                  (group.textStatus === 'checkTest' ||
                    group.textStatus === 'checkProd' ||
                    group.textStatus === 'successTest' ||
                    group.textStatus === 'confirmStart') && (
                    <>
                      {group?.pauseAction === 'start' ? (
                        <Button
                          view="accent"
                          onClick={() => {
                            dispatch(
                              taskPauseAction({
                                slug,
                                action: 'start',
                                groupId: group.id,
                              })
                            );
                          }}
                        >
                          Поставити на паузу
                        </Button>
                      ) : (
                        <Button
                          view="accent"
                          onClick={() => {
                            dispatch(
                              taskPauseAction({
                                slug,
                                action: 'stop',
                                groupId: group.id,
                              })
                            );
                          }}
                        >
                          Зняти з паузи
                        </Button>
                      )}
                    </>
                  )}
                {group.textStatus === 'new' && isProjectManager && (
                  <Button
                    view="warning"
                    variant="fill"
                    onClick={() => {
                      dispatch(
                        taskGroupAction({
                          slug,
                          action: 'delete',
                          groupId: group.id,
                        })
                      );
                    }}
                  >
                    Видалити КП
                  </Button>
                )}
                {group.textStatus === 'confirmStart' && isProjectManager && (
                  <Button
                    view="default"
                    onClick={() => {
                      dispatch(
                        taskGroupAction({
                          slug,
                          action: 'checkTest',
                          groupId: group.id,
                        })
                      );
                    }}
                  >
                    Відправити на перевірку на тесті
                  </Button>
                )}
                {(group.textStatus === 'successTest' ||
                  group.textStatus === 'confirmStart') &&
                  isProjectManager && (
                    <Button
                      view="success"
                      onClick={() => {
                        dispatch(
                          taskGroupAction({
                            slug,
                            action: 'checkProd',
                            groupId: group.id,
                          })
                        );
                      }}
                    >
                      Відправити на перевірку на проді
                    </Button>
                  )}
                {group.status === 2 && isProjectManager && (
                  <Button
                    view="third"
                    className="task-assessment__btn task-assessment__btn--finish"
                    onClick={() => {
                      dispatch(
                        taskGroupAction({
                          slug,
                          action: 'rejectComplete',
                          groupId: group.id,
                          param: 'text',
                        })
                      );
                    }}
                  >
                    Продовжити оцінювання
                  </Button>
                )}
              </div>

              {isAddStaffActive && isStatusNewAndProjectManager && (
                <div className="commercial-offer__item commercial-offer__item--top">
                  <div className="commercial-offer__label">
                    Додати співробітника
                  </div>
                  <CustomSelect
                    options={staffOptions}
                    placeholder="Оберіть спеціаліста"
                    className="commercial-offer__select commercial-offer__select--developer"
                    onChange={({ value }: any): any => {
                      setSelectedDeveloperId(value.toString());
                    }}
                  />
                  {selectedDeveloperId && (
                    <Button
                      view="default"
                      className="commercial-offer-developer__button"
                      onClick={() => {
                        dispatch(
                          taskGroupUserAction({
                            slug,
                            data: {
                              action: 'add',
                              groupId: group.id,
                              userId: selectedDeveloperId,
                            },
                          })
                        )
                          .unwrap()
                          .then(() => {
                            setIsAddStaffActive(false);
                          });
                      }}
                    >
                      Додати до КП
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CommercialOffer;

interface ICommercialOfferCheckProps
  extends Omit<
    ICommercialOfferProps,
    'rangeClickBlocked' | 'setRangeClickBlocked'
  > {
  priority: number;
}

export const CommercialOfferCheck: React.FC<ICommercialOfferCheckProps> = ({
  group,
  slug,
  priority,
}) => {
  const [isWorkDetails, setIsWorkDetails] = useState<boolean>(false);
  const { taskInfo } = useSelector((state: any) => state.taskData);
  const { userInfo } = useSelector((state: any) => state.userData);

  const showWorkDetailsHandler = (): void => {
    setIsWorkDetails((prev) => !prev);
  };

  return (
    <div className="commercial-offer-check">
      <Modal
        size="md"
        title="Деталі виконаних робіт"
        isActive={isWorkDetails}
        handleClose={showWorkDetailsHandler}
        leftButton={false}
        modalClassName="commercial-offer-check__modal-inner"
        bodyClassName="commercial-offer-check__modal-body quill"
      >
        <div className="commercial-offer-check__item-ttl commercial-offer-check__item-ttl--md">
          {group.name}
        </div>
        <div className="commercial-offer-check__content ql-editor">
          <ol className="">
            {group.calculations?.map((calculation: any) => (
              <li
                key={Date.now() * Math.random()}
                className="commercial-offer-check__item-text"
              >
                {calculation.text}
              </li>
            ))}
          </ol>
        </div>
      </Modal>

      <div className="commercial-offer-check__header">
        <IconLogo className="commercial-offer-check__logo" />
        <div className="commercial-offer-check__title">{group.name}</div>
      </div>
      <div className="commercial-offer-check__body">
        <div className="commercial-offer-check__item">
          Статус:{' '}
          <Button className="commercial-offer-check__button commercial-offer__button commercial-offer__button--success">
            Підтверджено
          </Button>
        </div>
        <div className="commercial-offer-check__item">
          Технічне завдання:{' '}
          <span
            className="commercial-offer-check__show-details"
            onClick={showWorkDetailsHandler}
          >
            дивитись
          </span>
        </div>
        <div className="commercial-offer-check__item">
          Старт робіт:{' '}
          <span>{dayjs.unix(group.dateStart).format('DD.MM.YYYY')}</span>
        </div>
        <div className="commercial-offer-check__item">
          Дедлайн:{' '}
          <span>{dayjs.unix(group.deadline).format('DD.MM.YYYY')}</span>
        </div>
      </div>
      {userInfo.roleId === PROJECT_ROLES.productOwner && (
        <div className="commercial-offer-check__footer">
          <div className="commercial-offer-check__item">
            Вартість робіт:{' '}
            <div className="commercial-offer-check__total">
              {group.total !== 0 ? `${group.total} грн` : 'За домовленістю'}
            </div>
          </div>
        </div>
      )}
      {/* {userInfo.roleId === PROJECT_ROLES.productOwner && (
        <ButtonByGroupStatusCancel
          group={{ status: 5 }}
          onClick={() => console.log()}
        />
      )}
      {userInfo.roleId === PROJECT_ROLES.productOwner && (
        <ButtonByGroupStatus
          group={{ status: 5 }}
          onClick={() => console.log()}
        />
      )} */}
    </div>
  );
};

const ButtonByGroupStatus: React.FC<{
  group: any;
  onClick: () => void;
}> = ({ group, onClick }) => {
  const CurrentButton = useMemo(() => {
    switch (group.status) {
      case 1:
        return (
          <Button
            onClick={onClick}
            className="commercial-offer__button commercial-offer__button--success"
          >
            Відправити в роботу
          </Button>
        );
      case 2:
        return (
          <Button
            onClick={onClick}
            className="commercial-offer__button commercial-offer__button--success"
          >
            Прийняти роботи на тесті
          </Button>
        );
      case 3:
        return (
          <Button
            onClick={onClick}
            className="commercial-offer__button commercial-offer__button--success"
          >
            Все добре, дякую.Роботу прийнято
          </Button>
        );
      default:
        return null;
    }
  }, [group.status]);

  return CurrentButton;
};

const ButtonByGroupStatusCancel: React.FC<{
  group: any;
  onClick: () => void;
}> = ({ group, onClick }) => {
  const CurrentButton = useMemo(() => {
    switch (group.status) {
      case 1:
        return (
          <Button onClick={onClick} className="commercial-offer__button ">
            Відхилити
          </Button>
        );

      case 2:
        return (
          <Button onClick={onClick} className="commercial-offer__button ">
            Знайдено помилки
          </Button>
        );

      case 3:
        return (
          <Button onClick={onClick} className="commercial-offer__button ">
            Відхилити з коментарем
          </Button>
        );

      default:
        return null;
    }
  }, [group.status]);

  return CurrentButton;
};

export const TaskAssessment = ({ task, isMobile }: any): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const [isAddAssessment, setIsAddAssessment] = useState(
    Array.from({ length: task?.groups?.length }, () => false)
  );
  const [days, setDays] = useState('0');

  const name = task?.name;

  const isNotArchive = task.textStatus !== 'archive';
  const confirmClient = task.textStatus === 'confirmClient';
  const isNew = task.textStatus === 'new';
  // const isNew = true;

  const changeAddAssessment = (groupIndex: any): void => {
    setIsAddAssessment((prev) =>
      prev.map((item, index) => (index === groupIndex ? !item : false))
    );
  };

  const updateAssessmentHandler = (groupId: number): void => {
    dispatch(
      updateAssessment({
        slug: task.slug,
        groupId,
        value: days,
      })
    );
  };

  const deleteAssessmentHandler = (id: number, groupId: number): void => {
    dispatch(
      deleteAssessment({
        slug: task.slug,
        action: 'rejectDeveloper',
        groupId,
        calcId: id,
      })
    );
  };

  const finishAssessmentHandler = (groupId: number): void => {
    dispatch(
      finishAssessment({
        groupId,
        slug: task.slug,
      })
    );
  };

  const continueAssessmentHandler = (groupId: number): void => {
    dispatch(
      continueAssessment({
        groupId,
        slug: task.slug,
      })
    );
  };

  // useEffect(() => {
  //   const scrollBlock = document.querySelector('.task__body');
  //   if (!scrollBlock) return;
  //   const scrollHeight = scrollBlock?.scrollHeight;
  //   if (scrollHeight > scrollBlock.clientHeight && !isMobile) {
  //     if (isAddAssessment) {
  //       scrollBlock.scrollTo({
  //         top: scrollBlock.scrollHeight,
  //         behavior: 'smooth',
  //       });
  //     } else {
  //       scrollBlock.scrollTo({
  //         top: 0,
  //         behavior: 'smooth',
  //       });
  //     }
  //   }
  // }, [isAddAssessment]);

  return (
    <div className="task-assessment">
      {task?.groups?.map((group: any, groupIndex: any) => {
        const isAnyActualAssessment = group.calculations.some(
          (calculation: any) => !calculation.status
        );
        return (
          <div key={group.id} className="task-assessment__content">
            <div className="task-assessment__title">{name}</div>
            {group?.text && (
              <div className="task-assessment__sub-title task-assessment__sub-title--sm">
                {group?.text}
              </div>
            )}
            {!!group?.wait && isAnyActualAssessment && (
              <div className="task-assessment__sub-title">
                <h4>термін виконання днів {group.term > 0 && group.term}</h4>
                {group?.textStatus === 'new' &&
                  group.userStatus === 1 &&
                  isNotArchive && (
                    <div>
                      <Input
                        defaultValue={group?.term || days}
                        min={1}
                        type="number"
                        onChange={(e) => setDays(e.target.value)}
                      />
                      {+days > 0 && (
                        <Button
                          view="default"
                          onClick={() => {
                            updateAssessmentHandler(group.id);
                            setDays('');
                          }}
                          className="task-assessment__btn"
                        >
                          Ок
                        </Button>
                      )}
                    </div>
                  )}
              </div>
            )}
            {!!group?.wait && !!group.calculations.length && (
              <div className="task-assessment__table-wrapper">
                <table className="task-assessment__table">
                  <tr>
                    <th className="task-assessment__table-heading">
                      Опис робіт
                    </th>
                    <th className="task-assessment__table-heading">
                      Час, год{' '}
                    </th>
                    <th className="task-assessment__table-heading">
                      Вартість{' '}
                    </th>
                  </tr>
                  {group?.calculations &&
                    group?.calculations?.map((calculation: any) => {
                      const fields = ['text', 'hour', 'price', 'priceOld'];
                      return (
                        <tr key={calculation.id}>
                          {fields?.map((field) => (
                            <Strike
                              key={field}
                              status={
                                calculation?.priceOld && field === 'priceOld'
                                  ? 1
                                  : 0
                              }
                              tag="td"
                            >
                              {calculation[field]}
                              {((field === 'price' && calculation?.price) ||
                                (field === 'priceOld' &&
                                  calculation?.priceOld)) &&
                                ' грн'}
                            </Strike>
                          ))}
                          {!calculation.status &&
                            group?.textStatus === 'new' &&
                            isNotArchive && (
                              <td>
                                <Button
                                  view="warning"
                                  className="task-assessment__delete-btn"
                                  onClick={() =>
                                    deleteAssessmentHandler(
                                      calculation.id,
                                      group.id
                                    )
                                  }
                                >
                                  <span className="ci ci-trash_full" />
                                </Button>
                              </td>
                            )}
                          {group?.status > 5 &&
                            calculation?.status === 3 &&
                            isNotArchive &&
                            group?.userStatus === 2 && (
                              // {calculation.status > 2 && confirmClient && (
                              <td>
                                <Button
                                  view="success"
                                  className="task-assessment__check-btn"
                                  onClick={() => {
                                    dispatch(
                                      checkAssessment({
                                        slug: task.slug,
                                        groupId: group.id,
                                      })
                                    );
                                  }}
                                >
                                  <CheckLogo />
                                </Button>
                              </td>
                            )}
                        </tr>
                      );
                    })}
                  <tr>
                    <td className="task-assessment__text-right task-assessment__text--small">
                      Загалом по КП:
                    </td>
                    <td>{group.totalHour}</td>
                    {task.total && <td>{task.total ? task.total : 0} грн</td>}
                    {group.totalOld && (
                      <Strike status={1} tag="td">
                        {group.totalOld} грн
                      </Strike>
                    )}
                  </tr>
                  <tr>
                    <td className="task-assessment__text-right task-assessment__text--small">
                      Старт робіт:{' '}
                    </td>
                    <td>{dayjs.unix(group.dateStart).format('DD.MM.YYYY')}</td>
                  </tr>
                  <tr>
                    <td className="task-assessment__text-right task-assessment__text--small">
                      Дедлайн:{' '}
                    </td>
                    <td className="task-assessment__text--small">
                      {group.deadline && (
                        <div>
                          {dayjs.unix(group.deadline).format('DD.MM.YYYY')}
                        </div>
                      )}
                      {group.deadlineOld && (
                        <Strike status={1}>
                          {dayjs.unix(group.deadlineOld).format('DD.MM.YYYY')}
                        </Strike>
                      )}
                    </td>
                  </tr>
                </table>
              </div>
            )}

            {!!group?.wait && (
              <>
                {isAddAssessment[groupIndex] && group?.textStatus === 'new' ? (
                  <AddAssessment
                    task={task}
                    isAddAssessment={isAddAssessment}
                    groupId={group.id}
                    changeAssessment={() => changeAddAssessment(groupIndex)}
                  />
                ) : (
                  <div className="task-assessment__btns">
                    {group?.textStatus === 'new' &&
                    group.userStatus === 1 &&
                    isNotArchive ? (
                      <>
                        <Button
                          view="default"
                          onClick={() => changeAddAssessment(groupIndex)}
                          className="task-assessment__btn"
                        >
                          Додати оцінку
                        </Button>
                        {+group.term > 0 && isAnyActualAssessment && (
                          <Button
                            view="success"
                            onClick={() => finishAssessmentHandler(group.id)}
                            className="task-assessment__btn task-assessment__btn--color-white"
                          >
                            Завершити оцінювання
                          </Button>
                        )}
                      </>
                    ) : group?.textStatus === 'new' && isNotArchive ? (
                      <Button
                        view="third"
                        className="task-assessment__btn task-assessment__btn--finish"
                        onClick={() => continueAssessmentHandler(group.id)}
                      >
                        Продовжити оцінювання
                      </Button>
                    ) : null}
                  </div>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

const Strike = ({
  children,
  status,
  className,
  tag = 'div',
  ...rest
}: any): JSX.Element => {
  const TagName = tag;
  return (
    <TagName
      className={cx(className, {
        'task-assessment__through': status === 1,
      })}
      style={{
        whiteSpace: 'break-spaces',
      }}
      {...rest}
    >
      {children}
    </TagName>
  );
};

const AddAssessment = ({
  task,
  changeAssessment,
  isAddAssessment,
  groupId,
}: any): JSX.Element => {
  const [assessment, setAssessment] = useState({
    text: '',
    hour: '',
  });
  const [errors, setErrors] = useState({
    text: false,
    hour: false,
  });
  const dispatch: AppDispatch = useDispatch();

  const addAssessmentHandler = (): void => {
    dispatch(
      addAssessment({
        slug: task.slug,
        action: 'add',
        hour: assessment.hour,
        text: assessment.text,
        groupId,
      })
    )
      .then(() => {
        dispatch(getTaskData(task.slug));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const changeAssessmentHandler = (e: any, type: string): void => {
    setAssessment({ ...assessment, [type]: e.target.value });
  };

  const sendAssessmentHandler = (): void => {
    if (!assessment.text.trim().length) {
      setErrors((prev) => ({ ...prev, text: true }));
    } else if (assessment.text.length) {
      setErrors((prev) => ({ ...prev, text: false }));
    }
    if (+assessment.hour < 1) {
      setErrors((prev) => ({ ...prev, hour: true }));
    } else if (+assessment.hour > 0) {
      setErrors((prev) => ({ ...prev, hour: false }));
    }
    if (assessment.text.length && +assessment.hour > 0) {
      addAssessmentHandler();
      changeAssessment();
    }
  };

  useEffect(() => {
    document.querySelectorAll('input[type="number"]').forEach((el: any) => {
      el.addEventListener('keydown', inputNumberHandler);
    });

    return () => {
      document.querySelectorAll('input[type="number"]').forEach((el: any) => {
        el.removeEventListener('keydown', inputNumberHandler);
      });
    };
  }, [isAddAssessment]);

  return (
    <div>
      <div className="task-assessment__item">Нова оцінка</div>
      <form action="javascript:void(0);" className="task-assessment__item">
        <span className="task-assessment__label">Короткий опис робіт</span>
        <textarea
          autoFocus
          className="task-assessment__input"
          onChange={(e) => changeAssessmentHandler(e, 'text')}
        />
        {errors.text && (
          <div className="task-assessment__error task-assessment__error--textarea">
            Введіть опис робіт
          </div>
        )}
        <span className="task-assessment__label">
          Час на виконання завдання, год
        </span>
        <Input
          type="number"
          onChange={(e) => changeAssessmentHandler(e, 'hour')}
          min={1}
        >
          {errors.hour && (
            <div className="task-assessment__error">
              Введіть кількість годин
            </div>
          )}
        </Input>
        <div className="task-assessment__btns">
          <Button
            onClick={() => {
              changeAssessment();
            }}
          >
            Закрити
          </Button>
          <Button view="default" onClick={sendAssessmentHandler} type="submit">
            Зберегти
          </Button>
        </div>
      </form>
    </div>
  );
};

export const AddCommercialOffer = ({
  task,
  changeAssessment,
  groupId,
}: any): JSX.Element => {
  const [assessment, setAssessment] = useState({
    text: '',
    hour: '',
  });
  const [errors, setErrors] = useState({
    text: false,
    hour: false,
  });
  const dispatch: AppDispatch = useDispatch();

  const addAssessmentHandler = (): void => {
    dispatch(
      addAssessment({
        slug: task.slug,
        action: 'add',
        hour: assessment.hour,
        text: assessment.text,
        groupId,
      })
    )
      .then(() => {
        dispatch(getTaskData(task.slug));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const changeAssessmentHandler = (e: any, type: string): void => {
    setAssessment({ ...assessment, [type]: e.target.value });
  };

  const sendAssessmentHandler = (): void => {
    if (!assessment.text.trim().length) {
      setErrors((prev) => ({ ...prev, text: true }));
    } else if (assessment.text.length) {
      setErrors((prev) => ({ ...prev, text: false }));
    }
    if (+assessment.hour < 1) {
      setErrors((prev) => ({ ...prev, hour: true }));
    } else if (+assessment.hour > 0) {
      setErrors((prev) => ({ ...prev, hour: false }));
    }
    if (assessment.text.length && +assessment.hour > 0) {
      addAssessmentHandler();
      changeAssessment();
    }
  };

  return (
    <div>
      <div className="task-assessment__item">Нова оцінка</div>
      <form action="javascript:void(0);" className="task-assessment__item">
        <span className="task-assessment__label">Назва КП</span>
        <Input onChange={(e) => changeAssessmentHandler(e, 'text')} />
        {errors.text && (
          <div className="task-assessment__error">Введіть назву КП</div>
        )}
        <span className="task-assessment__label"> Технічне завдання</span>
        <textarea
          autoFocus
          className="task-assessment__input"
          onChange={(e) => changeAssessmentHandler(e, 'text')}
        />
        {errors.text && (
          <div className="task-assessment__error task-assessment__error--textarea">
            Введіть технічне завдання
          </div>
        )}
        <span className="task-assessment__label">Термін виконання, днів:</span>
        <Input
          type="number"
          onChange={(e) => changeAssessmentHandler(e, 'hour')}
          min={1}
        >
          {errors.hour && (
            <div className="task-assessment__error">Введіть термін днів</div>
          )}
        </Input>
        <CustomSelect
          options={[
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '11', label: '11' },
            { value: '12', label: '12' },
            { value: '13', label: '13' },
            { value: '14', label: '14' },
            { value: '15', label: '15' },
            { value: '16', label: '16' },
            { value: '17', label: '17' },
            { value: '18', label: '18' },
            { value: '19', label: '19' },
            { value: '20', label: '20' },
            { value: '21', label: '21' },
            { value: '22', label: '22' },
            { value: '23', label: '23' },
            { value: '24', label: '24' },
            { value: '25', label: '25' },
            { value: '26', label: '26' },
            { value: '27', label: '27' },
            { value: '28', label: '28' },
            { value: '29', label: '29' },
            { value: '30', label: '30' },
          ]}
          onChange={(e: any) => changeAssessmentHandler(e, 'hour')}
        />
        <div className="task-assessment__btns">
          <Button
            onClick={() => {
              changeAssessment();
            }}
          >
            Закрити
          </Button>
          <Button view="default" onClick={sendAssessmentHandler} type="submit">
            Зберегти
          </Button>
        </div>
      </form>
    </div>
  );
};

const CommercialOfferField = ({
  label,
  type,
  onSubmit,
  placeholder,
  defaultValue,
  className,
  canEdit = true,
  min = 1,
  ...rest
}: any): JSX.Element => {
  const [value, setValue] = useState('');
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [errors, setErrors] = useState({
    text: false,
  });

  const handleChange = useCallback(
    (e: any): void => {
      setValue(e.target.value);

      if (type === 'number' && (e.target.value.length || +e.target.value < 1)) {
        setErrors((prev) => ({ ...prev, text: false }));
        setIsButtonActive(true);
      } else if (e.target.value.length) {
        setErrors((prev) => ({ ...prev, text: false }));
        setIsButtonActive(true);
      }
    },
    [type]
  );

  const onClickHandler = useCallback(() => {
    if (type === 'number' && (!value.trim().length || +value < 0)) {
      setErrors((prev) => ({ ...prev, text: true }));
    } else if (!value.trim().length) {
      setErrors((prev) => ({ ...prev, text: true }));
    } else if (type === 'date') {
      const date = new Date(value).getTime();
      const now = new Date().getTime();
      console.log(date, now);
      if (date < now) {
        setErrors((prev) => ({ ...prev, text: true }));
      } else {
        setIsButtonActive(false);
        onSubmit(value);
        setErrors((prev) => ({ ...prev, text: false }));
        setValue('');
      }
    }
    if (value.length && (type !== 'number' || +value > -1 || type !== 'date')) {
      setIsButtonActive(false);
      onSubmit(value);
      setErrors((prev) => ({ ...prev, text: false }));
      setValue('');
    }
  }, [value, type]);

  return (
    <div className={className}>
      {label && (
        <div
          className={cx({
            'commercial-offer__label': canEdit,
          })}
        >
          {label} {!canEdit && defaultValue}
        </div>
      )}
      {!!canEdit &&
        (type === 'textarea' ? (
          <textarea
            className="commercial-offer__input commercial-offer__input--textarea"
            placeholder={placeholder}
            onChange={handleChange}
            defaultValue={defaultValue}
            style={
              {
                border: errors.text && '1px solid #FF0000',
              } as React.CSSProperties
            }
          />
        ) : (
          <Input
            type={type}
            onChange={handleChange}
            min={type === 'number' ? min : formatDate(new Date())}
            placeholder={placeholder}
            defaultValue={defaultValue}
            style={
              {
                border: errors.text && '1px solid #FF0000',
                minHeight: !isButtonActive && 'auto',
              } as React.CSSProperties
            }
          />
        ))}
      {isButtonActive && (
        <Button
          onClick={onClickHandler}
          className="commercial-offer__button commercial-offer__button--success commercial-offer__button--success-small"
          view="success"
        >
          Ок
        </Button>
      )}
    </div>
  );
};

const CommercialOfferDevelopers = ({
  group,
  onClick,
  onChange,
  isProjectManager,
  isSalesManager,
  onChatChange,
  onReject,
}: any): JSX.Element => (
  <div className="commercial-offer-developer">
    {group?.developers.map(({ speciality, users }: any) => (
      <div className="commercial-offer-developer__item">
        <div className="commercial-offer-developer__title">{speciality}</div>
        <div>
          {users.map(
            ({
              firstName,
              lastName,
              id,
              deadline,
              calculations,
              totalHour,
              term,
              chat,
              termOld,
              price,
              priceOld,
              totalOld,
              total,
              wait,
            }: any) => {
              console.log('====================================');
              console.log(2);
              console.log('====================================');
              return (
                <table key={id} className="commercial-offer-developer__staff">
                  <div className="commercial-offer-developer__staff-name">{`${firstName} ${lastName}`}</div>
                  {!!calculations.length && (
                    <tr className="commercial-offer-developer__staff-header">
                      <td
                        className={cx(
                          'commercial-offer-developer__staff-header-item',
                          {
                            'commercial-offer-developer__staff-header-item--text':
                              isSalesManager,
                          }
                        )}
                      >
                        Опис робіт:
                      </td>
                      <td className="commercial-offer-developer__staff-header-item">
                        Час, год{' '}
                      </td>
                      {isSalesManager && (
                        <td className="commercial-offer-developer__staff-header-item commercial-offer-developer__staff-header-item--price">
                          Вартість
                        </td>
                      )}
                    </tr>
                  )}
                  <tr>
                    {calculations?.map((calculation: any) => {
                      const baseFields = ['text', 'hour', 'total', 'totalOld'];
                      const fields = !isSalesManager
                        ? baseFields
                        : [...baseFields, 'price', 'priceOld'];

                      return (
                        <>
                          <td
                            key={calculation.id}
                            className="commercial-offer-developer__staff-calculation"
                          >
                            {fields?.map((field) => {
                              if (
                                (field === 'price' ||
                                  field === 'priceOld' ||
                                  field === 'total' ||
                                  field === 'totalOld') &&
                                isSalesManager
                              ) {
                                return null;
                              }
                              return (
                                <Strike
                                  className={cx(
                                    'commercial-offer-developer__staff-calculation--coll',
                                    {
                                      'commercial-offer-developer__staff-calculation--coll-text':
                                        isSalesManager && field === 'text',
                                      'commercial-offer-developer__staff-calculation--coll-hour':
                                        field === 'hour',
                                    }
                                  )}
                                  tag="td"
                                  key={field}
                                  status={calculation.status}
                                >
                                  {calculation[field]}
                                </Strike>
                              );
                            })}
                            {calculation.textStatus === 'checkComplete' &&
                              isProjectManager && (
                                <Button
                                  view="warning"
                                  className="task-assessment__delete-btn"
                                  onClick={() => onReject(calculation.id)}
                                >
                                  <span className="ci ci-trash_full" />
                                </Button>
                              )}
                            {isSalesManager && (
                              <div
                                // className="commercial-offer-developer__staff-calculation"
                                className="commercial-offer-developer__staff-calculation--coll commercial-offer-developer__staff-calculation--short commercial-offer-developer__staff-calculation--price"
                              >
                                {fields?.map((field: any) => {
                                  if (
                                    field === 'price' ||
                                    field === 'priceOld'
                                  ) {
                                    return (
                                      <Strike
                                        key={field}
                                        status={
                                          calculation[field] ===
                                          calculation.priceOld
                                            ? 1
                                            : 0
                                        }
                                      >
                                        {((field === 'price' &&
                                          calculation?.price) ||
                                          (field === 'priceOld' &&
                                            calculation?.priceOld)) &&
                                          `${calculation[field]} грн`}
                                      </Strike>
                                    );
                                  }
                                  return null;
                                })}
                              </div>
                            )}
                          </td>
                        </>
                      );
                    })}
                    {!!calculations.length && (
                      <tr className="commercial-offer-developer__staff-footer">
                        <td className="commercial-offer-developer__staff-calculation">
                          <div className="commercial-offer-developer__staff-footer--coll">
                            Загалом по спавробітнику:
                          </div>
                          <div className="commercial-offer-developer__staff-footer--coll">
                            {totalHour}
                          </div>
                          {isSalesManager && total && (
                            <div className="commercial-offer-developer__staff-calculation--short commercial-offer-developer__staff-calculation--price">
                              {`${total} грн`}
                              {totalOld && (
                                <Strike status={1}>{`${totalOld} грн`}</Strike>
                              )}
                            </div>
                          )}
                        </td>
                        {!!term && (
                          <td className="commercial-offer-developer__staff-calculation">
                            <div className="commercial-offer-developer__staff-footer--coll">
                              Термін виконання співробітника, днів:
                            </div>
                            <div className="commercial-offer-developer__staff-footer--coll-right">
                              {term}
                              <Strike
                                status={1}
                                tag="span"
                                style={{
                                  marginLeft: '10px',
                                }}
                              >
                                {termOld}
                              </Strike>
                            </div>
                            {isSalesManager && (
                              <div className="commercial-offer-developer__staff-footer--coll commercial-offer-developer__staff-footer--empty" />
                            )}
                          </td>
                        )}
                        <tr className="commercial-offer-developer__staff-calculation">
                          <td className="commercial-offer-developer__staff-footer--coll">
                            Дедлайн співробітника:{' '}
                          </td>
                          <td className="commercial-offer-developer__staff-footer--coll-right">
                            {dayjs.unix(deadline).format('DD.MM.YYYY')}
                            {group?.deadlineOld && (
                              <Strike status={1}>
                                {dayjs
                                  .unix(group?.deadlineOld)
                                  .format('DD.MM.YYYY')}
                              </Strike>
                            )}
                          </td>
                          {isSalesManager && (
                            <td className="commercial-offer-developer__staff-footer--coll commercial-offer-developer__staff-footer--empty" />
                          )}
                        </tr>
                      </tr>
                    )}
                  </tr>
                  {isProjectManager && (
                    <>
                      <tr className="commercial-offer-developer__toglers">
                        <td className="commercial-offer-developer__togler-wrapper">
                          <Toggler
                            size="xs"
                            key={wait}
                            disabled={group?.textStatus !== 'new'}
                            defaultChecked={wait}
                            className="commercial-offer__developer-toggler-wrapper"
                            checkboxClassName="commercial-offer__developer-toggler"
                            onChange={(value: any) => onChange(id, +value)}
                          />

                          <label className="commercial-offer-developer__label">
                            Очікувати оцінку
                          </label>
                        </td>
                        <td className="commercial-offer-developer__togler-wrapper">
                          <Toggler
                            size="xs"
                            defaultChecked={chat}
                            key={chat}
                            className="commercial-offer__developer-toggler-wrapper"
                            checkboxClassName="commercial-offer__developer-toggler"
                            onChange={(value: any) => onChatChange(id, +value)}
                          />

                          <label className="commercial-offer-developer__label">
                            Дозволити чат з клієнтом
                          </label>
                        </td>
                      </tr>
                      {group?.textStatus === 'new' && (
                        <Button
                          className="commercial-offer-developer__button"
                          view="warning"
                          variant="fill"
                          size="xs"
                          onClick={() => onClick(id)}
                        >
                          Видалити
                        </Button>
                      )}
                    </>
                  )}
                </table>
              );
            }
          )}
        </div>
      </div>
    ))}
  </div>
);

const PriorityRating = ({ priority = 0 }): JSX.Element => (
  <div className="commercial-offer__priority">
    {new Array(4).fill(false).map((rating, i) => (
      <div
        key={rating}
        className={cx('commercial-offer__priority-rating', {
          [`commercial-offer__priority-rating--${i + 1}`]: i + 1 <= priority,
        })}
      />
    ))}
  </div>
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../../services/user.service';

export const getRoleList = createAsyncThunk('user/getRoleList', async () => {
  try {
    return await UserService.getRoleList();
  } catch (error) {
    return error;
  }
});

export const updateUserInfo = createAsyncThunk(
  'user/updateName',
  async (data: any) => {
    try {
      return await UserService.updateUserInfo(data);
    } catch (error) {
      return error;
    }
  }
);

export const updateAvatar = createAsyncThunk(
  'user/updateAvatar',
  async (data: any) => {
    try {
      return await UserService.updateAvatar(data);
    } catch (error) {
      return error;
    }
  }
);

export const deleteAvatar = createAsyncThunk('user/deleteAvatar', async () => {
  try {
    return await UserService.deleteAvatar();
  } catch (error) {
    return error;
  }
});

export const updatePassword = createAsyncThunk(
  'user/updatePassword',
  async (data: any) => {
    try {
      return await UserService.updatePassword(data);
    } catch (error) {
      return error;
    }
  }
);

export const getUserData = createAsyncThunk('user/showS', async () => {
  try {
    return await UserService.getUserData();
  } catch (error) {
    return error;
  }
});

export const generateBotToken = createAsyncThunk(
  'user/generateBotToken',
  async (data: any) => {
    try {
      return await UserService.generateBotToken(data);
    } catch (error) {
      return error;
    }
  }
);

export const getFilesRules = createAsyncThunk(
  'task/getFilesRules',
  async () => {
    try {
      return await UserService.getFilesRules();
    } catch (error) {
      return error;
    }
  }
);

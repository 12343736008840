import { AxiosResponse } from 'axios';
import { projectInstance } from '../helpers/axios';

const getRoleList = (): Promise<any> =>
  projectInstance
    .get('user/role')
    .then((response: AxiosResponse) => response.data);

const updateUserInfo = ({ param, value }: any): Promise<any> =>
  projectInstance
    .post('user/save', {
      param,
      value,
    })
    .then((response: AxiosResponse) => response.data);

const getUserData = (): Promise<any> =>
  projectInstance.get('user').then((response: AxiosResponse) => response.data);

const updateAvatar = (data: any): Promise<any> =>
  projectInstance
    .post('user/avatar', data)
    .then((response: AxiosResponse) => response.data);

const deleteAvatar = (): Promise<any> =>
  projectInstance
    .post('user/avatar/delete')
    .then((response: AxiosResponse) => response.data);

const updatePassword = (data: any): Promise<any> =>
  projectInstance
    .post('user/save', data)
    .then((response: AxiosResponse) => response.data);

const generateBotToken = (data: any): Promise<any> =>
  projectInstance
    .post('user/token', data)
    .then((response: AxiosResponse) => response.data);

const getFilesRules = (): Promise<any> =>
  projectInstance
    .get('/rules')
    .then((response: AxiosResponse) => response.data);

const userService = {
  getRoleList,
  updateUserInfo,
  updateAvatar,
  deleteAvatar,
  updatePassword,
  getUserData,
  generateBotToken,
  getFilesRules,
};

export default userService;

import io from 'socket.io-client';

const URL = process.env.REACT_APP_SOCKET_URL;

// window.addEventListener('offline', () => {
//     console.log('offline');
//     socket.close();
// }
// );

// window.addEventListener('online', () => {
//     console.log('online');
//     socket = io(URL, {
//         reconnection: true,
//         reconnectionDelay: 1000,
//         reconnectionDelayMax: 5000,
//     });
// }
// );

// let socket = io(URL, {
//     reconnection: true,
//     reconnectionDelay: 0,
//     reconnectionDelayMax: 5000,
// });

// export {
//     socket
// };

export const socket = io(URL, {
    reconnection: true,
    reconnectionDelay: 0,
    reconnectionDelayMax: 5000,
});

import React, { memo, forwardRef } from 'react';
import cx from 'classnames';

import { useThemeToggler } from '../../context/ThemeContext';

import './Emojies.scss';

interface IEmojiesBlock {
  emojies: string[];
  onClick: (item: string, messageId: string) => void;
  className?: string;
  emojiClassName?: string;
  hasTouchScreen?: boolean;
  message?: {
    id: string;
    text: string;
  };
}

export const EmojiesBlock = memo(
  forwardRef<HTMLDivElement, IEmojiesBlock>(
    (
      {
        emojies,
        className,
        onClick,
        message,
        emojiClassName,
        hasTouchScreen = false,
      },
      ref
    ) => {
      const { theme } = useThemeToggler();

      const handleOnClick = (item: string): void => {
        onClick(item, message?.id as string);
        console.log('item:', item);
      };

      return (
        <div
          ref={ref}
          className={cx(
            'emoji-block',
            {
              [`emoji-block--${theme}`]: theme,
            },
            className
          )}
        >
          {emojies.map((item) => (
            <div
              key={item}
              onClick={!hasTouchScreen ? () => handleOnClick(item) : () => {}}
              onTouchEnd={hasTouchScreen ? () => handleOnClick(item) : () => {}}
              className={cx('emoji-block__item', emojiClassName)}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}
        </div>
      );
    }
  )
);

// export default memo(EmojiesBlock);

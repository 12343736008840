import { useEffect } from 'react';

import { socket } from '../socket.js';

export const useSocketListener = (eventName: string, handler: any): void => {
  useEffect(() => {
    socket.on(eventName, handler);
    return () => {
      socket.off(eventName, handler);
    };
  }, [eventName, handler]);
};

import { TChartLabel } from '../common/types';

export type TPermission = 'ADMIN' | 'MANAGER' | 'CLIENT' | 'STAFF';

export const priorityStatuses = {
  1: 'Трохи почекаю',
  2: 'Є потреба',
  3: 'Терміново',
  4: 'НЕГАЙНО!',
};

export const PERMISSION: any = {
  1: 'ADMIN',
  2: 'MANAGER',
  3: 'CLIENT',
  4: 'STAFF',
};

export const isDarkMode =
  window.matchMedia &&
  window.matchMedia('(prefers:color:scheme: dark)').matches;

export const DARK_THEME = 'DARK_THEME';

export const emojiesObject = {
  recently: {
    '👍': '&#128077;',
    '🔥': '&#128293;',
    '❤️': '&#10084;',
    '👎': '&#128078;',
    '😁': '😁',
    '🤔': '&#129300;',
  },
  getaAll() {
    return {
      ...this.recently,
      '👏': '&#128079;',
      '😅': '&#128517;',
    };
  },
};

export const imgExtensions = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'webp',
  'jpe',
  'bmp',
  'ico',
  'tif',
  'tiff',
];

export const videoExtensions = [
  'mp4',
  'webm',
  'ogg',
  'mov',
  'avi',
  'wmv',
  'flv',
  'h264',
  'm4v',
  'mpg',
  'mpeg',
];

const graphicExtensions = ['cdr', 'psd', 'ai', 'eps', 'fig'];

const archiveExtensions = [
  'zip',
  'svgz',
  'rar',
  'tar',
  'targz',
  '7z',
  'gz',
  'gzip',
  'tgz',
  'zipx',
];

const textExtensions = ['txt', 'sql', 'log', 'cfg', 'conf', 'ini'];

const audioExtensions = [
  'mp3',
  'wav',
  'wma',
  'ogg',
  'aac',
  'flac',
  'alac',
  'm3u',
  'm4a',
];
const other = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];

export const allExtensions = [
  ...imgExtensions,
  ...videoExtensions,
  ...graphicExtensions,
  ...archiveExtensions,
  ...textExtensions,
  ...audioExtensions,
  ...other,
];

export const months = {
  1: 'Січень',
  2: 'Лютий',
  3: 'Березень',
  4: 'Квітень',
  5: 'Травень',
  6: 'Червень',
  7: 'Липень',
  8: 'Серпень',
  9: 'Вересень',
  10: 'Жовтень',
  11: 'Листопад',
  12: 'Грудень',
};

export const bonusesChartLabels: TChartLabel[] = [
  {
    label: 'Бонус за поповнення рахунку',
    color: '#20B96D',
  },
  {
    label: 'Бонус за відправку задачі в роботу',
    color: '#7AD457',
  },
  {
    label: 'Інші бонуси',
    color: '#F18055',
  },
];

export const balanceChartLabels: TChartLabel[] = [
  {
    label: 'Поповнення балансу',
    color: '#20B96D',
  },
  {
    label: 'Витрати на чайові',
    color: '#ADD8FF',
  },
  {
    label: 'Інші витрати',
    color: '#F18055',
  },
];

export const staffBalanceChartLabels: TChartLabel[] = [
  {
    label: 'Основні роботи',
    color: '#24D37B',
  },
  {
    label: 'Додаткові роботи',
    color: '#5987F8',
  },
];

export const projectChartLabels: TChartLabel[] = [
  {
    label: 'Витрати на відправку завдань в роботу',
    color: '#5987F8',
  },
  // {
  //   label: 'Поповнення балансу проєктів',
  //   color: '#5987F8',
  // },
  // {
  //   label: 'Чайові',
  //   color: '#ADD8FF',
  // },
  // {
  //   label: 'Інші витрати',
  //   color: '#20B96D',
  // },
];

export const mockPriorities = [
  {
    priority: 1,
    title: 'Почекаю',
  },
  {
    priority: 2,
    title: 'Є потреба',
  },
  {
    priority: 3,
    title: 'Терміново',
  },
  {
    priority: 4,
    title: 'НЕГАЙНО!',
  },
];

export const commercialOfferYextStatuses = {
  0: 'delete',
  3: 'checkStart',
  5: 'reoffer',
  6: 'confirmStart',
  7: 'checkTest',
  8: 'successTest',
  9: 'checkProd',
  10: 'archive',
};

export const commercialOfferTextStatuses = {
  delete: 'delete',
  checkStart: 'checkStart',
  reoffer: 'reoffer',
  confirmStart: 'confirmStart',
  checkTest: 'checkTest',
  successTest: 'successTest',
  checkProd: 'checkProd',
  archive: 'archive',
};

export const emailValidationString =
  /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+$/;

import { useState, useEffect, Dispatch } from 'react';

import { setLocalStorage, getLocalStorage } from '../helpers/common';

const useLocalStorage = <T>(key: string, defaultValue: T): [T, Dispatch<T>] => {
  const [value, setValue] = useState<T>((): any => {
    let currentValue;

    try {
      currentValue = getLocalStorage(key, defaultValue);
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  useEffect(() => {
    setLocalStorage(key, value);
  }, [value, key]);

  return [value, setValue];
};

export default useLocalStorage;

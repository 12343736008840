import React from 'react';
import cx from 'classnames';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Button from '../../../components/Button/Button';
import { AppDispatch } from '../../../store';
import { restorePassword, setNewPassword } from '../../../store/auth/thunk';
import { translateData } from '../constant';
import { RestoreInputs } from '../types';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

function RestorePassword(): JSX.Element {
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch: AppDispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<RestoreInputs>();

  const onSubmit: SubmitHandler<RestoreInputs> = (data: RestoreInputs) => {
    dispatch(restorePassword(data))
      .unwrap()
      .then(() => {
        navigate('/send-password/', { replace: true });
      })
      .catch((e: any) => {
        const error = e.response.data;

        setError('email', {
          type: 'server',
          message: error.message,
        });
      });
  };

  const onSubmitPassword: SubmitHandler<RestoreInputs> = (
    data: RestoreInputs
  ) => {
    const newPasswordData = {
      data,
      token,
    };
    console.log(newPasswordData);
    dispatch(setNewPassword(newPasswordData))
      .unwrap()
      .then(() => {
        navigate('/login/', { replace: true });
      })
      .catch((e: any) => {
        const error = e.response.data;

        setError('password', {
          type: 'server',
          message: error.message,
        });
      });
  };

  // useDocumentTitle('restore password');

  return (
    <div className="section__auth-form">
      <h1
        className={cx('form__title', {
          'form__title--sm': token,
        })}
      >
        {!token ? translateData.UA.forgotPasswordText : 'Введіть новий пароль'}
      </h1>
      {!token ? (
        <EmailForm
          onSubmit={handleSubmit(onSubmit)}
          errors={errors}
          register={register}
        />
      ) : (
        <PasswordForm
          onSubmit={handleSubmit(onSubmitPassword)}
          errors={errors}
          register={register}
        />
      )}
    </div>
  );
}

export default RestorePassword;

const EmailForm = ({
  onSubmit,
  errors,
  register,
}: {
  onSubmit: any;
  errors: any;
  register: any;
}): JSX.Element => (
  <form onSubmit={onSubmit}>
    <fieldset className="form__fieldset">
      <label
        className="form__label form__label--login form__label--custom"
        htmlFor="email"
      >
        Email
      </label>
      <input
        className="form__input  form__input--login"
        id="email"
        placeholder={translateData.UA.placeholderEmail}
        style={{ borderColor: errors.email?.message && '#FF0000' }}
        {...register('email', {
          required: translateData.UA.emailErrorMsg,
        })}
      />
      {errors.email && (
        <span className="form__error">{errors.email.message}</span>
      )}
    </fieldset>

    <Button view="default" type="submit">
      {translateData.UA.restoreBtnText}
    </Button>

    <span className="section__auth-text" style={{ marginTop: '24px' }}>
      {translateData.UA.or}
      <NavLink to="/login/" style={{ marginLeft: '8px' }}>
        {translateData.UA.signInText}
      </NavLink>
    </span>
  </form>
);

const PasswordForm = ({
  onSubmit,
  errors,
  register,
}: {
  onSubmit: any;
  errors: any;
  register: any;
}): JSX.Element => (
  <form onSubmit={onSubmit}>
    <fieldset className="form__fieldset">
      <input
        className="form__input  form__input--login"
        id="password"
        placeholder={translateData.UA.placeholderPassword}
        style={{ borderColor: errors.password?.message && '#FF0000' }}
        {...register('password', {
          required: translateData.UA.passwordErrorMsg,
        })}
      />
      {errors.password && (
        <span className="form__error">{errors.password.message}</span>
      )}
    </fieldset>

    <Button view="default" type="submit">
      Оновити пароль
    </Button>
  </form>
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  --color: #fff;
  --size: 80px;
  position: absolute;
  width: var(--size);
  height: var(--size);
  color: var(--color);
}
.spinner:after {
  content: " ";
  display: block;
  width: calc(var(--size) * 0.8);
  height: calc(var(--size) * 0.8);
  border-radius: 50%;
  border: calc(var(--size) / 10) solid;
  border-color: currentColor transparent currentColor transparent;
  animation: spinner 1.2s linear infinite;
}
.spinner--xs {
  --size: 20px;
}
.spinner--sm {
  --size: 40px;
}
.spinner--md {
  --size: 80px;
}
.spinner--lg {
  --size: 120px;
}
.spinner--center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Spinner/Spinner.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EACI,YAAA;EACA,cAAA;EACA,8BAAA;EACA,+BAAA;EACA,kBAAA;EACA,oCAAA;EACA,+DAAA;EACA,uCAAA;AACR;AAEI;EACI,YAAA;AAAR;AAGI;EACI,YAAA;AADR;AAII;EACI,YAAA;AAFR;AAKI;EACI,aAAA;AAHR;AAMI;EACI,QAAA;EACA,SAAA;EACA,gCAAA;AAJR;AAOI;EACI;IACI,uBAAA;EALV;EAQM;IACI,yBAAA;EANV;AACF","sourcesContent":[".spinner {\n    --color: #fff;\n    --size: 80px;\n    position: absolute;\n    width: var(--size);\n    height: var(--size);\n    color: var(--color);\n\n    &:after {\n        content: \" \";\n        display: block;\n        width: calc(var(--size) * 0.8);\n        height: calc(var(--size) * 0.8);\n        border-radius: 50%;\n        border: calc(var(--size) / 10) solid;\n        border-color: currentColor transparent currentColor transparent;\n        animation: spinner 1.2s linear infinite;\n    }\n\n    &--xs {\n        --size: 20px;\n    }\n\n    &--sm {\n        --size: 40px;\n    }\n\n    &--md {\n        --size: 80px;\n    }\n\n    &--lg {\n        --size: 120px;\n    }\n\n    &--center {\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n    }\n\n    @keyframes spinner {\n        0% {\n            transform: rotate(0deg);\n        }\n\n        100% {\n            transform: rotate(360deg);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { Portal } from '../Portal/Portal';

import { useThemeToggler } from '../../context/ThemeContext';

import { ReactComponent as CloseIcon } from '../../media/icons/close_big.svg';

import './Modal.scss';

type Size = 'sm' | 'md' | 'lg';

interface IPopupProps {
  children: React.ReactNode;
  isActive: boolean;
  handleClose: () => void;
  bodyClassName?: string;
  modalClassName?: string;
  title?: string;
  zIndex?: number;
  leftButton?: boolean;
  size?: Size | (() => Size);
  headerAccentBackground?: boolean;
  headerModalClassName?: string;
  modalLiveButtonClassName?: string;
}

const Modal = forwardRef(
  (
    {
      isActive,
      children,
      title,
      handleClose,
      bodyClassName,
      headerAccentBackground,
      modalClassName,
      headerModalClassName,
      modalLiveButtonClassName,
      leftButton = true,
      zIndex = 1000,
      size = 'md',
    }: IPopupProps,
    ref: any
  ): JSX.Element | null => {
    const { theme } = useThemeToggler();
    const [isMounted, setIsMounted] = useState(isActive);
    const isPressBlocked = useRef(true);
    const currentElement = useRef(null);

    useEffect(() => {
      if (isActive && !isMounted) {
        setIsMounted(true);
      }

      return () => {
        setIsMounted(false);
      };
    }, [isActive]);

    const hideModalHandler = (e: any): void => {
      const { target, currentTarget } = e as any;
      if (currentTarget === target) {
        e.stopPropagation();
        target.querySelector('.modal').classList.remove('modal--is-active');
        target.classList.remove('overlay--is-active');
        setIsMounted(false);
      }
    };

    const closeModalHandler = (): void => {
      if (!isMounted) {
        handleClose();
      }
    };

    if (!isActive) {
      return null;
    }

    return (
      <Portal>
        <div
          onTransitionEnd={closeModalHandler}
          ref={ref}
          className={cx('overlay', {
            'overlay--is-active': isMounted,
            'overlay--mobile': modalLiveButtonClassName,
          })}
          onMouseDown={(e) => {
            (currentElement.current as any) = e.target;
          }}
          onMouseUp={(e) => {
            if (currentElement.current === e.target) {
              isPressBlocked.current = false;
              if (!isPressBlocked.current) {
                hideModalHandler(e);
              }
            }
          }}
          style={{ zIndex }}
        >
          <div
            onTransitionEnd={closeModalHandler}
            className={cx(
              'modal',
              {
                [`modal--${theme}`]: theme,
                [`modal--${size}`]: size,
                'modal--is-active': isMounted,
              },
              modalClassName
            )}
          >
            {leftButton && (
              <div
                className={cx(
                  'modal__side-button',
                  {
                    [`modal__side-button--${theme}`]: theme,
                  },
                  modalLiveButtonClassName
                )}
                onClick={() => {
                  if (isMounted) {
                    setIsMounted(false);
                  }
                }}
              >
                <CloseIcon />
              </div>
            )}
            {!leftButton && (
              <div
                className={cx(
                  'modal__header',
                  {
                    [`modal__header--${theme}`]: theme,
                  },
                  headerModalClassName
                )}
                style={{
                  background: headerAccentBackground ? '#F1556D' : '#EFF0F3',
                  color: headerAccentBackground ? '#fff' : '#544E61',
                }}
              >
                <h2 className="modal__title">{title}</h2>
                <button
                  type="button"
                  className="modal__close-btn"
                  onClick={() => {
                    setIsMounted(false);
                  }}
                >
                  <span className="ci ci-chevron_right" />
                </button>
              </div>
            )}

            <div className={cx('modal__body', bodyClassName)}>{children}</div>
          </div>
        </div>
      </Portal>
    );
  }
);

// export const ModalWrapper = (props: IPopupProps, ref: any): any => {
//   const { isActive } = props;
//   const [isMounted, setIsMounted] = useState(isActive);

//   useEffect(() => {
//     setTimeout(() => {
//       setIsMounted(isActive);
//     }, 1000);
//   }, [isActive]);

//   if (!isMounted) return null as any;
//   return <Modal {...props} />;
// };

export default memo(Modal);

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeLocalStorage } from '../helpers/common';

export const useAuthErrorHandler = (): (() => void) => {
  const navigate = useNavigate();

  return useCallback(() => {
    navigate('/login/');
    removeLocalStorage('user');
    removeLocalStorage('token');
    // window.location.reload();
  }, [navigate]);
};

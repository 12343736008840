import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Lightbox from 'react-image-lightbox';
import cx from 'classnames';

import 'react-image-lightbox/style.css';
import './Gallery.scss';

import { ReactComponent as IconClose } from '../../media/icons/expand.svg';
import { ReactComponent as IconDownload } from '../../media/icons/download.svg';
import { ReactComponent as IconTrash } from '../../media/icons/trash_full.svg';

interface IGalleryProps {
  images: string[];
  index: number;
  closeLightbox: () => void;
  onDownload: (index: number) => void;
  // onDelete: (index: number) => void;
  onDelete?: any;
  canDelete?: boolean;
}

const Gallery: React.FC<IGalleryProps> = ({
  images,
  closeLightbox,
  onDownload,
  onDelete,
  index = 0,
  canDelete = true,
}) => {
  const [photoIndex, setPhotoIndex] = useState(index);
  const [isFull, setIsFull] = useState(false);

  const handlerPrev = useCallback(() => {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  }, [photoIndex, images.length]);

  const handlerNext = useCallback(() => {
    setPhotoIndex((photoIndex + 1) % images.length);
  }, [photoIndex, images.length]);

  const handlerFullSize = useCallback(() => setIsFull(() => !isFull), [isFull]);

  const handlerDownload = useCallback(
    () => onDownload(photoIndex),
    [onDownload, photoIndex]
  );

  const handlerDelete = useCallback(
    () => onDelete(photoIndex),
    [onDelete, photoIndex]
  );

  useEffect(() => {
    if (isFull) {
      document.documentElement.requestFullscreen();
    }
    return () => {
      if (isFull) {
        document.exitFullscreen();
      }
    };
  }, [isFull]);

  return (
    <div>
      <Lightbox
        animationDuration={0.2}
        mainSrc={images[photoIndex]}
        prevSrc={
          (photoIndex &&
            images[(photoIndex + images.length - 1) % images.length]) ||
          ''
        }
        nextSrc={
          (photoIndex + 1 !== images.length &&
            images[(photoIndex + 1) % images.length]) ||
          ''
        }
        onCloseRequest={closeLightbox}
        enableZoom
        clickOutsideToClose
        onMovePrevRequest={handlerPrev}
        onMoveNextRequest={handlerNext}
        wrapperClassName={cx('gallery')}
        imageTitle={
          <GalleryTopBlock
            onDelete={handlerDelete}
            onDownload={handlerDownload}
            photoIndex={photoIndex}
            onFullSize={handlerFullSize}
            canDelete={canDelete}
            length={images.length}
          />
        }
      />
    </div>
  );
};

export default Gallery;

interface IGalleryTopBlockProps {
  photoIndex: number;
  length: number;
  onDownload: () => void;
  onDelete: () => void;
  onFullSize: () => void;
  canDelete?: boolean;
}

const GalleryTopBlock = memo(
  forwardRef(
    (
      {
        photoIndex,
        length,
        onFullSize,
        onDownload,
        onDelete,
        canDelete,
      }: IGalleryTopBlockProps,
      ref
    ) => {
      console.log('GalleryTopBlock');

      return (
        <div ref={ref as any} className="gallery-top-block">
          <div className="gallery-top-block__left">{`${
            photoIndex + 1
          } / ${length}`}</div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div className="gallery-top-block__right">
              <div
                onClick={onDownload}
                className="gallery-top-block__btn gallery-top-block__btn--download"
              >
                <IconDownload />
              </div>
              {canDelete && (
                <div
                  onClick={onDelete}
                  className="gallery-top-block__btn gallery-top-block__btn--delete"
                >
                  <IconTrash />
                </div>
              )}
            </div>
            <div className="gallery-top-block__full-size-btn">
              <IconClose
                onClick={onFullSize}
                className="gallery-top-block__full-size"
              />
            </div>
          </div>
        </div>
      );
    }
  )
);

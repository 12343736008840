import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createProject, fetchProjectList } from '../../store/projects/thunk';
import { AppDispatch } from '../../store';
import { TCreateProjectInputs } from './types';
import { clearProjectInfo } from '../../store/projects/store';

interface ICreateProjectProps {
  hideCreateProjectForm: (value: boolean) => void;
}

const CreateProject = forwardRef(
  ({ hideCreateProjectForm }: ICreateProjectProps, ref) => {
    const dispatch: AppDispatch = useDispatch();
    const [isSending, setIsSending] = useState<boolean>(false);
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<TCreateProjectInputs>();
    const navigate = useNavigate();
    const params = useParams();

    const onSubmit: SubmitHandler<TCreateProjectInputs> = async (
      data: TCreateProjectInputs
    ) => {
      setIsSending(true);
      await dispatch(createProject(data));
      hideCreateProjectForm(false);
      const reult = await dispatch(fetchProjectList());
      const currentNewProject = reult.payload.projectList.find(
        (project: any) => project.name === data.name
      );
      navigate(`/project/${currentNewProject.slug}/`);
      setIsSending(false);
    };

    useEffect(() => {
      if (ref) {
        (ref as any).current.click();
      }
    }, []);

    useLayoutEffect(() => {
      dispatch(clearProjectInfo());
    }, [params.slug]);

    return (
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="form__fieldset">
          <label
            ref={ref as any}
            htmlFor="name"
            className="form__label form__label--custom"
          >
            Створити новий проєкт
          </label>
          <input
            id="name"
            className="form__input"
            placeholder="Введіть назву проекту"
            style={{ borderColor: errors.name && '#FF0000' }}
            {...register('name', { required: true })}
          />
        </fieldset>

        <input
          style={{ opacity: isSending ? 0.8 : 1 }}
          disabled={isSending}
          type="submit"
          className="form__btn"
          value="Зберегти"
        />
      </form>
    );
  }
);

export default CreateProject;

import React, {
  CSSProperties,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Tab, TabList, Tabs } from 'react-tabs';
import { useMediaQuery } from 'react-responsive';

import Button from '../../components/Button/Button';
import { Message } from './Message/Message';
import AutoResizeTextarea from '../../forms/AutoResizeTextarea/AutoResizeTextarea';
import ContextMenu from './ContextMenu/ContextMenu';
import FileExtensions from '../../components/UI/FileExtensions/FileExtensions';
import Gallery from '../../components/Gallery/Gallery';
import Info from '../../components/UI/Info/Info';
import { EmojiesBlock } from '../../components/Emojies/Emojies';

import { AppDispatch } from '../../store';
import { IMessage } from '../../common/types';

import {
  createMessage,
  deleteMessage,
  editMessage,
  getMessagesList,
  recoverMessage,
  replyMessage,
  sendEmoji,
  chatClear,
  deleteFile,
} from '../../store/tasks/thunk';

import {
  GLOBAL_ROLES_COLORS,
  PROJECT_ROLES,
  PROJECT_ROLES_COLORS,
  generalChatType,
  managerChatType,
  managerToManagerChatType,
  techChatType,
} from '../../common/constant';

import { emojiesObject, imgExtensions } from '../../helpers/constant';
import { scrollToNode, setCursorPosition } from '../../helpers/common';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useThemeToggler } from '../../context/ThemeContext';

import { ReactComponent as IconSendArrow } from '../../media/icons/send-arrow.svg';
import { ReactComponent as IconHappy } from '../../media/icons/happy.svg';
import { ReactComponent as IconPaperClip } from '../../media/icons/clip.svg';

import { ReactComponent as IconSub } from '../../media/icons/sub_right.svg';
import { ReactComponent as IconClose } from '../../media/icons/close_big.svg';
import { ReactComponent as IconEdit } from '../../media/icons/edit.svg';

import './Chat.scss';
import Spinner from '../../components/UI/Spinner/Spinner';
import { useSocketListener } from '../../hooks/useSocketListener';
import {
  cancelSendingErrorMessage,
  TaskState,
  updateMessagesData as updateTypeIdMessage,
} from '../../store/tasks/store';
import Dot from '../../components/UI/Dot/Dot';
import { checkPermission } from '../../hooks/checkPermission';

interface IChatProps {
  staffMessageId: number | null;
  setStaffMessageId: (value: any) => void | undefined;
  inputRef?: any;
  nodeRef?: any;
  className?: string;
  typeId?: number;
  setTypeId?: (value: number) => void;
  isVisible?: boolean;
  disabled?: boolean;
  defaultTextareaHeight?: number;
  chatPlaceholder?: string;
}

type TAction = 'reply' | 'edit' | 'emoji' | '';

const selectMessages = (state: any): any => state.taskData;
const memoisedMessages = createSelector(
  selectMessages,
  (state: any) => state.taskMessages
);

export const scrollToStart = (selector = '.chat__body'): void =>
  document
    .querySelector(selector)
    ?.scrollTo(0, document.querySelector(selector)?.scrollHeight as number);

export const Chat = memo(
  ({
    inputRef,
    nodeRef,
    className,
    staffMessageId,
    setStaffMessageId,
    typeId = generalChatType,
    setTypeId = () => {},
    chatPlaceholder = 'Введіть текст повідомлення',
    disabled = false,
    isVisible = false,
    defaultTextareaHeight = 30,
  }: IChatProps) => {
    const { theme } = useThemeToggler();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const dispatch: AppDispatch = useDispatch();
    const params = useParams();
    const state = useSelector((st) => st) as any;
    const user = useSelector((userState: any) => userState.auth.user);
    const inform = useSelector(
      (projectState: any) => projectState.projectData.inform
    );
    const { taskInfo } = useSelector(
      (taskState: { taskData: TaskState }) => taskState.taskData
    );

    const taskMessages = memoisedMessages(state);

    const [selectedText, setSelectedText] = useState<string>('');
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [galleryVisible, setGalleryVisible] = useState<boolean>(false);

    const [isContextMenuShow, setIsContextMenuShow] = useState<boolean>(false);
    const [isActiveMessage, setIsActiveMessage] = useState<IMessage>(
      {} as IMessage
    );
    const [activeMessageId, setActiveMessageId] = useState<number>(0);
    const [firstGettingMessages, setFirstGettingMessages] =
      useState<boolean>(false);
    const [isMesageFromSocket, setIsMesageFromSocket] =
      useState<boolean>(false);
    const [isContextMenuLocked, setIsContextMenuLocked] =
      useState<boolean>(false);

    const [sendMessageSettings, setSendMessageSettings] = useState<{
      action: TAction;
      messageId: number;
      citeText?: string | null;
    }>({
      action: '',
      messageId: 0,
      citeText: null,
    });

    const showMessageContextMenuDelay = useRef<any>(1500);
    const chatRef = useRef<HTMLDivElement>(null);
    const contextMenuRef = useRef<any>(null);
    const contextMenuTimeout = useRef<any>(null);
    const timesHoldLeft = useRef<number>(0);

    useOnClickOutside(contextMenuRef, (e: any) => {
      setIsContextMenuShow(false);
      clearTimeout(contextMenuTimeout.current);
      timesHoldLeft.current = 0;
    });

    const handleSocket = useCallback(
      (data: any): void => {
        if (!navigator.onLine) {
          return;
        }
        // if (data.slug === params.slugTask && data.type === generalChatType) {
        if (data.slug === params.slugTask && data.type === typeId) {
          dispatch(
            getMessagesList({
              typeId,
              slug: params.slugTask as string,
              // userId: typeId === 3 ? user.id : null,
              userId: staffMessageId
                ? staffMessageId
                : typeId === 3
                ? user.id
                : null,
            })
          )
            .unwrap()
            .then(() => {
              setIsMesageFromSocket(false);
            });
        }
      },
      [params.slugTask, typeId]
    );

    useSocketListener('taskChat', handleSocket);

    const messages = useMemo(() => taskMessages?.messageList, [taskMessages]);

    const memoisedMessage = useMemo(
      () =>
        messages?.find(
          ({ id }: IMessage) => id === activeMessageId
        ) as IMessage,
      [messages, activeMessageId]
    );

    useEffect(() => {
      dispatch(
        getMessagesList({
          typeId,
          slug: params.slugTask as string,

          userId: staffMessageId
            ? staffMessageId
            : typeId === 3
            ? user.id
            : null,
        })
      )
        .unwrap()
        .then(() => {
          setFirstGettingMessages(true);
          scrollToStart();
        })
        .catch((error: any) => {
          console.log(error);
        });
    }, [isVisible, typeId]);

    const clearChatInfroHandler = useCallback(
      (data: any) => {
        if (checkPermission('STAFF') || checkPermission('MANAGER')) {
          dispatch(chatClear(data));
        }
      },
      [params.slugTask, typeId]
    );

    // the first time we get messages we need to scroll to the bottom
    useEffect(() => {
      if (messages?.length && !firstGettingMessages) {
        scrollToStart();
      }
    }, [messages?.length, firstGettingMessages]);

    // scroll to the bottom when we send a message and don't have a message from the socket
    useEffect(() => {
      if (!isMesageFromSocket) return;
      scrollToStart();
    }, [messages?.length, isMesageFromSocket]);

    useEffect(() => {
      const handleHideMenu = (e: any): void => {
        if (!e.target.classList.contains('context-menu')) {
          setIsContextMenuShow(false);
        }
      };
      const onContextMenuHandler = (e: any): any => {
        clearTimeout(contextMenuTimeout.current);
        timesHoldLeft.current = 0;
        if (
          !e.target.closest('.message') ||
          e.target.closest('.message--system')
        ) {
          setIsContextMenuShow(false);
          return;
        }
        if (e.target.closest('.message__deleted-content')) {
          return;
        }

        if (!window.ontouchend) {
          e.preventDefault();
        }
        // here we need to check if the message is deleted
        if (!e.target.closest('.message--deleted') && !isContextMenuShow) {
          setIsContextMenuShow(true);
        }

        const timeout = setTimeout(() => {
          const contextMenu = contextMenuRef.current;
          if (
            !contextMenu &&
            !chatRef.current &&
            !contextMenu?.closest('.task__messages-block')
          ) {
            return;
          }
          const clientX = e.clientX || e.changedTouches[0].clientX;
          const clientY = e.clientY || e.changedTouches[0].clientY;
          const chatOffsetTop = (document.querySelector(
            '.chat__inner'
          ) as any)!.getBoundingClientRect().top;

          const posY = e.changedTouches
            ? clientY - contextMenu?.offsetHeight - chatOffsetTop
            : window.innerHeight - clientY > contextMenu?.offsetHeight
            ? clientY
            : clientY - contextMenu?.offsetHeight;
          const posX =
            window.innerWidth - clientX > contextMenu?.clientWidth + 20
              ? clientX -
                window.innerWidth -
                contextMenu?.closest('.task__messages-block')!.offsetLeft +
                contextMenu?.closest('.task__messages-block')!.clientWidth * 2 -
                chatRef.current!.clientWidth
              : clientX -
                window.innerWidth +
                contextMenu?.closest('.task__messages-block')!.clientWidth -
                contextMenu!.clientWidth;
          contextMenu?.setAttribute('style', `top: ${posY}px; left: ${posX}px`);
        }, 10);
        return () => {
          clearTimeout(timeout);
        };
      };
      window.addEventListener('click', handleHideMenu);
      window.addEventListener('contextmenu', onContextMenuHandler);
      window.addEventListener('touchstart', (e: any) => {
        if (e.target.closest('.message')) {
          contextMenuTimeout.current = setTimeout(() => {
            if (showMessageContextMenuDelay.current < timesHoldLeft.current) {
              console.log(
                showMessageContextMenuDelay.current,
                timesHoldLeft.current
              );
              if (!isContextMenuShow) {
                setIsContextMenuShow(true);
              }
            }
            timesHoldLeft.current += 1200;
          }, 1000);
        } else {
          clearTimeout(contextMenuTimeout.current);
          timesHoldLeft.current = 0;
        }
      });
      window.addEventListener('touchend', onContextMenuHandler, {
        capture: false,
        passive: true,
      });

      return () => {
        window.removeEventListener('click', handleHideMenu);
        window.removeEventListener('contextmenu', onContextMenuHandler);
        window.removeEventListener('touchend', onContextMenuHandler);
      };
    }, [activeMessageId, isContextMenuShow]);

    useEffect(() => {
      const handler = (e: any): void => {
        if (
          e.target.closest('.message') &&
          !e.target.closest('.message--deleted') &&
          !e.target.closest('.message--system')
        ) {
          if (isContextMenuShow) {
            return;
          }
          setActiveMessageId(
            () => +e.target.closest('.message').dataset.messageId
          );
        }
      };
      document.addEventListener('contextmenu', handler);

      return () => {
        document.removeEventListener('contextmenu', handler);
      };
    }, [sendMessageSettings]);

    useEffect(() => {
      const scrollHandler = (): void => {
        if (isContextMenuShow) {
          setIsContextMenuShow(false);
        }
      };

      window.addEventListener('scroll', scrollHandler, true);

      return () => {
        window.removeEventListener('scroll', scrollHandler, true);
      };
    }, [isContextMenuShow]);

    useEffect(() => {
      // add selected text event listener

      const mouseOverHandler = (e: any): void => {
        if (!selectedText.length) {
          return;
        }
        const { target } = e;

        const message = target.closest('.message');
        const messageId = message?.getAttribute('data-message-id');
        activeId = +messageId;
        console.log(messageId);
        // console.log(messageId, activeMessageId);
        if (target.closest('.message__text')) {
          isSelecting = true;
        } else {
          isSelecting = false;
        }
      };

      let isSelecting = true;
      let activeId: any = null;
      const handleSelectedText = (): void => {
        if (!isSelecting) {
          return;
        }
        console.log(activeId, activeMessageId);
        const textSelected = (window as any).getSelection();
        if (
          !textSelected.anchorNode?.parentNode?.classList.contains(
            'message__text--inner'
          )
        ) {
          return;
        }
        if (
          textSelected.focusOffset !== 0 ||
          textSelected.extentOffset !== 0 ||
          (activeId !== null && (activeId as any) !== activeMessageId)
        ) {
          return;
        }
        console.log(textSelected);
        const text = textSelected.toString();
        // if (!text.length) {
        //   // document.removeEventListener('mouseover', mouseOverHandler);
        //   return;
        // }
        setSelectedText(text);
      };

      document.addEventListener('mouseover', mouseOverHandler);
      document.addEventListener('selectionchange', handleSelectedText);
    }, [isContextMenuShow, activeMessageId]);

    const deleteMessageHandler = useCallback(
      ({ id }: IMessage): void => {
        dispatch(deleteMessage({ messageId: id, slug: params.slugTask }));
      },
      [activeMessageId, params.slugTask]
    );

    const deleteFileHandler = useCallback(
      ({ file_id }: IMessage): void => {
        dispatch(deleteFile({ fileId: file_id, slug: params.slugTask }));
      },
      [activeMessageId, params.slugTask]
    );

    const handleRecoverMessage = useCallback(
      ({ id }: IMessage): void => {
        dispatch(recoverMessage({ messageId: id, slug: params.slugTask }));
      },
      [activeMessageId, params.slugTask]
    );

    const replyMessageHandler = useCallback(
      ({ id }: IMessage): void => {
        setActiveMessageId(() => id);

        setSendMessageSettings({
          action: 'reply',
          messageId: isActiveMessage.id,
        });
      },
      [activeMessageId, selectedText]
    );

    const editMessageHandler = useCallback(
      ({ text, setMessage }: any): void => {
        // setMessage(text);
        // alert('dasd');
        setSendMessageSettings({
          action: 'edit',
          messageId: isActiveMessage ? isActiveMessage.id : 0,
        });
      },
      [isActiveMessage]
    );

    const sendEmojiHandler = useCallback(
      async (nodeValue: string, messageId: string): Promise<any> => {
        await dispatch(
          sendEmoji({
            emoji: nodeValue,
            messageId,
            slug: params.slugTask,
          })
        );
        setSendMessageSettings({
          ...sendMessageSettings,
          action: 'emoji',
        });
      },
      [activeMessageId]
    );

    const cancelSendMesssageHandler = useCallback((): void => {
      dispatch(cancelSendingErrorMessage(memoisedMessage.tId));
      console.log(memoisedMessage);
      (window as any).deleteFromIndexedDB(memoisedMessage.tId);
    }, [memoisedMessage]);

    // const toggleEmojiHandler = useCallback((): void => {
    //   setIsEmojiVisible((prev) => !prev);
    // }, [isEmojiVisible]);

    const imagesFromMessages = [] as any[];
    messages?.forEach((item: any) => {
      if (item.file?.type === 'image') {
        imagesFromMessages.push(item.file?.url);
      }
    });

    const hasTouchScreen = 'ontouchstart' in window;
    const isProjectManager =
      checkPermission('MANAGER') &&
      user.roleId === PROJECT_ROLES.projectManager;
    const isSalesManager =
      checkPermission('MANAGER') && user.roleId === PROJECT_ROLES.salesManager;

    const usersWithChatEnabled = useMemo(
      () =>
        taskInfo?.groups
          ?.map((group: any) =>
            group?.developers?.map(({ users }: any) =>
              users?.map((usr: any) => ({
                type: managerChatType,
                title: usr.firstName,
                titleMobile: usr.firstName,
                chatEnabled: usr.chat,
                id: usr.id,
              }))
            )
          )
          .flat(Infinity),
      [taskInfo?.groups?.length, taskInfo?.groups?.developers?.length]
    );

    const uniqueUsersWithChatEnabled = usersWithChatEnabled?.filter(
      (item: any, i: number, arr: any) =>
        arr?.findIndex((el: any) => el?.id === item?.id) === i
    );

    return (
      <div
        ref={chatRef}
        className={cx(
          'chat',
          {
            [`chat--${theme}`]: theme,
          },
          className
        )}
        style={{
          backgroundColor:
            typeId !== generalChatType && theme !== 'dark' ? '#fffeef' : '',
        }}
      >
        <div className="chat__inner">
          {(checkPermission('STAFF') || checkPermission('MANAGER')) && (
            <ChatTypeTabs
              types={
                isProjectManager
                  ? chatPMTypes([
                      ...(uniqueUsersWithChatEnabled || []),
                      {
                        type: managerToManagerChatType,
                        title: 'PM/SM',
                        titleMobile: 'PM/SM',
                        for: 'manager',
                      },
                    ] as any)
                  : isSalesManager
                  ? chatSMTypes
                  : chatTypes
              }
              onClear={clearChatInfroHandler}
              inform={inform}
              userId={user.id}
              typeId={typeId}
              changeTypeId={(type, id) => {
                setTypeId(type);
                setSendMessageSettings({
                  action: '',
                  messageId: 0,
                });
                dispatch(updateTypeIdMessage());
                if (isProjectManager) {
                  setStaffMessageId(id);
                }
              }}
              theme={theme}
            />
          )}
          <div
            className="chat__body"
            style={{ height: disabled && '100%' } as CSSProperties}
          >
            <div className="chat__top-block" />
            {messages ? (
              messages?.map((item: any, i: number, array: any) => (
                <Message
                  key={item.id}
                  message={item}
                  bgColor={
                    item?.user.roleId
                      ? PROJECT_ROLES_COLORS[item?.user.roleId]
                      : GLOBAL_ROLES_COLORS[item?.user.groupId]
                  }
                  parentBgColor={
                    item.parentMessage && item?.parentMessage?.user.roleId
                      ? PROJECT_ROLES_COLORS[item?.parentMessage?.user.roleId]
                      : GLOBAL_ROLES_COLORS[item?.parentMessage?.user.groupId]
                  }
                  isFileDeleted={item.messageType === 2}
                  onOpen={() => {
                    setActiveIndex(
                      imagesFromMessages.findIndex(
                        (image: any) => image === item.file?.url
                      )
                    );
                    setGalleryVisible(true);
                  }}
                  className={
                    array.length - 1 === i ? 'chat__message--last' : ''
                  }
                  onSendEmoji={sendEmojiHandler as any}
                  setActiveMessage={setIsActiveMessage}
                  dataMessageId={item.id as any}
                  isTheSameDay={
                    dayjs.unix(array[i - 1]?.dateCreate).format('DD MMMM') ===
                    dayjs.unix(item.dateCreate).format('DD MMMM')
                  }
                  isTheSameAuthor={
                    array[i - 1]?.type !== 'system' &&
                    array[i - 1]?.user.id === item.user.id
                      ? true
                      : false
                  }
                  isSystem={item.type === 'system' || item.messageType > 1}
                  onRecoverMessage={handleRecoverMessage}
                  onScrollTo={() => {
                    scrollToNode(
                      'message',
                      array.findIndex(
                        ({ id }: IMessage) => id === array[i].parentMessage.id
                      ),
                      true
                    );
                  }}
                />
              ))
            ) : (
              <Spinner size="lg" center className="task-loading" />
            )}
            {!disabled && (
              <ContextMenu
                ref={contextMenuRef}
                hasTouchScreen={hasTouchScreen}
                isVisible={isContextMenuShow && !isContextMenuLocked}
                message={memoisedMessage}
                theme={theme}
                userId={user.id}
                selectedText={selectedText}
                onCancel={cancelSendMesssageHandler}
                withError={memoisedMessage?.withError}
                onDeleteMessage={
                  memoisedMessage?.file_id
                    ? deleteFileHandler
                    : deleteMessageHandler
                }
                onReplayMessage={replyMessageHandler}
                onEditMessage={editMessageHandler}
                onSendEmoji={sendEmojiHandler}
              />
            )}
          </div>
          {selectedText}
          <ChatFooter
            inputRef={inputRef}
            staffMessageId={staffMessageId}
            typeId={typeId}
            userId={user.id}
            isMobile={isMobile}
            selectedText={selectedText}
            isMesageFromSocket={isMesageFromSocket}
            setActiveMessageId={setActiveMessageId}
            messageAction={sendMessageSettings.action}
            setSendMessageSettings={setSendMessageSettings}
            activeMessageId={activeMessageId}
            messagesLength={messages?.length || 0}
            theme={theme as any}
            // messageHandler={messageHandler}
            setIsMesageFromSocket={setIsMesageFromSocket}
            nodeRef={nodeRef}
            className="chat__footer"
            chatPlaceholder={chatPlaceholder}
            disabled={disabled}
            checkedMessage={memoisedMessage}
            isVisible={isVisible}
            onCloseHandler={() => {
              setSendMessageSettings({
                action: '',
                messageId: 0,
              });
              setActiveMessageId(0);
            }}
            defaultTextareaHeight={defaultTextareaHeight}
          />
        </div>
        {galleryVisible && (
          <Gallery
            index={activeIndex}
            images={imagesFromMessages}
            closeLightbox={() => setGalleryVisible(false)}
            onDelete={() => {
              setGalleryVisible(false);
            }}
            onDownload={() => {
              setGalleryVisible(false);
            }}
            canDelete={false}
          />
        )}
      </div>
    );
  }
);

const ChatActionBlock = ({
  message,
  action,
  onClose,
}: {
  message: IMessage;
  onClose: () => void;
  action: TAction;
}): JSX.Element => (
  <div className="chat__reply">
    {action === 'reply' && (
      <IconSub className={cx('chat__button', 'chat__button--reply')} />
    )}
    {action === 'edit' && (
      <IconEdit className={cx('chat__button', 'chat__button--edit')} />
    )}
    <Info
      showOnline={false}
      backgroundColor={PROJECT_ROLES_COLORS[message?.user.roleId]}
      avatar={{
        text: message?.user.lastName,
        url: message?.user.avatar?.url,
      }}
      className="chat__reply-info"
      classNames={{
        title: 'chat__reply-title',
        text: 'chat__reply-text',
        avatar: `chat__reply-avatar ${
          message?.file && 'chat__reply-info--hide-avatar'
        }`,
      }}
      title={`${message?.user.firstName} ${message?.user.lastName}`}
      text={
        message?.file
          ? imgExtensions.includes(message?.file?.extension)
            ? 'Фото'
            : message?.file?.name
          : message?.text
      }
      sizes={{
        title: 'xs',
        text: 'sm',
        avatar: 'sm',
      }}
      dangerouslyHtml
      user={message?.user}
    >
      {message?.file ? (
        imgExtensions.includes(message?.file?.extension) ? (
          <img
            className="chat__reply-img-thumb"
            src={message?.file.thumb}
            alt={message?.file.name}
          />
        ) : (
          <FileExtensions
            className="chat__reply-file-thumb"
            type={message?.file?.extension}
          />
        )
      ) : null}
    </Info>
    <IconClose
      onClick={onClose}
      className={cx('chat__button', 'chat__button--close')}
    />
  </div>
);

interface IChatFooterProps {
  inputRef: any;
  nodeRef: any;
  className: string;
  chatPlaceholder: string;
  disabled: boolean;
  userId: number;
  isVisible: boolean;
  defaultTextareaHeight: number;
  theme: boolean;
  checkedMessage: any;
  messagesLength: number;
  activeMessageId: number;
  isMesageFromSocket: boolean;
  messageAction: TAction;
  isMobile: boolean;
  typeId: number;
  selectedText: string;
  staffMessageId: number | null;
  setIsMesageFromSocket: (value: boolean) => void;
  setActiveMessageId: (id: number) => void;
  setSendMessageSettings: (settings: {
    action: TAction;
    messageId: number;
  }) => void;
  onCloseHandler: () => void;
}

const ChatFooter: React.FC<IChatFooterProps> = ({
  inputRef,
  nodeRef,
  isMobile,
  selectedText,
  isMesageFromSocket,
  setIsMesageFromSocket,
  className,
  chatPlaceholder,
  disabled,
  isVisible,
  defaultTextareaHeight,
  theme,
  checkedMessage,
  messagesLength,
  activeMessageId,
  userId,
  messageAction,
  staffMessageId,
  setActiveMessageId,
  setSendMessageSettings,
  typeId,
  onCloseHandler = () => {},
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { slugTask } = useParams();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const emojiBlockRef = useRef<HTMLDivElement>(null);

  const [isEmojiBlockVisible, setIsEmojiBlockVisible] = useState(false);
  const [messageValue, setMessageValue] = useState('');

  const isProjectManager = checkPermission('MANAGER');

  useOnClickOutside(emojiBlockRef, (e: any) => {
    if (e.target.closest('.chat__button--emojies')) return;
    setIsEmojiBlockVisible(false);
  });

  const toggleEmojiHandler = useCallback((): void => {
    setIsEmojiBlockVisible((prev) => !prev);
  }, [isEmojiBlockVisible]);

  const messageHandler = useCallback(
    (value: any): any => setMessageValue(value),
    []
  );

  const addSmile = useCallback((emoji: string): void => {
    setMessageValue((prev: any) =>
      setCursorPosition(textareaRef.current, prev, emoji)
    );
  }, []);

  const sendMessageHandler = useCallback((): void => {
    if (!messageValue.trim().length) return;

    const updateMessagesData = (): void => {
      setMessageValue('');
      if (messageAction) {
        setSendMessageSettings({
          action: '',
          messageId: 0,
        });
      }
    };

    const actionParams = {
      text: messageValue,
      slug: slugTask,
      tId: Date.now(),
    };

    let dispatchAction = createMessage({
      typeId,
      chatUserId: staffMessageId
        ? staffMessageId
        : typeId === 3
        ? userId
        : null,
      // chatUserId: typeId === 3 ? (isProjectManager ? userId : null) : null,
      ...actionParams,
    });

    if (messageAction === 'reply') {
      dispatchAction = replyMessage({
        replyId: activeMessageId,
        citeText: selectedText ? selectedText : null,
        ...actionParams,
      });
    }

    if (messageAction === 'edit') {
      dispatchAction = editMessage({
        messageId: activeMessageId,
        ...actionParams,
      });
    }

    dispatch(dispatchAction)
      .unwrap()
      .then(() => {
        updateMessagesData();
        setIsMesageFromSocket(true);
        // scrollToStart();
      })
      .catch(() => {
        if (!navigator.onLine) {
          const chatBody = document.querySelector('.chat__body');
          chatBody?.scrollTo(0, chatBody.scrollHeight as any);
        }
      });
  }, [messageValue, messageAction, messagesLength]);

  const onKeyDownHandler = useCallback((e: any): void => {
    if (e.keyCode === 13 && !e.ctrlKey) {
      setMessageValue((prev): any =>
        setCursorPosition(textareaRef.current, prev, '\n')
      );
    } else if (e.keyCode === 13 && e.ctrlKey) {
      sendMessageHandler();
    }
  }, []);

  useEffect(() => {
    const keysHandler = (e: any): void => {
      if (e.keyCode === 27) {
        setMessageValue('');
        setSendMessageSettings({
          action: '',
          messageId: 0,
        });

        setActiveMessageId(0);
      } else if (e.keyCode === 13 && e.ctrlKey) {
        sendMessageHandler();
      }
    };

    window.addEventListener('keydown', keysHandler);

    return () => {
      window.removeEventListener('keydown', keysHandler);
    };
  }, [messageValue, messageAction, activeMessageId]);

  // useEffect(() => {
  //   scrollToStart();
  // }, []);

  // useEffect(() => {
  //   // if (isMesageFromSocket) return;
  //   scrollToStart();
  // }, [messagesLength, isMesageFromSocket]);

  const hasTouchScreen = 'ontouchstart' in window;

  return (
    <div
      ref={ref}
      className="chat__footer"
      style={{
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? 0.5 : 1,
      }}
    >
      {selectedText}
      {nodeRef && <div ref={nodeRef} />}
      {(messageAction === 'edit' || messageAction === 'reply') && (
        <ChatActionBlock
          action={messageAction}
          message={checkedMessage}
          onClose={onCloseHandler}
        />
      )}

      <div
        className={cx('chat__input-wrap', {
          'chat__input-wrap--dark': theme,
        })}
      >
        <IconPaperClip
          className="chat__button chat__button--attachments"
          onClick={() => {
            console.log(inputRef.current);
            inputRef.current?.click();
          }}
        />

        <AutoResizeTextarea
          ref={textareaRef}
          autofocus={!disabled && !isMobile}
          sendByEnter={true}
          isFieldActive={messageAction === 'edit' || messageAction === 'reply'}
          defaultHeight={defaultTextareaHeight}
          placeholder={chatPlaceholder}
          className="chat__input"
          isChatField={true}
          handleChangeName={messageHandler}
          name={messageAction === 'edit' ? checkedMessage?.text : messageValue}
          onKeyDown={onKeyDownHandler}
        />
        {isEmojiBlockVisible && (
          <div className="chat__smiles">
            <EmojiesBlock
              ref={emojiBlockRef}
              hasTouchScreen={hasTouchScreen}
              emojies={Object.keys(emojiesObject.getaAll())}
              onClick={addSmile}
            />
          </div>
        )}
        <div className="chat__buttons">
          <IconHappy
            onClick={toggleEmojiHandler}
            className="chat__button chat__button--emojies"
          />
          <Button
            className="chat__button chat__button--send-message"
            type="button"
            view="default"
            onClick={sendMessageHandler as any}
          >
            <IconSendArrow />
          </Button>
        </div>
      </div>
    </div>
  );
};

const chatTypes = [
  {
    type: generalChatType,
    title: 'Задача',
    titleMobile: 'Задача',
    for: 'all',
  },
  {
    type: techChatType,
    title: 'Тех.',
    titleMobile: 'Тех.',
    for: 'all',
  },
  {
    type: managerChatType,
    title: 'Чат з менеджером',
    titleMobile: 'PM.',
    for: 'staff',
  },
];

const chatPMTypes = (types = []): any => [
  ...chatTypes.filter((el) => el.for !== 'staff'),
  ...types,
];

const chatSMTypes = [
  ...chatTypes.filter((el) => el.for !== 'staff'),
  {
    type: managerToManagerChatType,
    title: 'PM/SM',
    titleMobile: 'PM/SM',
    for: 'manager',
  },
];

const ChatTypeTabs = ({
  changeTypeId,
  inform,
  theme,
  userId,
  typeId,
  onClear,
  types = chatTypes,
}: {
  theme: string;
  inform: any;
  userId: number;
  typeId: number;
  changeTypeId: (value: number, id: number) => void;
  onClear: (data: any) => void;
  types?: any;
}): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const params = useParams();

  const informLength = inform?.informerList?.filter(
    (item: any) => item.status === 0 && item.slug === params.slugTask
  ).length;

  const chatInformCountsByType = useMemo(() => {
    const informerList = inform?.informerList;
    if (!informerList?.length) return {};
    let generalChat = 0;
    let techChat = 0;
    let managerChat = 0;
    const chatInformCounts = {} as any;
    informerList.forEach((item: any) => {
      if (item.status === 1 || item.slug !== params.slugTask) {
        return;
      }
      if (item.chatType === generalChatType) {
        generalChat += 1;
      } else if (item.chatType === techChatType) {
        techChat += 1;
      } else if (item.chatType === managerChatType) {
        managerChat += 1;
      }
    });
    chatInformCounts[generalChatType] = generalChat;
    chatInformCounts[techChatType] = techChat;
    chatInformCounts[managerChatType] = managerChat;
    return chatInformCounts;
  }, [informLength, tabIndex]);

  useEffect(() => {
    if (!informLength) return;
    onClear({
      slug: params.slugTask,
      typeId: tabIndex + 1,
      chatUserId: typeId === 3 ? userId : null,
    });
  }, [tabIndex, informLength]);

  console.log(chatInformCountsByType);

  return (
    <Tabs
      style={{
        padding: '16px 16px 0',
        borderBottom: '1px solid #e5e5e5',
        backgroundColor: theme === 'dark' ? '#1E1F28 ' : '#fff',
        fontSize: '14px',
      }}
      onSelect={(index) =>
        setTabIndex(() => {
          if (index !== tabIndex) {
            changeTypeId(
              types[index].type,
              types[index].id ? types[index].id : null
            );
          }
          return index;
        })
      }
    >
      <TabList className="chat__tabs">
        {types.map((item: any, index: any) => (
          <Tab
            key={item.type}
            onClick={() => {
              if (index !== tabIndex) {
                changeTypeId(item.type, item.id ? item.id : null);
              }
            }}
            style={{
              position: 'relative',
            }}
          >
            {!!chatInformCountsByType?.[item.type] && tabIndex !== index && (
              <Dot
                variant="warning"
                size="md"
                style={{
                  position: 'absolute',
                  top: '3px',
                  right: '-2px',
                }}
              >
                {chatInformCountsByType?.[item.type]}
              </Dot>
            )}
            {!isMobile ? item.title : item.titleMobile}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

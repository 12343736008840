import { AxiosResponse } from 'axios';
import { instance, projectInstance } from '../helpers/axios';
import {
  RegisterInputs,
  LoginInputs,
  RestoreInputs,
} from '../pages/auth/types';
import { PERMISSION } from '../helpers/constant';
import { IUserData } from '../common/types';

const register = (data: RegisterInputs): Promise<IUserData> =>
  instance.post('register', data).then((response: AxiosResponse) => {
    const userData = {
      ...response.data.data.user,
      permission: PERMISSION[response.data.data.user.groupId],
    };

    if (response.data.data.token) {
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('token', response.data.data.token);
    }

    return userData;
  });

const staffRegister = (data: RegisterInputs): Promise<IUserData> =>
  instance.post('register/developer', data).then((response: AxiosResponse) => {
    const userData = {
      ...response.data.user,
      permission: PERMISSION[response.data.user.groupId],
    };

    if (response.data.token) {
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('token', response.data.token);
    }

    return userData;
  });

const login = (data: LoginInputs): Promise<IUserData> =>
  instance.post('login', data).then((response: AxiosResponse) => {
    const userData = {
      ...response.data.data.user,
      permission: PERMISSION[response.data.data.user.groupId],
    };

    if (response.data.data.token) {
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('token', response.data.data.token);
    }

    return userData;
  });

const restorePassword = (data: RestoreInputs): Promise<any> =>
  instance.post('forgot', data);

const logout = (): void => {
  projectInstance.post('logout').then(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  });
};

const setNewPassword = (data: RestoreInputs, token: string): Promise<any> =>
  instance.post(`restore/${token}`, data);

const getNicknames = (): Promise<any> =>
  instance.get('/register/developer/nic');

const getSpecialities = (): Promise<any> =>
  instance.get('/register/developer/speciality');

const authService = {
  register,
  staffRegister,
  login,
  restorePassword,
  logout,
  getNicknames,
  setNewPassword,
  getSpecialities,
};

export default authService;

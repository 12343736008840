import { AxiosResponse } from 'axios';

import { projectInstance, projectInstanceWithFiles } from '../helpers/axios';
import { TCreateTask } from '../store/tasks/thunk';

import { TaskData } from '../layout/TaskList/types';

const createTask = (data: TCreateTask): Promise<any> =>
  projectInstance
    .post('task/create', data)
    .then((response: AxiosResponse) => response.data);

const updatePriority = (data: TaskData, slug: string): Promise<any> =>
  projectInstance
    .post(`task/${slug}/priority`, data)
    .then((response: AxiosResponse) => response.data);

const updateTask = (data: TaskData, slug: string): Promise<any> =>
  projectInstance
    .post(`task/${slug}/save`, data)
    .then((response: AxiosResponse) => response.data);

const updateStatus = (
  data: { action: string; value: string; param: string },
  slug: string
): Promise<any> =>
  projectInstance
    .post(`task/${slug}/action`, data)
    .then((response: AxiosResponse) => response.data);

const getTaskData = (slug: string): Promise<any> =>
  projectInstance
    .get(`task/${slug}/show`)
    .then((response: AxiosResponse) => response.data.taskInfo);

const chatClear = (data: any): Promise<any> =>
  projectInstance
    .post('informer/chat/clear', data)
    .then((response: AxiosResponse) => response.data);

const uploadFile = (data: any, config: any, slug: string): Promise<any> =>
  projectInstanceWithFiles
    .postForm(`task/${slug}/file/upload`, data, config)
    .then((response: AxiosResponse) => response.data)
    .catch((error) => error);

const updateFileName = (data: any): Promise<any> =>
  projectInstance
    .post(`task/${data.slug}/file/name`, {
      fileId: data.fileId,
      name: data.name,
      typeId: data.typeId,
    })
    .then((response: AxiosResponse) => response.data);

const getArchive = (): Promise<any> =>
  projectInstance
    .get('task/archive')
    .then((response: AxiosResponse) => response.data);

const getFilesList = (data: any): Promise<any> =>
  projectInstance
    .get(
      `task/${data.slug}/file/${data.typeId}${
        data?.fileUserId ? `/${data.fileUserId}` : ''
      }/list`
    )
    .then((response: AxiosResponse) => response.data);

const deleteFile = (data: any): Promise<any> =>
  projectInstance
    .post(`task/${data.slug}/file/remove`, {
      fileId: data.fileId,
    })
    .then((response: AxiosResponse) => response.data);

const getMessagesList = (data: any): Promise<any> =>
  projectInstance
    .get(
      `task/${data.slug}/chat/${data.typeId}${
        data.userId ? `/${data.userId}` : ''
      }/message/list`
    )
    .then((response: AxiosResponse) => response.data);

const createMessage = (
  data: { typeId: number; text: string; chatUserId?: number; tId?: number },
  slug: string
): Promise<any> =>
  projectInstance
    .post(`task/${slug}/message/create`, {
      typeId: data.typeId,
      text: data.text,
      chatUserId: data.chatUserId,
      tId: data?.tId,
    })
    .then((response: AxiosResponse) => response.data);

const editMessage = (
  data: { messageId: number; text: string },
  slug: string
): Promise<any> =>
  projectInstance.post(`task/${slug}/message/edit`, {
    messageId: data.messageId,
    text: data.text,
  });

const deleteMessage = (
  data: { messageId: number },
  slug: string
): Promise<any> =>
  projectInstance.post(`task/${slug}/message/delete`, {
    messageId: data.messageId,
  });

const recoverMessage = (
  data: { messageId: number },
  slug: string
): Promise<any> =>
  projectInstance.post(`task/${slug}/message/recover`, {
    messageId: data.messageId,
  });

const replyMessage = (
  data: { replyId: number; text: string; citeText?: string },
  slug: string
): Promise<any> =>
  projectInstance
    .post(`task/${slug}/message/reply`, {
      replyId: data.replyId,
      text: data.text,
      // citeText: '11111',
      citeText: data.citeText,
    })
    .then((response: AxiosResponse) => response.data);

const sendEmoji = (
  data: { messageId: number; emoji: string },
  slug: string
): Promise<any> =>
  projectInstance.post(`task/${slug}/message/emoji`, {
    messageId: data.messageId,
    emoji: data.emoji,
  });

const createFakeCO = (data: any): Promise<any> =>
  projectInstance.post(`/task/${data.slug}/robot`, {
    robotToken: 'f@r67FhkTkorl823fvLs65wpM2',
  });

export const coActions = (data: any): Promise<any> =>
  projectInstance.post(`/task/${data.slug}/group/client/action`, {
    action: data.action,
    text: data.text,
    groupId: data.groupId,
    taskId: data.taskId,
    value: data.value,
    param: data.param,
  });

const changePriorityCO = (data: any): Promise<any> =>
  projectInstance.post(`/task/${data.slug}/group/client/priority`, {
    groupId: data.groupId,
    priority: data.priority,
  });

const getTaskDescription = (slug: string): Promise<any> =>
  projectInstance
    .get(`task/${slug}/text`)
    .then((response: AxiosResponse) => response.data);

const getTaskHistory = (slug: string): Promise<any> =>
  projectInstance
    .get(`task/${slug}/history`)
    .then((response: AxiosResponse) => response.data);

const addAssessment = (slug: string, data: any): Promise<any> =>
  projectInstance
    .post(`task/${slug}/group/developer/calculation/action`, {
      action: data.action,
      groupId: data.groupId,
      hour: data.hour,
      text: data.text,
    })
    .then((response: AxiosResponse) => response.data);

const deleteAssessment = (slug: string, data: any): Promise<any> =>
  projectInstance
    .post(`task/${slug}/group/developer/calculation/action`, {
      action: data.action,
      groupId: data.groupId,
      calcId: data.calcId,
    })
    .then((response: AxiosResponse) => response.data);

const updateAssessment = (slug: string, data: any): Promise<any> =>
  projectInstance
    .post(`task/${slug}/group/developer/action`, {
      action: 'update',
      param: 'term',
      groupId: data.groupId,
      value: data.value,
    })
    .then((response: AxiosResponse) => response.data);

const finishAssessment = (slug: string, data: any): Promise<any> =>
  projectInstance
    .post(`task/${slug}/group/developer/action`, {
      action: 'complete',
      groupId: data.groupId,
    })
    .then((response: AxiosResponse) => response.data);

const continueAssessment = (slug: string, data: any): Promise<any> =>
  projectInstance
    .post(`task/${slug}/group/developer/action`, {
      action: 'continue',
      groupId: data.groupId,
    })
    .then((response: AxiosResponse) => response.data);

const checkAssessment = (slug: string, data: any): Promise<any> =>
  projectInstance
    .post(`task/${slug}/group/developer/calculation/action`, {
      action: 'checkComplete',
      groupId: data.groupId,
    })
    .then((response: AxiosResponse) => response.data);

const taskAction = (slug: string, data: any): Promise<any> =>
  projectInstance
    .post(`task/${slug}/action`, data)
    .then((response: AxiosResponse) => response.data);

const taskGroupAction = (slug: string, data: any): Promise<any> =>
  projectInstance
    .post(`task/${slug}/group/action`, data)
    .then((response: AxiosResponse) => response.data);

const taskPauseAction = (slug: string, data: any): Promise<any> =>
  projectInstance
    .post(`task/${slug}/pause/action`, data)
    .then((response: AxiosResponse) => response.data);

const taskGroupUserAction = (slug: string, data: any): Promise<any> =>
  projectInstance
    .post(`task/${slug}/group/user/action`, data)
    .then((response: AxiosResponse) => response.data);

const managerAction = (slug: string, data: any): Promise<any> =>
  projectInstance
    .post(`task/${slug}/group/manager/calculation/action`, data)
    .then((response: AxiosResponse) => response.data);

const taskService = {
  changePriorityCO,
  updatePriority,
  createTask,
  updateTask,
  updateStatus,
  getTaskData,
  getArchive,
  uploadFile,
  getFilesList,
  deleteFile,
  updateFileName,
  getMessagesList,
  createMessage,
  editMessage,
  deleteMessage,
  recoverMessage,
  replyMessage,
  sendEmoji,
  createFakeCO,
  coActions,
  getTaskDescription,
  getTaskHistory,
  addAssessment,
  deleteAssessment,
  updateAssessment,
  finishAssessment,
  continueAssessment,
  checkAssessment,
  chatClear,
  taskAction,
  taskGroupAction,
  taskPauseAction,
  taskGroupUserAction,
  managerAction,
};

export default taskService;

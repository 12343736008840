import { createSlice } from '@reduxjs/toolkit';
import {
  createTask,
  getTaskData,
  getMessagesList,
  getFilesList,
  getArchive,
  getTaskDescription,
  getTaskHistory,
  changePriorityCO,
  updateTaskPriority,
  createMessage,
} from './thunk';
import { IArchive, IMessage } from '../../common/types';

export interface TaskState {
  data: string;
  taskInfo: any | null;
  taskMessages: any;
  taskFiles: any;
  isLoaded: boolean;
  textLoaded: boolean;
  isFileLoaded: boolean;
  taskText: string;
  archive: IArchive[] | null;
  taskHistory: any;
}

const initialState = {
  data: '',
  taskInfo: null,
  taskText: '',
  textLoaded: false,
  taskMessages: [],
  taskFiles: [],
  isLoaded: false,
  isFileLoaded: false,
  archive: [],
  taskHistory: {
    list: [],
    isLoaded: false,
  },
} as TaskState;

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    // clearTaskData: (state) => {
    //   state.isLoaded = false;
    //   state.taskFiles = null;
    //   // state.taskInfo = null;
    //   state.taskText = '';
    //   state.taskHistory = {
    //     list: [],
    //     isLoaded: false,
    //   };
    //   state.archive = [];
    //   state.taskMessages = null;
    // },
    updateMessagesData: (state) => {
      state.taskMessages = [];
    },
    // createMessage: (state, action) => {
    //   console.log(action, 'action');
    //   const data = {
    //     // text: action.meta.arg.text,
    //     chatType: 1,
    //     dateCreate: new Date().getTime() / 1000,
    //     file_id: 0,
    //     // hashId: '88e0f16114a1e011c87b797513095a20',
    //     id: 16309,
    //     messageType: 3,
    //     replyLength: 0,
    //     type: '',
    //     user: {
    //       avatar: [],
    //       firstName: JSON.parse(localStorage.getItem('user') || '').firstName,
    //       groupId: 3,
    //       id: 116,
    //       lastName: JSON.parse(localStorage.getItem('user') || '').lastName,
    //       roleId: 4,
    //     },
    //   };
    //   state.taskMessages.messageList = [
    //     ...state.taskMessages.messageList,
    //     data,
    //   ];
    // },
    cancelSendingErrorMessage: (state, action) => {
      console.log(action.payload, 'action');
      state.taskMessages.messageList = state.taskMessages.messageList.filter(
        (message: IMessage) => message.tId !== action.payload
      );
    },
    clearTaskData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createTask.fulfilled, (state: TaskState, action) => {
      state.data = action.payload;
    });
    builder.addCase(createTask.rejected, (state: TaskState) => {
      state.data = '';
    });
    builder.addCase(getTaskData.fulfilled, (state: TaskState, action) => {
      state.taskInfo = action.payload;
    });
    builder.addCase(getTaskData.rejected, (state: TaskState) => {
      state.taskInfo = null;
    });
    builder.addCase(getMessagesList.pending, (state: TaskState) => {
      state.isLoaded = true;
    });
    builder.addCase(getMessagesList.fulfilled, (state: TaskState, action) => {
      state.taskMessages = action.payload;
      state.isLoaded = false;
    });
    builder.addCase(getMessagesList.rejected, (state: TaskState) => {
      state.taskMessages = [];
      state.isLoaded = false;
    });
    builder.addCase(getFilesList.pending, (state: TaskState) => {
      state.isFileLoaded = true;
    });
    builder.addCase(getFilesList.fulfilled, (state: TaskState, action) => {
      state.taskFiles = action.payload.fileList;
      state.isFileLoaded = false;
    });
    builder.addCase(getFilesList.rejected, (state: TaskState) => {
      state.taskFiles = [];
    });
    builder.addCase(getArchive.fulfilled, (state: TaskState, action) => {
      state.archive = action.payload.taskList;
      console.log(state.archive);
    });
    builder.addCase(getArchive.rejected, (state: TaskState) => {
      state.archive = [];
    });
    builder.addCase(
      getTaskDescription.fulfilled,
      (state: TaskState, action) => {
        state.taskText = action.payload.data.text;
        state.textLoaded = true;
      }
    );
    builder.addCase(getTaskDescription.pending, (state: TaskState) => {
      state.textLoaded = false;
    });
    builder.addCase(getTaskDescription.rejected, (state: TaskState) => {
      state.taskText = '';
      state.textLoaded = false;
    });
    builder.addCase(getTaskHistory.fulfilled, (state: TaskState, action) => {
      state.taskHistory.list = action.payload.historyList;
      state.taskHistory.isLoaded = true;
    });
    builder.addCase(getTaskHistory.pending, (state: TaskState) => {
      state.taskHistory.isLoaded = false;
    });
    builder.addCase(getTaskHistory.rejected, (state: TaskState) => {
      state.taskHistory.list = [];
      state.taskHistory.isLoaded = false;
    });
    builder.addCase(changePriorityCO.fulfilled, (state: TaskState, action) => {
      const { groupId } = action.meta.arg;
      if (!state.taskInfo.groups) return;
      state.taskInfo.groups.forEach((group: any) => {
        if (group.id === groupId) {
          group.priority = action.meta.arg.priority;
        }
      });
    });
    builder.addCase(
      updateTaskPriority.fulfilled,
      (state: TaskState, action) => {
        state.taskInfo.priority = action.meta.arg.priority;
      }
    );
    builder.addCase(createMessage.pending, (state: TaskState, action) => {
      const data = madeFakeMessage(
        action.meta.arg.text,
        action.meta.arg.typeId,
        action.meta.arg.tId
      );
      state.taskMessages.messageList = [
        ...state.taskMessages.messageList,
        data,
      ];
    });
    builder.addCase(createMessage.rejected, (state: TaskState, action) => {
      state.taskMessages.messageList = state.taskMessages.messageList.map(
        (message: IMessage) => {
          if (message.tId === action.meta.arg.tId) {
            message.withError = true;
          }
          return message;
        }
      );
    });
  },
});

const { reducer } = taskSlice;
export const { clearTaskData, updateMessagesData, cancelSendingErrorMessage } =
  taskSlice.actions;

export default reducer;

function madeFakeMessage(
  text: string,
  chatType: number,
  tId: number
): IMessage {
  const user = JSON.parse(localStorage.getItem('user') as string);
  const dateCreate = new Date().getTime() / 1000;
  const isConnected = navigator.onLine;

  return {
    text,
    chatType,
    dateCreate,
    id: dateCreate,
    type: '',
    withError: !isConnected,
    tId,
    user,
  } as IMessage;
}

import React from 'react';
import { useThemeToggler } from '../../context/ThemeContext';
import './Balance.scss';

interface IBalanceProps {
  money: string;
  children?: React.ReactNode;
  className?: string;
  text?: string;
}

export default function Balance({
  children,
  className,
  text = '',
  money = '',
}: IBalanceProps): JSX.Element | null {
  const { theme } = useThemeToggler();

  return (
    <div className={`balance balance--${theme} ${className}`}>
      {children}
      <span>{`${text}`}</span>
      {/* {money && <span className="balance__money">{`${money} грн`}</span>} */}
      <span className="balance__money">{`${money} грн`}</span>
    </div>
  );
}

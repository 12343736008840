import React from 'react';
import { NavLink } from 'react-router-dom';
import { translateData } from '../constant';

function SendPassword(): JSX.Element {
  return (
    <div className="section__auth-form section__auth-form--custom">
      <h1 className="form__title form__title--restore">
        {translateData.UA.sendPasswordText}
      </h1>

      <span className="ci ci-mail section__auth-img" />
      <p className="section__auth-description">
        {translateData.UA.checkEmailText}
      </p>

      <span className="section__auth-text" style={{ marginTop: '24px' }}>
        {translateData.UA.or}
        <NavLink to="/login/" style={{ marginLeft: '8px' }}>
          {translateData.UA.signInText}
        </NavLink>
      </span>
    </div>
  );
}

export default SendPassword;

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './store/auth/store';
import projectReducer from './store/projects/store';
import taskReducer from './store/tasks/store';
import userReducer from './store/user/store';
import billingReducer from './store/billing/store';

const rootReducer = combineReducers({
  auth: authReducer,
  projectData: projectReducer,
  taskData: taskReducer,
  userData: userReducer,
  billingData: billingReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

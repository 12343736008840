import { createAsyncThunk } from '@reduxjs/toolkit';
import taskService from '../../services/task.service';

export type TCreateTask = {
  name: string;
  slug: string;
  projectId?: any;
};

export const createTask = createAsyncThunk(
  'task/new',
  async (data: TCreateTask, thunkAPI) => {
    try {
      return await taskService.createTask(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTaskPriority = createAsyncThunk(
  'task/updateTaskPriority',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.updatePriority(data, data.slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTask = createAsyncThunk(
  'task/updateTask',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.updateTask(data, data.slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateStatus = createAsyncThunk(
  'task/updateStatus',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.updateStatus(
        { action: data.action, value: data.value, param: data.param },
        data.slug
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTaskData = createAsyncThunk(
  'task/getInfo',
  async (slug: string, thunkAPI) => {
    try {
      return await taskService.getTaskData(slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const uploadFile = createAsyncThunk(
  'task/uploadFile',
  async (data: any) => {
    try {
      return await taskService.uploadFile(data.data, data.config, data.slug);
    } catch (error) {
      return error;
    }
  }
);

export const getFilesList = createAsyncThunk(
  'task/getFilesList',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.getFilesList(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getArchive = createAsyncThunk(
  'task/getArchive',
  async (_, thunkAPI) => {
    try {
      return await taskService.getArchive();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateFileName = createAsyncThunk(
  'task/updateFileName',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.updateFileName(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteFile = createAsyncThunk(
  'task/deleteFile',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.deleteFile(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getMessagesList = createAsyncThunk(
  'task/getMessagesList',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.getMessagesList(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createMessage = createAsyncThunk(
  'task/createMessage',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.createMessage(data, data.slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editMessage = createAsyncThunk(
  'task/editMessage',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.editMessage(data, data.slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteMessage = createAsyncThunk(
  'task/deleteMessage',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.deleteMessage(data, data.slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const recoverMessage = createAsyncThunk(
  'task/recoverMessage',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.recoverMessage(data, data.slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const replyMessage = createAsyncThunk(
  'task/replyMessage',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.replyMessage(data, data.slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendEmoji = createAsyncThunk(
  'task/sendEmoji',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.sendEmoji(data, data.slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createFakeCO = createAsyncThunk(
  'task/createfakeCO',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.createFakeCO(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const coActions = createAsyncThunk(
  'task/cpActions',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.coActions(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changePriorityCO = createAsyncThunk(
  'task/changePriorityCO',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.changePriorityCO(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTaskDescription = createAsyncThunk(
  'task/getTaskDescription',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.getTaskDescription(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTaskHistory = createAsyncThunk(
  'task/getTaskHistory',
  async (data: any, thunkAPI) => {
    try {
      return await taskService.getTaskHistory(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const addAssessment = createAsyncThunk(
  'task/addAssessment',
  async (data: any) => {
    try {
      return await taskService.addAssessment(data.slug, data);
    } catch (error) {
      return error;
    }
  }
);

export const deleteAssessment = createAsyncThunk(
  'task/deleteAssessment',
  async (data: any) => {
    try {
      return await taskService.deleteAssessment(data.slug, data);
    } catch (error) {
      return error;
    }
  }
);

export const updateAssessment = createAsyncThunk(
  'task/updateAssessment',
  async (data: any) => {
    try {
      return await taskService.updateAssessment(data.slug, data);
    } catch (error) {
      return error;
    }
  }
);

export const finishAssessment = createAsyncThunk(
  'task/finishAssessment',
  async (data: any) => {
    try {
      return await taskService.finishAssessment(data.slug, data);
    } catch (error) {
      return error;
    }
  }
);

export const continueAssessment = createAsyncThunk(
  'task/continueAssessment',
  async (data: any) => {
    try {
      return await taskService.continueAssessment(data.slug, data);
    } catch (error) {
      return error;
    }
  }
);

export const checkAssessment = createAsyncThunk(
  'task/checkAssessment',
  async (data: any) => {
    try {
      return await taskService.checkAssessment(data.slug, data);
    } catch (error) {
      return error;
    }
  }
);

export const chatClear = createAsyncThunk(
  'task/chatClear',
  async (data: any) => {
    try {
      return await taskService.chatClear(data);
    } catch (error) {
      return error;
    }
  }
);

export const taskAction = createAsyncThunk(
  'task/taskAction',
  async (data: any) => {
    try {
      return await taskService.taskAction(data.slug, data);
    } catch (error) {
      return error;
    }
  }
);

export const taskGroupAction = createAsyncThunk(
  'task/taskGroupAction',
  async (data: any) => {
    try {
      return await taskService.taskGroupAction(data.slug, data);
    } catch (error) {
      return error;
    }
  }
);

export const taskGroupUserAction = createAsyncThunk(
  'task/taskGroupAction',
  async ({ slug, data }: any) => {
    try {
      return await taskService.taskGroupUserAction(slug, data);
    } catch (error) {
      return error;
    }
  }
);

export const managerAction = createAsyncThunk(
  'task/managerAction',
  async (data: any) => {
    try {
      return await taskService.managerAction(data.slug, data);
    } catch (error) {
      return error;
    }
  }
);

export const taskPauseAction = createAsyncThunk(
  'task/taskPauseAction',
  async (data: any) => {
    try {
      return await taskService.taskPauseAction(data.slug, data);
    } catch (error) {
      return error;
    }
  }
);

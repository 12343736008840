import React, { useCallback, useLayoutEffect } from 'react';

import { useThemeToggler } from '../../context/ThemeContext';
import useLocalStorage from '../../hooks/useLocalStorage';

import { getLocalStorage } from '../../helpers/common';
import { isDarkMode, DARK_THEME } from '../../helpers/constant';

import './ThemeSwitcher.scss';

const ThemeSwitcher = (): JSX.Element => {
  const { theme, setTheme } = useThemeToggler();
  const [darkTheme, setDarkTheme] = useLocalStorage(DARK_THEME, isDarkMode);

  const onToggle = useCallback((): void => {
    if (theme === 'light') {
      setTheme('dark');
      setDarkTheme(true);
    } else {
      setTheme('light');
      setDarkTheme(false);
    }
  }, [theme, darkTheme]);

  useLayoutEffect(() => {
    if (getLocalStorage(DARK_THEME)) {
      setDarkTheme(true);
      setTheme('dark');
    }

    const localStorageHandler = (): void => {
      const isDark = Boolean(getLocalStorage(DARK_THEME));
      setDarkTheme(isDark);
      setTheme(isDark ? 'dark' : 'light');
    };
    window.addEventListener('storage', localStorageHandler);

    return () => {
      window.removeEventListener('storage', localStorageHandler);
    };
  }, []);

  return (
    <label className="switcher">
      <input
        className="switcher__input"
        type="checkbox"
        checked={theme !== 'light'}
        onChange={onToggle}
      />
      <span className="switcher__toggler" />
    </label>
  );
};

export default ThemeSwitcher;

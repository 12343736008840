// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
  padding-top: 30px;
}
.skeleton__item {
  line-height: 1;
  display: block;
  overflow: hidden;
  position: relative;
  width: 50%;
  height: 100%;
  border-radius: 5px;
  background-color: rgb(239, 241, 246);
  margin: 0 auto 10px auto;
}
.skeleton__item--animated::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 500px;
  top: 0;
  left: -500px;
  background-image: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
  animation: progress 1.2s ease-in-out infinite;
}

@keyframes progress {
  0% {
    left: -500px;
  }
  100% {
    left: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Skeleton/Skeleton.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AACI;EACI,cAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;EACA,oCAAA;EACA,wBAAA;AACR;AACQ;EACI,WAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,MAAA;EACA,YAAA;EACA,mHAAA;EACA,6CAAA;AACZ;;AAIA;EACI;IACI,YAAA;EADN;EAIE;IACI,UAAA;EAFN;AACF","sourcesContent":[".skeleton {\n    padding-top: 30px;\n\n    &__item {\n        line-height: 1;\n        display: block;\n        overflow: hidden;\n        position: relative;\n        width: 50%;\n        height: 100%;\n        border-radius: 5px;\n        background-color: rgb(239, 241, 246);\n        margin: 0 auto 10px auto;\n\n        &--animated::before {\n            content: \"\";\n            position: absolute;\n            height: 100%;\n            width: 500px;\n            top: 0;\n            left: -500px;\n            background-image: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));\n            animation: progress 1.2s ease-in-out infinite;\n        }\n    }\n}\n\n@keyframes progress {\n    0% {\n        left: -500px;\n    }\n\n    100% {\n        left: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useState } from 'react';

const WakeUpMonitor = ({ onWakeUp }: any): null => {
  const [lastActivity, setLastActivity] = useState(performance.now());

  useEffect(() => {
    const checkIdleTime = (): void => {
      const currentTime = performance.now();
      const timeSinceLastActivity = currentTime - lastActivity;

      if (timeSinceLastActivity > 3000) {
        if (onWakeUp) {
          onWakeUp();
        }
      }

      setLastActivity(currentTime);
    };

    const intervalId = setInterval(checkIdleTime, 1000);

    return () => clearInterval(intervalId);
  }, [lastActivity, onWakeUp]);

  return null;
};

export default WakeUpMonitor;

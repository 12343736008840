// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input__field {
  display: block;
  width: 100%;
  height: 35px;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #E9EBF0;
  outline: none;
}
.input__field::placeholder {
  color: #C1C1C1;
}
.input__field:focus {
  border-color: #3F8CFF;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Input/Input.scss","webpack://./src/styles/abstracts/_colors.scss"],"names":[],"mappings":"AAGI;EACI,cAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;AAFR;AAGQ;EACI,cCZH;ADWT;AAGQ;EACI,qBCUK;ADXjB","sourcesContent":["@import \"../../../styles/abstracts/colors.scss\";\n\n.input {\n    &__field {\n        display: block;\n        width: 100%;\n        height: 35px;\n        padding: 8px 16px;\n        font-size: 14px;\n        border-radius: 2px;\n        border: 1px solid $GALLERY;\n        outline: none;\n        &::placeholder {\n            color: $SILVER;\n        }\n        &:focus {\n            border-color: $BLUE_DE_FRANCE;\n        }\n    }\n}\n","$WHITE: #FFFFFF;\n$SILVER: #C1C1C1;\n$LIGHT_GRAY: #D2D2D2;\n$IRON: #D5D6D7;\n$ATHENS_GRAY: #EFF0F3;\n$WHITE_SMOKEY: #F5F5F5;\n$GALLERY: #E9EBF0;\n$ZIRCON: #F4F8FE;\n\n$UFO_GREEN: #24D37B;\n$CRAYOLA: #1AC971;\n$SUCCESS_COLOR: #20B96D;\n$DEFAULT_COLOR: #A2ABB4;\n\n$CARNATION: #F1556D;\n$RED: #FF0000;\n$SOFT_RED: #FF512F;\n\n$TULIP_TREE: #F0B139;\n$TULIP_TREE_DARK: #db9e2a;\n$CADMIUM_ORANGE: #E4862B;\n$CADMIUM_ORANGE_DARK: #E6792E;\n$DEEP_CARROT_ORANGE: #EA6333;\n\n$BRIGHT_CERULEAN: #1FABF0;\n$CORNFLOWER: #5987F8;\n$BLUE_DE_FRANCE: #3F8CFF;\n$BLUE_DE_FRANCE_DARK: #2B78EB;\n$SOFT_BLUE: rgba(63, 140, 255, 0.06);\n\n$BLACK: #000000;\n$BLACK_PEARL: #0A1629;\n$DARK_JUNGLE_GREEN: #1E1F28;\n$SHARK: #1E272E;\n$ONYX: #2B343B;\n$ARSENIC: #384047;\n$SCARPA_FLOW: #544E61;\n$GUNSMOKE: #828588;\n\n$COOL_GREY: #8E95A5;\n$LIGHT_GREY: #C4C4C4;\n$DARK_GREY: #999;\n$SOFT_GRAY: #EFEFEF;\n\n$SOFT_BLACK: rgba(0, 0, 0, 0.1);\n$VIOLET: rgba(84, 78, 97, 1);\n$DARK_VIOLET: #0C182A;\n$LIGHT_BLUE: #CAD7E6;\n$LIGHT_BLUE_DARK: rgba(171, 184, 199, 1);\n$GRAY_SOFT: #FBFBFB;\n$WHITE_SMOKEY: #dadce0;\n$WHITE_SMOKEY_DARK: #e8e8e9;\n$LIGHT_GREEN: #b6fac4;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useMemo } from 'react';
import Tooltip from 'rc-tooltip';
import { useDispatch } from 'react-redux';
import UsersList from '../../components/UsersList/UsersList';
import { useModal } from '../../components/Modal/useModal';
import Modal from '../../components/Modal/Modal';
import AddParticipants from '../../forms/AddParticipants/AddParticipants';
import { TUserData } from '../ProjectList/types';
import { getRoleList } from '../../store/user/thunk';
import { AppDispatch } from '../../store';

import { ReactComponent as IconEllipse } from '../../media/icons/ellipse.svg';

interface IEditUsersListProps {
  projectId: number;
  usersList: TUserData[];
  slug: string;
}

export default function EditUsersList({
  projectId,
  usersList,
  slug,
}: IEditUsersListProps): JSX.Element {
  const dispatch: AppDispatch = useDispatch();
  const [isModaleVisible, toggleModalVisible] = useModal(false);

  const handleOpenAddParticipantsModal = (): void => {
    dispatch(getRoleList())
      .unwrap()
      .then(() => {
        toggleModalVisible(true);
      });
  };

  const userListSortedByRole = useMemo(
    () => [...usersList].sort((a, b) => a.roleId - b.roleId),
    [usersList]
  );

  return (
    <>
      <UsersList
        withBorder
        onClick={handleOpenAddParticipantsModal}
        size="md"
        usersArray={usersList}
      />
      {!!usersList.length && (
        <Tooltip
          placement="top"
          trigger={['hover']}
          overlay={<span>додати користувача</span>}
        >
          <button
            type="button"
            className="section__add-user"
            onClick={handleOpenAddParticipantsModal}
          >
            <IconEllipse />
            <span className="ci ci-user_plus" />
          </button>
        </Tooltip>
      )}

      <Modal
        title="Користувачі, які додані до проекту"
        size="md"
        isActive={isModaleVisible}
        handleClose={() => toggleModalVisible(false)}
        modalClassName="modal--add-participants"
      >
        <AddParticipants
          projectId={projectId}
          slug={slug}
          usersArray={userListSortedByRole}
        />
      </Modal>
    </>
  );
}

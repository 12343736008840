import React, { memo } from 'react';
import { TProjectData, TStatusItem } from '../types';
import './ProjectCard.scss';

interface IProjectCardProps {
  project: TProjectData;
}

const ProjectCard = ({ project }: IProjectCardProps): JSX.Element => {
  const renderStatusList = (stageList: TStatusItem[]): JSX.Element => (
    <ul className="list project-card__list">
      {stageList &&
        Object.entries(stageList).map((item: any) => (
          <li
            key={item[0]}
            className={`project-card__item project-card__item--${item[0]}`}
          >
            {item[1]}
          </li>
        ))}
    </ul>
  );

  return (
    <>
      {renderStatusList(project.tasks)}
      <h3 className="project-card__title">{project.name}</h3>
      <span className="project-card__link" />
      {/* {project.description}</span> */}
    </>
  );
};

export default memo(ProjectCard);

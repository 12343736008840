import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AuthContext } from './AuthContext';

export function AuthProvider(
  { children }: { children: React.ReactNode },
): JSX.Element {
  const { isLoggedIn } = useSelector((state: { auth: any; }) => state.auth);

  const value = useMemo(() => ({ isLoggedIn }), [isLoggedIn]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
